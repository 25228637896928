import React, { useState, useEffect, useCallback } from 'react';

import socketio from 'socket.io-client';
import Push from 'push.js';

import { Container, Header, StyledLink } from './styles';

import RegisterButton from '~/components/Buttons/RegisterButton';

import { useAuth } from '~/context/AuthContext';
import api from '~/services/api';
import { PageTitle } from '~/components/PageTitle';

function TableSessions() {
  const [sessions, setSessions] = useState([]);
  const { user } = useAuth();

  const getTables = useCallback(async () => {
    const response = await api.get('/restaurants/table-sessions-minimal');

    setSessions(response.data);
    // console.tron.log(response.data);
  }, []);

  useEffect(() => {
    try {
      getTables();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getTables]);

  useEffect(() => {
    sessions.forEach((session) => {
      const user = session.key;

      const socket = socketio('http://localhost:3333', {
        query: {
          user_id: user,
          restaurant_id: user.id,
        },
      });
      socket.on('notification', (notification) => {
        // console.log('emitiu a notificação');
        Push.create(`Mesa ${notification.id} pediu fechamento de conta`);
      });
    });
  }, [sessions, user.id]);

  // async function handleDelete(id) {
  //   try {
  //     if (window.confirm('Tem certeza que deseja deletar esta mesa?')) {
  //       await api.delete(`/restaurants/tables/${id}`);

  //       getTables();

  //       toast.success('Mesa deletada com sucesso!');
  //     }
  //   } catch (error) {
  //     toast.error('Erro ao deletar mesa');
  //   }
  // }

  return (
    <Container>
      <Header>
        <div>
          <PageTitle>Mesas</PageTitle>
          <p>Gerencie as mesas de seu estabelecimento</p>
        </div>
        <StyledLink to="/tables/new">
          <RegisterButton>Adicionar</RegisterButton>
        </StyledLink>
      </Header>

      {/* <TablesTable borderless>
        <thead>
          <tr>
            <th>Número</th>
            <th>Status</th>
            <th>Código</th>
            <th>QRCode</th>
            <th>Imprimir</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {sessions.map((table) => (
            <tr key={table.id}>
              <td>{table.table_number}</td>
              <td>{table.status}</td>
              <td>{table.table_code.key}</td>
              <td>
                {table.table_code ? (
                  <ProductImage
                    src={table.table_code.url}
                    alt="Foto do QRCode"
                  />
                ) : (
                  <p>Sem QRCode</p>
                )}
              </td>
              <td>
                <ReactToPrint
                  trigger={() => <button type="button">Imprimir código</button>}
                  content={() => componentRef.current}
                />
                <PrintComponent ref={componentRef}>
                  <img src={table.table_code.url} alt="Qrcode" />
                </PrintComponent>
              </td>
              <td>
                <Actions>
                  <StyledLink
                    to={{
                      pathname: 'tables/edit',
                      state: {
                        table,
                      },
                    }}
                  >
                    <MdEdit size={20} />
                  </StyledLink>
                  <Delete onClick={() => handleDelete(table.id)}>
                    <MdDelete size={20} />
                  </Delete>
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </TablesTable> */}
    </Container>
  );
}

export default TableSessions;
