import styled, { css } from 'styled-components';

export const DoneButton = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #28a745;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const EntregaFacilInfo = styled.div`
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
`;

export const ButtonCancel = styled.button`
  color: #ff2c3a;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  font-weight: bold;
`;
export const DisplayNoneImprimir = styled.button`
  display: none;
`;
export const Print = styled.button`
  height: 48px;
  width: 440px;
  border-radius: 5px;

  border: none;
  background: #ff2c3a;
  font-weight: bold;

  svg {
    margin-right: 6px;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  }
`;
export const PrintOutline = styled.button`
  height: 48px;
  width: 440px;
  border-radius: 5px;

  border: none;
  background: transparent;
  color: #ff2c3a;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 20px;
  svg {
    margin-right: 6px;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  }
`;

export const Footer = styled.footer`
  display: fixed;
  bottom: 20px;
`;

export const ContainerOrders = styled.div`
  max-height: 70vh;
  overflow-y: scroll;

  ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
      max-height: 55vh;
    `}

  @media (max-width: 1441px) {
    max-height: 55vh;
  }
`;

export const ModalContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ModalContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  h3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #4d4d4c;
    margin: 0;
  }

  svg {
    cursor: pointer;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  align-self: flex-start;

  svg {
    cursor: pointer;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #cdcdcd;
  flex-direction: column;
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  gap: 5px;

  ${(props) => props.grow && 'flex-grow: 1;'}
`;

export const CardColumnItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const TableNumber = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
`;

export const Badge = styled.button`
  font-size: 11px;
  text-align: left;
  width: fit-content;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 0;
  margin-top: 4px;
  background-color: transparent;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.color};
  border-radius: 100px;
  padding: 4px 8px;
  transition: all 0.1s;
  &:hover {
    background-color: ${(props) => props.color};
    color: #fff;
  }
  &:focus {
    outline: 1;
    outline-color: ${(props) => props.color};
  }
  &:active {
    background-color: ${(props) => props.color};
    opacity: 0.8;
    color: #fff;
  }
`;