import React, { useMemo } from 'react';

import {
  Container,
  Order,
  Restaurant,
  Body,
  Time,
  Items,
  Item,
  ItemName,
  Complements,
  ComplementsTitle,
  Obs,
  Footer,
  DeliveryInfo,
  DeliveryPrice,
  ItemHeader,
} from './styles';

import formatTime from '~/services/formatTime';
import formatPhone from '~/services/formatPhoneString';
import formatCompleteDate from '~/services/formatCompleteDate';
import formatValue from '~/services/formatValue';
import { useAuth } from '~/context/AuthContext';
import formatDateYYYY from '~/services/formatDateYYYY';

const PrintNewOrderComponent = React.forwardRef(
  ({ item, restaurant, orders }, ref) => {
    const userName = useMemo(() => {
      return item?.buyer
        ? formatPhone(
          item?.buyer?.phone ? item?.buyer.phone : item?.buyer?.ifood_phone
        )
        : item?.waiter?.name;
    }, [item]);
    const userNameDelivery = item?.buyer
      ? item?.buyer?.ifood_phone
        ? item?.buyer?.ifood_phone
        : ''
      : '';

    const { user } = useAuth();

    function changeValue(value) {
      const newValue = value.replace('.', ',');

      return newValue;
    }

    function searchTableName(table) {
      if (table.table_type === 'balcony') {
        return 'Balcão';
      }

      if (table.table_type === 'pos') {
        return 'POS';
      }

      if (table.table_number === -10) {
        return 'Mesa Copa';
      }

      if (table.table_type === 'delivery' && item?.scheduled_to) {
        return 'Delivery Agendado';
      }

      if (table.table_type === 'delivery' && item?.with_withdrawal) {
        return 'Retirada';
      }

      if (table.table_type === 'delivery') {
        return 'Delivery';
      }

      if (table.table_type === 'command') {
        return `Comanda  ${table.table_number}`;
      }

      return `Mesa  ${table.table_number}`;
    }

    function getPaymentStatus(item) {
      if (item?.ifood_paid) {
        return 'Pagamento já efetuado.';
      } else if (
        item?.intended_payment_method?.id === 301 ||
        item?.intended_payment_method?.id === 238 ||
        item?.intended_payment_method?.id === 572 ||
        item?.intended_payment_method?.id === 2548
      ) {
        return 'Pagamento já efetuado';
      } else {
        return 'Pagamento na entrega.';
      }
    }

    return (
      <Container ref={ref}>
        <Restaurant style={{ paddingTop: 20 }}>
          <strong>{restaurant?.fantasy_name}</strong>
        </Restaurant>

        {item?.table.table_type === 'delivery' && (
          <Restaurant
            style={{
              fontSize: 20,
              paddingBottom: 0,
              display: 'flex',
              flexDirection: 'column',
              paddingTop: 0,
            }}
          >
            <span style={{ fontSize: 25 }}>
              {item?.basket?.ifood_table
                ? item?.basket?.ifood_table
                : searchTableName(item?.table)}
            </span>

            <strong style={{ fontSize: 25 }}>
              PEDIDO #{item?.attendance_password}
            </strong>
          </Restaurant>
        )}
        <Body style={{ borderBottom: '1px solid #858585' }}>
          {item?.table.table_type !== 'delivery' && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <strong style={{ fontSize: 20 }}>NOVO PEDIDO</strong>

              <strong style={{ fontSize: 20 }}>
                #{item?.basket.basket_id}
              </strong>
            </div>
          )}

          {item?.table.table_type !== 'delivery' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                fontSize: 20,
              }}
            >
              {item?.table?.table_type !== 'delivery' && (
                <strong>{searchTableName(item?.table)}</strong>
              )}

              <strong>
                {item?.table?.table_type === 'delivery'
                  ? ''
                  : userName
                    ? `${userName} - ${item?.buyer?.name || ''}`
                    : `${item?.buyer?.name || ''}`}
              </strong>
            </div>
          )}
        </Body>
        {item?.table?.table_type === 'command' &&
          item?.basket?.command_table_number && (
            <Order>{`Mesa ${item?.basket?.command_table_number}`}</Order>
          )}
        <Order>
          {item?.table?.table_type === 'prepaid' && <p>Pagamento Antecipado</p>}
          {item?.basket?.ifood_table && <p>iFood</p>}

          {item?.scheduled_to && (
            <p>{`Agendado para ${formatCompleteDate(item?.scheduled_to)}`}</p>
          )}
        </Order>

        <Body>
          <Time>
            <strong>{formatDateYYYY(item?.basket.start_time)}</strong>
          </Time>

          <Time>
            <strong>{formatTime(item?.basket.start_time)}</strong>
          </Time>
        </Body>

        {item?.table.table_type === 'delivery' && (
          <DeliveryInfo>
            {item?.basket?.schedule && (
              <strong>
                <span>{item?.basket?.schedule}</span>
              </strong>
            )}
            {item?.ifood_restaurant?.name && (
              <span>Restaurante iFood: {item.ifood_restaurant.name}</span>
            )}
            {item?.delivery_by && (
              item?.delivery_by === "MERCHANT" ? (
                <span>
                  Modalidade de entrega: Agente Fixo
                </span>
              ) : (
                <span>
                  Modalidade de entrega: Agente Livre (Nuvem)
                </span>
              )
            )}
            {item?.buyer?.localizer && (
              <span>ID: {item?.buyer?.localizer}</span>
            )}
            {item?.table?.table_type === 'delivery' && (
              <span>{userNameDelivery} </span>
            )}

            <ItemHeader
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <span style={{ color: '#fff' }}>CLIENTE</span>
              <span style={{ color: '#fff' }}>{item?.buyer?.phone}</span>
            </ItemHeader>
            <span>{item?.buyer?.name}</span>
            {/* <span>{item?.buyer?.email}</span> */}
            {item?.with_withdrawal.toString() === 'false' && (
              <>
                <span style={{ maxWidth: '100mm' }}>
                  {item?.buyer?.delivery_address?.street || ''} -{' '}
                  {item?.buyer?.delivery_address?.number || ''} -{' '}
                  {item?.buyer?.delivery_address?.complement
                    ? `${item?.buyer?.delivery_address?.complement} - `
                    : ''}
                  {item?.buyer?.delivery_address?.neighborhood || ''} -{' '}
                  {item?.buyer?.delivery_address?.city || ''} -{' '}
                  {item?.buyer?.delivery_address?.state || ''}
                </span>

                <span>{item?.buyer?.delivery_address?.zip_code || ''}</span>

                <span style={{ maxWidth: '100mm' }}>
                  {item?.buyer?.delivery_address?.reference || ''}
                </span>
              </>
            )}
            <span>
              {`Método de pagamento: ${item?.intended_payment_method?.name
                ? item?.intended_payment_method?.name
                : item?.payment_method?.name
                  ? item?.payment_method?.name
                  : 'Não informado'
                }`}
            </span>

            <span>{getPaymentStatus(item)}</span>

            {item?.with_withdrawal && (
              <span style={{ fontWeight: 'bold' }}>Cliente irá retirar.</span>
            )}

            {item?.ifood_document && (
              <span style={{ fontWeight: 'bold' }}>
                {item.ifood_document.length === 11 ? 'CPF:' : 'CNPJ:'}{' '}
                {item.ifood_document}
              </span>
            )}

            {item?.details && (
              <span style={{ fontWeight: 'bold' }}>{item?.details}</span>
            )}
          </DeliveryInfo>
        )}

        <Items>
          <Item>
            {item?.table.table_type === 'delivery' ? (
              <ItemHeader
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <span>PEDIDO</span>
                <span>VALOR</span>
              </ItemHeader>
            ) : (
              <ItemHeader>
                <span>QTD</span>
                <span>ITEMS</span>
              </ItemHeader>
            )}
          </Item>
          {orders?.length > 0 &&
            orders?.map((order) => (
              <Item key={order.id}>
                <ItemName>
                  {order.use_weight ? (
                    <strong>
                      {changeValue(order.weight)}kg {order.product.name}
                    </strong>
                  ) : (
                    <strong>
                      <span>{order.amount}</span>
                      <span style={{ marginLeft: 28 }}>
                        {order.product.name}
                      </span>
                    </strong>
                  )}

                  {item?.table.table_type === 'delivery' && (
                    <strong>{formatValue(order?.total_price)}</strong>
                  )}
                </ItemName>
                {order.complement_categories.map((category) => (
                  <div key={category.id} style={{ marginLeft: 28 }}>
                    {user.print_complement_category_title && (
                      <ComplementsTitle>
                        <strong>{category.complement_category.name}:</strong>
                      </ComplementsTitle>
                    )}

                    {category.order_complements.map((complement) => (
                      <Complements key={complement.id}>
                        <strong>
                          {complement.amount} {complement.complement.name}{' '}
                        </strong>
                      </Complements>
                    ))}
                  </div>
                ))}

                {order.details && (
                  <Obs>
                    <strong>Obs: {order.details}</strong>
                  </Obs>
                )}
              </Item>
            ))}
        </Items>

        {item?.table.table_type === 'delivery' && (
          <DeliveryPrice>
            <div>
              <span>Pedido:</span>
              <span>
                {formatValue(
                  parseFloat(item?.basket?.total_price) +
                  parseFloat(item?.merchant_discount || 0) -
                  parseFloat(item?.additional_fees || 0)
                )}
              </span>
            </div>
            {item?.delivery_tax_price && (
              <div>
                <span>Taxa de entrega:</span>
                <span>{formatValue(item?.delivery_tax_price)}</span>
              </div>
            )}
            {!item?.basket?.ifood_id && item?.delivery_fee_discount && item?.delivery_fee_discount > 0 && (
              <div>
                <span>
                  Cupom de entrega grátis aplicado
                </span>
              </div>
            )}
            {item?.additional_fees && item?.additional_fees > 0 && (
              <div>
                <span>Taxa de serviço iFood:</span>
                <span>{formatValue(item?.additional_fees)}</span>
              </div>
            )}
            {item?.coupon_discount &&
              item?.ifood_discount === null &&
              item?.merchant_discount === null && (
                <div>
                  <span>Desconto do cupom:</span>
                  <span> {formatValue(item?.coupon_discount)}</span>
                </div>
              )}
            {item?.ifood_discount > 0 && (
              <div>
                <span>Cupom do iFood:</span>
                <span>- {formatValue(item?.ifood_discount)}</span>
              </div>
            )}
            {item?.merchant_discount > 0 && (
              <div>
                <span>Cupom do restaurante:</span>
                <span>- {formatValue(item?.merchant_discount)}</span>
              </div>
            )}

            {item?.rescue && (
              <div>
                <span>Resgate do clube:</span>
                <span>- {formatValue(item?.rescue?.cashback)}</span>
              </div>
            )}

            <div>
              <span>Total:</span>
              <span>
                {formatValue(
                  Number(item?.total_delivery_price) -
                  (Number(item?.rescue?.cashback) || 0) -
                  Number(item?.ifood_discount || 0)
                )}
              </span>
            </div>

            {item?.user_change > 0 &&
              (item?.intended_payment_method?.name === 'Dinheiro' ||
                item?.intended_payment_method?.name === 'Dinheiro (iFood)' ||
                item?.payment_method?.name === "Dinheiro") && (
                <>
                  <div>
                    <span>Receber do cliente:</span>
                    <span>{formatValue(Number(item?.user_change))}</span>
                  </div>

                  <div>
                    <span>Troco:</span>
                    <span>
                      {formatValue(
                        Number(item?.user_change) -
                        (Number(item?.total_delivery_price) -
                          (Number(item?.rescue?.cashback) || 0) -
                          Number(item?.ifood_discount || 0))
                      )}
                    </span>
                  </div>
                </>
              )}
          </DeliveryPrice>
        )}

        <Footer>
          <strong>VERSÃO WEB</strong>
          <strong>Desenvolvido por Takeat</strong>
        </Footer>
      </Container>
    );
  }
);
export default PrintNewOrderComponent;
