import React, { useState, useCallback, useRef } from 'react';
import { Form } from '@unform/web';

import { Modal, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import Input from '~/components/Form/Input';
import { Button } from 'ui-kit-takeat';
import Select from '~/components/Form/SelectInput';
import { FiTrash } from 'react-icons/fi';

import { FaPen, FaTrash } from 'react-icons/fa';

import getValidationErrors from '~/services/getValidationErrors';
import * as Yup from 'yup';

import nfeInfo from '~/services/NFeInfo';
import InputCurrency from '~/components/Form/InputCurrency';
import { Container } from './styles';

export default function NfeAvulsaItem({
  item,
  itens,
  setItens,
  key,
  number,
  nextId,
  setNextId,
  decimalFormatter,
  percentFormatter,
  currencyFormatter,
  icms_s_t_devolucao,
  icms_origem_options,
  user
}) {
 const [itemOptions, setItemOptions]= useState([
   {label: 'Código Produto', value: 'codigo_produto'},
   {label: 'Descrição', value: 'descricao'},
   {label: 'CFOP', value: 'cfop'},
   {label: 'NCM', value: 'codigo_ncm'},
   {label: 'Unidade Comercial', value: 'unidade_comercial'},
   {label: 'Unidade Tributavel', value: 'unidade_tributavel'},
   {label: 'Quantidade Comercial', value: 'quantidade_comercial'},
   {label: 'Quantidade Tributavel', value: 'quantidade_tributavel'},
   {label: 'Valor Unitário Comercial', value: 'valor_unitario_comercial'},
   {label: 'Valor Unitario Tributavel', value: 'valor_unitario_tributavel'},
   {label: 'Valor Bruto', value: 'valor_bruto'},
   {label: 'PIS Situação Tributaria', value: 'pis_situacao_tributaria'},
   {label: 'Cofins Situação Tributaria', value: 'cofins_situacao_tributaria'},
   {label: 'ICMS Situação Tributaria', value: 'icms_situacao_tributaria'},
   {label: 'ICMS Origem', value: 'icms_origem'},
   {label: 'ICMS Valor', value: 'icms_valor'},
   {label: 'ICMS Aliquota', value: 'icms_aliquota'},
   {label: 'ICMS Base Calculo', value: 'icms_base_calculo'},
   {label: 'ICMS Modalidade Base Calculo', value: 'icms_modalidade_base_calculo'},
   {label: 'CEST', value: 'cest'},
 ]);

 const [thisItens, setThisItens] = useState([]);

 const [selectValue, setSelectValue] = useState({label: 'Código Produto', value: 'codigo_produto'});
 const [inputValue, setInputValue] = useState('');

 const formRefEdit = useRef(null);

 const [modalEdit, setModalEdit] = useState(false);
 function toggleEdit() {
   console.log(item);
   setModalEdit(!modalEdit);
 }

 const [modalDelete, setModalDelete] = useState(false);
 function toggleDelete() {
   setModalDelete(!modalDelete);
 }

  let dataItensEdit = {
    valor_bruto: item.valor_bruto || '',
    valor_frete: item.valor_frete || '',
    valor_desconto: item.valor_desconto || '',
    valor_unitario_comercial: item.valor_unitario_comercial || '',
    quantidade_comercial: item.quantidade_comercial || '',
    valor_unitario_tributavel: item.valor_unitario_tributavel || '',
    quantidade_tributavel: item.quantidade_tributavel || '',
    icms_base_calculo: item.icms_base_calculo || '',
    icms_aliquota: item.icms_aliquota || '',
    icms_valor: item.icms_valor || '',
    pis_aliquota_porcentual: item.pis_aliquota_porcentual || '',
    pis_base_calculo: item.pis_base_calculo || '',
    pis_valor: item.pis_valor || '',
    cofins_aliquota_porcentual: item.cofins_aliquota_porcentual || '',
    cofins_base_calculo: item.cofins_base_calculo || '',
    cofins_valor: item.cofins_valor || '',
    icms_base_calculo_retido_st: item.icms_base_calculo_retido_st || '',
    icms_aliquota_final: item.icms_aliquota_final || '',
    icms_valor_substituto: item.icms_valor_substituto || '',
    icms_valor_retido_st: item.icms_valor_retido_st || '',
  };

  const [icmsSituacaoTributaria, setIcmsSituacaoTributaria] = useState(item.icms_situacao_tributaria || null);
  const [icmsOrigem, setIcmsOrigem] = useState(item.icms_origem || null);
  const [icmsModalidadeBaseCalculo, setIcmsModalidadeBaseCalculo] = useState(item.icms_modalidade_base_calculo || null);
  const [pisSituacaoTributaria, setPisSituacaoTributaria] = useState(item.pis_situacao_tributaria || null);
  const [cofinsSituacaoTributaria, setCofinsSituacaoTributaria] = useState(item.cofins_situacao_tributaria || null);

  const validateItemEdit = useCallback(async () => {
    try {
      if (formRefEdit.current) {
        formRefEdit.current.setErrors({});
      }

      let data = {
        numero_item: item.numero_item,
        codigo_produto: formRefEdit.current.getFieldValue('codigo_produto'),
        descricao: formRefEdit.current.getFieldValue('descricao'),
        codigo_ncm: formRefEdit.current.getFieldValue('codigo_ncm'),
        cfop: formRefEdit.current.getFieldValue('cfop'),
        unidade_comercial: formRefEdit.current.getFieldValue('unidade_comercial'),
        unidade_tributavel: formRefEdit.current.getFieldValue('unidade_tributavel'),
        cest: formRefEdit.current.getFieldValue('cest'),
        codigo_beneficio_fiscal: formRefEdit.current.getFieldValue('codigo_beneficio_fiscal'),
      };

      // console.log('formRefEdit.current', formRefEdit, formRefEdit.current.getFieldValue('cfop'));
      
      data.icms_situacao_tributaria = icmsSituacaoTributaria;
      data.icms_origem = icmsOrigem;
      data.icms_modalidade_base_calculo = icmsModalidadeBaseCalculo;
      data.pis_situacao_tributaria = pisSituacaoTributaria;
      data.cofins_situacao_tributaria = cofinsSituacaoTributaria;

      console.log('data1', data);

      //o dataItensEdit sao os itens que usa o InputCurrency com formato
      //o que ta no "data" sao os outros valores que usam o Input
      data.valor_bruto = dataItensEdit.valor_bruto || null;
      data.valor_frete = dataItensEdit.valor_frete || null;
      data.valor_desconto = dataItensEdit.valor_desconto || null;
      data.valor_unitario_comercial = dataItensEdit.valor_unitario_comercial || null;
      data.quantidade_comercial = dataItensEdit.quantidade_comercial || null;
      data.valor_unitario_tributavel = dataItensEdit.valor_unitario_tributavel || null;
      data.quantidade_tributavel = dataItensEdit.quantidade_tributavel || null;
      data.icms_base_calculo = dataItensEdit.icms_base_calculo || null;
      data.icms_aliquota = dataItensEdit.icms_aliquota || null;
      data.icms_valor = dataItensEdit.icms_valor || null;
      data.pis_aliquota_porcentual = dataItensEdit.pis_aliquota_porcentual || null;
      data.pis_base_calculo = dataItensEdit.pis_base_calculo || null;
      data.pis_valor = dataItensEdit.pis_valor || null;
      data.cofins_aliquota_porcentual = dataItensEdit.cofins_aliquota_porcentual || null;
      data.cofins_base_calculo = dataItensEdit.cofins_base_calculo || null;
      data.cofins_valor = dataItensEdit.cofins_valor || null;

      data.icms_base_calculo_retido_st = dataItensEdit.icms_base_calculo_retido_st || null;
      data.icms_aliquota_final = dataItensEdit.icms_aliquota_final || null;
      data.icms_valor_substituto = dataItensEdit.icms_valor_substituto || null;
      data.icms_valor_retido_st = dataItensEdit.icms_valor_retido_st || null;

      console.log('data', data);

      const schemaValidator = {
        descricao: Yup.string().required('Obrigatório'),
        codigo_ncm: Yup.string().required('Obrigatório'),
        cfop: Yup.string().required('Obrigatório'),
        codigo_produto: Yup.string().required('Obrigatório'),
        quantidade_comercial: Yup.string().required('Obrigatório'),
        quantidade_tributavel: Yup.string().required('Obrigatório'),
        icms_situacao_tributaria: Yup.string().required('Obrigatório'),
        valor_unitario_comercial: Yup.string().required('Obrigatório'),
        unidade_comercial: Yup.string().required('Obrigatório'),
        valor_unitario_tributavel: Yup.string().required('Obrigatório'),
        unidade_tributavel: Yup.string().required('Obrigatório'),
        valor_bruto: Yup.string().required('Obrigatório'),
        icms_origem: Yup.string().required('Obrigatório'),
        icms_situacao_tributaria: Yup.string().required('Obrigatório'),
        pis_situacao_tributaria: Yup.string().required('Obrigatório'),
        cofins_situacao_tributaria: Yup.string().required('Obrigatório'),
      };

      if(data.icms_situacao_tributaria === '00'){
        schemaValidator.icms_aliquota = Yup.string().required('Obrigatório');
        schemaValidator.icms_base_calculo = Yup.string().required('Obrigatório');
        schemaValidator.icms_modalidade_base_calculo = Yup.string().required('Obrigatório');
      }

      const schema = Yup.object().shape(schemaValidator);

      await schema.validate(data, {
        abortEarly: false,
      });

      const newItens = [...itens.filter(it => it.numero_item !== item.numero_item), data];

      setItens(newItens.sort((a, b) => b.numero_item - a.numero_item));
      localStorage.setItem('@gddashboard:itensNotaAvulsa', JSON.stringify(newItens));
      toggleEdit();
    }catch(error){
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRefEdit.current) {
          formRefEdit.current.setErrors(errors);
        }
      }
    }
  }, [item, setItens, setNextId, nextId, toggleEdit, dataItensEdit]);

  const deleteItem = useCallback(() => {
    const newItens = itens.filter(it => it.numero_item !== item.numero_item);
    let i = 1;

    newItens.forEach(it => {
      it.numero_item = i;
      i++;
    });

    setItens(newItens);
    localStorage.setItem('@gddashboard:itensNotaAvulsa', JSON.stringify(newItens));

    setNextId(nextId - 1);
  }, [item, itens, setItens, nextId, setNextId]);

 return (
    <Container>
      <Row>
        <Col md="1">
          <strong>Item {Number(number) + 1}</strong>
        </Col>
        <Col md="8">
          {item.descricao}
        </Col>
        <Col md="1" style={{textAlign: 'right'}}>
          R${item.valor_bruto?.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })}
        </Col>
        <Col md="2" style={{textAlign: 'right'}}>
          <FaPen
            onClick={toggleEdit}
            title="Editar item"
            style={{ cursor: 'pointer', marginLeft: 8 }}
            color="#666666"
          />
          <FaTrash
            color="#ff403d"
            title="Deletar item"
            style={{ cursor: 'pointer', marginLeft: 8 }}
            onClick={toggleDelete}
          />
        </Col>
      </Row>

      <Modal isOpen={modalEdit} toggle={toggleEdit} size="xl">
        <Form onSubmit={validateItemEdit} ref={formRefEdit} initialData={item}>
          <ModalBody>
            Editar Item
            <Row>
              <Col md="2">
                <Input
                  name="codigo_produto"
                  label="Código do Produto"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>
              <Col md="4">
                <Input
                  name="descricao"
                  label="Descrição / Nome do Produto"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="2">
                <Input
                  name="codigo_ncm"
                  label="NCM"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="2">
                <Input
                  name="cfop"
                  label="CFOP"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="2">
                <Input
                  name="cest"
                  label="CEST"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>
            </Row>
            
            {/* QTD comercial | Valor unit. Comercial | UN comercial | QTD tributável | Valor unit. Tributável | UN tributável */}
            <Row>
              <Col md="2">
                <InputCurrency
                  name="quantidade_comercial"
                  label="Quantidade Comercial"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  format={decimalFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.quantidade_comercial = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="valor_unitario_comercial"
                  label="Valor Unit. Comercial"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.valor_unitario_comercial = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <Input
                  name="unidade_comercial"
                  label="Unidade Comercial"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="quantidade_tributavel"
                  label="Quantidade Tributável"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  format={decimalFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.quantidade_tributavel = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="valor_unitario_tributavel"
                  label="Valor Unit. Tributável"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.valor_unitario_tributavel = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <Input
                  name="unidade_tributavel"
                  label="Unidade Tributável"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>
              
            </Row>
            
            {/* Valor bruto | Valor Desconto | Valor Frete |Origem Mercadoria | CEST */}
            <Row>
              <Col md="2">
                <InputCurrency
                  prefix="R$"
                  label="Valor Bruto"
                  placeholder="R$"
                  format={currencyFormatter}
                  name="valor_bruto"
                  fontSize={{ fontSize: 12 }}
                  onValueChange={(e) => {
                    dataItensEdit.valor_bruto = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="valor_desconto"
                  label="Valor Desconto"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  prefix="R$"
                  placeholder="R$"
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.valor_desconto = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="valor_frete"
                  label="Valor Frete"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  prefix="R$"
                  placeholder="R$"
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.valor_frete = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              {/* <Col md="2">
                <Input
                  name="cest"
                  label="CEST"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col> */}
              <Col md="2">
                <Input
                  name="codigo_beneficio_fiscal"
                  label="cBenef"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="4">  
                <Select
                  name="icms_situacao_tributaria"
                  options={icms_s_t_devolucao}
                  label={`ICMS Situação Tributária (${user.regime_tributario === '1' ? 'CSOSN' : 'CST'})`}
                  value={icms_s_t_devolucao.filter(icms => icms.value === icmsSituacaoTributaria)}
                  onChange={e => setIcmsSituacaoTributaria(e.value)}
                />
              </Col>
            </Row>
            
            {/* ICMS */}
            <Row>
              <Col md="3">
                <Select
                  name="icms_origem"
                  options={icms_origem_options}
                  label="ICMS de Origem"
                  value={icms_origem_options.filter(icms => icms.value === icmsOrigem)}
                  onChange={e => setIcmsOrigem(e.value)}
                />
              </Col>

              <Col md="3">
                <Select
                  name="icms_modalidade_base_calculo"
                  options={nfeInfo.icms_modalidade_base_calculo}
                  label="ICMS Modalidade Base Cálc."
                  value={nfeInfo.icms_modalidade_base_calculo.filter(icms => icms.value === icmsModalidadeBaseCalculo)}
                  onChange={e => setIcmsModalidadeBaseCalculo(e.value)}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="icms_base_calculo"
                  label="ICMS Base Cálculo"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.icms_base_calculo = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="icms_aliquota"
                  label="ICMS Alíquota"
                  type="text"
                  placeholder="%"
                  suffix="%"
                  fontSize={{ fontSize: 12 }}
                  format={percentFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.icms_aliquota = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="icms_valor"
                  label="ICMS Valor"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.icms_valor = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>
            </Row>
            
            {/* PIS */}
            <Row>
              <Col md="3">
                <Select
                  name="pis_situacao_tributaria"
                  options={nfeInfo.pis_situacao_tributaria}
                  label="PIS Situação Trib."
                  value={nfeInfo.pis_situacao_tributaria.filter(icms => icms.value === pisSituacaoTributaria)}
                  onChange={e => setPisSituacaoTributaria(e.value)}
                />
              </Col>
              
              <Col md="3">
                <InputCurrency
                  name="pis_base_calculo"
                  label="PIS Base Cálculo"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.pis_base_calculo = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="pis_aliquota_porcentual"
                  label="PIS Alíquota"
                  type="text"
                  placeholder="%"
                  fontSize={{ fontSize: 12 }}
                  format={percentFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.pis_aliquota_porcentual = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>
              
              <Col md="3">
                <InputCurrency
                  name="pis_valor"
                  label="PIS Valor"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.pis_valor = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col> 
            </Row>
            
            {/* COFINS */}
            <Row>
              <Col md="3">
                <Select
                  name="cofins_situacao_tributaria"
                  options={nfeInfo.pis_situacao_tributaria}
                  label="COFINS Situação Trib."
                  value={nfeInfo.pis_situacao_tributaria.filter(icms => icms.value === cofinsSituacaoTributaria)}
                  onChange={e => setCofinsSituacaoTributaria(e.value)}
                />
              </Col>
              
              <Col md="3">
                <InputCurrency
                  name="cofins_base_calculo"
                  label="COFINS Base Cálculo"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.cofins_base_calculo = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="cofins_aliquota_porcentual"
                  label="COFINS Alíquota"
                  type="text"
                  placeholder="%"
                  fontSize={{ fontSize: 12 }}
                  format={percentFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.cofins_aliquota_porcentual = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>
              
              <Col md="3">
                <InputCurrency
                  name="cofins_valor"
                  label="COFINS Valor"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.cofins_valor = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col> 
            </Row>

            {/* Mais ICMS */}
            <Row>
              <Col md="3">
                <InputCurrency
                  name="icms_base_calculo_retido_st"
                  label="ICMS Base Calc. Retido ST"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.icms_base_calculo_retido_st = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="icms_aliquota_final"
                  label="ICMS Alíquota Final"
                  type="text"
                  prefix="%"
                  placeholder="%"
                  fontSize={{ fontSize: 12 }}
                  format={percentFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.icms_aliquota_final = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="icms_valor_substituto"
                  label="ICMS Valor Substituto"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.icms_valor_substituto = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="icms_valor_retido_st"
                  label="ICMS Valor Retido ST"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.icms_valor_retido_st = e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggleEdit}
              title="Cancelar"
              buttonColor="#dc3545"
            />
            <Button
              type="button" 
              onClick={validateItemEdit}
              buttonColor="#2ec9b7"
              title="Salvar"
            />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalBody>
          Tem certeza que deseja remover o item <strong>{item.descricao}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={toggleDelete}
            title="Continuar com item"
            buttonColor="#2ec9b7"
          />
          <Button
            type="button" 
            onClick={deleteItem}
            buttonColor="#dc3545"
            title="Remover" 
          />
        </ModalFooter>
      </Modal>
    </Container>
  );

  return (
    <div style={{padding: 15}}>
      <Form>
        <Row>
          <Col md="1" style={{marginTop: 15}}>
            <strong>Item {Number(number) + 1}) </strong>
          </Col>
          <Col md="4">
            <Select 
              options={itemOptions}
              name="option"
              value={selectValue}
              onChange={e => {
                setSelectValue(e);
                // setThisItens([...thisItens, {value: e.label, value: 'teste'}])
              }}
            />
          </Col>
          <Col md="5">
            <Input 
              name={`input_name_${item?.numero_item}`}
              id={`input_name_${item?.numero_item}`}
              value={inputValue}
              onChange={e => {
                setInputValue(e.target.value);
              }}
            />
          </Col>
          <Col md="2">
            {/* <ActionButton
              name="add_campo_item"
              icon={<TiPlus />}
              onClick={e =>  {
                setThisItens([...thisItens, {label: selectValue.label, name: selectValue.value, value: inputValue}]);
                setItemOptions(itemOptions.filter(it => it.value !== selectValue.value));
                setSelectValue(itemOptions.filter(it => it.value !== selectValue.value)[0] || []);
                setInputValue('');
              }}
              disabled={itemOptions.length === 0}
              style={{marginTop: 10}}
            >+</ActionButton> */}

                <button name="add_campo_item" 
                  onClick={e =>  {
                    setThisItens([...thisItens, {label: selectValue.label, name: selectValue.value, value: inputValue}]);
                    setItemOptions(itemOptions.filter(it => it.value !== selectValue.value));
                    setSelectValue(itemOptions.filter(it => it.value !== selectValue.value)[0] || []);
                    setInputValue('');
                    const hasDoc = document.getElementById(`input_name_${item.numero_item}`);
                    if(hasDoc){
                      hasDoc.focus();
                    }
                  }}
                  type="button"
                  style={{
                    borderRadius: '25px',
                    height: 40,
                    width: 40,
                    border: 'none',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    background: '#2AB7A6',
                    color: 'white',
                    textAlign: 'center',
                    marginTop: 10,
                    fontSize: 20,
                    fontWeight: 600
                  }}
                  disabled={itemOptions.length === 0}
                > + </button>

              {item.numero_item !== 1 &&
                <button
                  name="remove_campo_item"
                  onClick={e =>  {
                    setItens(itens.filter(it => it.numero_item !== item.numero_item));
                  }}
                  type="button"
                  style={{
                    borderRadius: '25px',
                    height: 40,
                    width: 40,
                    border: 'none',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    background: 'red',
                    color: 'white',
                    textAlign: 'center',
                    marginTop: 10,
                    marginLeft: 15
                  }}
                > <FiTrash size={17}/> </button>
              }
          </Col>
        </Row>
        <div>
          {thisItens.map(it => (
            <Row style={{textAlign: 'left', paddingLeft: 20}}>
              <Col md="1" style={{textAlign: 'right'}}>
                <FiTrash size={16} color="red" style={{cursor: 'pointer'}} onClick={e => {
                  setThisItens(thisItens.filter(th => th.name !== it.name));
                  setItemOptions([...itemOptions, {label: it.label, value: it.name}]);
                  setSelectValue([...itemOptions, {label: it.label, value: it.name}][0] || []);
                }}/>
              </Col>
              <Col md="11">
                <strong>{it.label}:</strong> {it.value} 
              </Col>
              {/* <Col md="6">
                {it.value}  
              </Col> */}
            </Row>
          ))}
        </div>
      </Form>
    </div>
  );
}
