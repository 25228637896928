import styled from 'styled-components';

import NumberFormat from 'react-number-format';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';

export const Card = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
  max-height: calc(100% - 66px);
  overflow-y: auto;
  padding-bottom: 120px;
  
`;

export const Map = styled.div`
  width: 100%;
  height: 600px;
  border-radius: 4px;
`;

export const AddressesTable = styled(Table)`
  width: 100%;
  min-width: 360px;
  margin: 0px;
  padding: 0px;

  thead {
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    text-align: left;

    tr > th {
      text-transform: none;
    }
  }

  tbody {
    tr:first-child td {
      padding: 5px !important;
    }

    tr:not(:first-child) td {
      padding: 5px 5px !important;
    }
  }
`;

export const NumberInput = styled(NumberFormat)`
  height: 44px;
  width: 100%;
  padding: 0 10px;
  border-radius: 4px;
  border: 1px solid #33333320;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 4px;
  }
`;

export const PlusButton = styled.button`
  height: 44px;
  width: 44px;
  padding: 0 10px;
  border-radius: 4px;
  border: none;
  background-color: #0ccab4;
  svg {
    color: #fff;
  }
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid #0ccab4;
    svg {
      color: #0ccab4;
    }
  }
`;

export const PenButton = styled(Link)`
  padding: 0px 6px;
  margin: 0px 5px;
  svg {
    color: #0ccab4;
    cursor: pointer;
  }
`;

export const Checkbox = styled.div`
  height: 100%;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;

  input {
    margin: auto 0;
  }

  label {
    margin-left: 10px;
    font-weight: 400;
  }

  /* input {
    background-color: #3bd2c1;
  } */
`;
