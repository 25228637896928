import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Spinner } from 'reactstrap';
import { format } from 'date-fns';
import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter } from 'react-icons/ai';
import {
  Container,
  Table,
  Order,
  Items,
  Complements,
  ComplementsTitle,
  Obs,
  DeliveryPrice,
  DeliveryInfo,
  DisplayNoneImprimir,
  Print,
} from './styles';

import api from '~/services/api';

import formatTime from '~/services/formatTime';

import { MotoboyDiv } from '../EyeModalOrders/styles';
import { ProgressCircle } from '../ProgressComponent/ProgressCircle';
import { ProgressMiddleBar } from '../ProgressComponent/ProgressMiddleBar';
import formatCompleteDate from '~/services/formatCompleteDate';
import formatValue from '~/services/formatValue';
import PrintNewOrderComponentDelivery from '../PrintNewOrderComponentDelivery';

export default function EyeModalOrdersDelivery({
  item,
  paid,
  bills,
  session,
  user,
  productBills,
}) {
  const [orders, setOrders] = useState([]);
  const [buyer, setBuyer] = useState(null);

  function changeValue(value) {
    const newValue = value.replace('.', ',');
    return newValue;
  }

  const [step] = useState(() => {
    switch (item?.status) {
      case 'pending':
        return 0;
      case 'accepted':
        return 1;
      case 'ready':
        return 2;
      case 'completed':
        return 3;
      default:
        return 0;
    }
  });

  const getOrders = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/delivery/session-orders/${item.id}`
      );

      setOrders(response.data.orders);
      setBuyer(response.data.buyer);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'delivery' && item.scheduled_to) {
      return 'Delivery Agendado';
    }
    if (table.table_type === 'delivery' && item?.with_withdrawal) {
      return 'Retirada';
    }

    if (table.table_type === 'delivery') {
      return 'Delivery';
    }

    return `MESA  ${table.table_number}`;
  }

  useEffect(() => {
    getOrders();
  }, []);

  const componentRef = useRef(null);

  return (
    <Container>
      <Table>
        <strong>{searchTableName(item.table)}</strong>
      </Table>
      <Order>
        Pedido #{item?.attendance_password}{' '}
        {item?.scheduled_to && (
          <p>Agendado para {formatCompleteDate(item?.scheduled_to)}</p>
        )}
      </Order>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '70px auto 0',
          width: '100%',
          marginBottom: 10,
        }}
      >
        <ProgressCircle
          label="Feito"
          hour={item?.start_time && format(new Date(item.start_time), 'HH:mm')}
          step={step}
          idx={0}
        />
        <ProgressMiddleBar step={step} idx={0} />
        <ProgressCircle
          label="Confirmado"
          hour={
            item?.accepted_at && format(new Date(item.accepted_at), 'HH:mm')
          }
          step={step}
          idx={1}
        />
        <ProgressMiddleBar step={step} idx={1} />
        <ProgressCircle
          label="Despachado"
          hour={item?.ready_at && format(new Date(item.ready_at), 'HH:mm')}
          step={step}
          idx={2}
        />
        <ProgressMiddleBar step={step} idx={2} />
        <ProgressCircle
          label={
            item?.ifood_delivery_time && step < 3 ? 'Previsão' : 'Entregue'
          }
          hour={
            item?.completed_at && format(new Date(item.completed_at), 'HH:mm')
          }
          step={step}
          idx={3}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{`${buyer?.name} - ${buyer?.phone}`}</span>
        <span>{formatTime(item?.start_time)}</span>
      </div>

      {item?.table.table_type === 'delivery' && (
        <DeliveryInfo>
          <span style={{ fontWeight: 'bold' }}>Dados do cliente:</span>
          {buyer?.localizer && <span>ID: {buyer?.localizer}</span>}
          <span> {buyer?.name}</span>
          <span>{buyer?.email}</span>
          {item?.with_withdrawal.toString() === 'false' && (
            <>
              <span>
                {buyer?.delivery_address?.street || ''} -{' '}
                {buyer?.delivery_address?.number || ''}
              </span>
              <span>{buyer?.delivery_address?.neighborhood || ''}</span>
              <span>
                {buyer?.delivery_address?.city || ''} -{' '}
                {buyer?.delivery_address?.state || ''}
              </span>
              <span>{buyer?.delivery_address?.zip_code || ''}</span>
            </>
          )}

          {item?.intended_payment_method?.name && (
            <>
              <span style={{ fontWeight: 'bold', marginTop: 10 }}>
                Método de pagamento:
              </span>
              <span> {item?.intended_payment_method?.name || ''}</span>
            </>
          )}

          {item?.with_withdrawal && (
            <span style={{ fontWeight: 'bold', marginTop: 10 }}>
              Cliente irá retirar o produto.
            </span>
          )}
          {item?.details && (
            <span style={{ fontWeight: 'bold', marginTop: 10 }}>
              {item?.details}
            </span>
          )}
        </DeliveryInfo>
      )}

      <div>
        <p style={{ margintTop: 16 }}>Pedido:</p>
        {orders.length > 0 ? (
          orders.map((order) => (
            <div key={order.id}>
              <div>
                {order.use_weight ? (
                  <strong>
                    {changeValue(order.weight)}kg {order.product.name}
                  </strong>
                ) : (
                  <strong>
                    {order.amount}x {order.product.name}
                  </strong>
                )}
              </div>
              {order.complement_categories.map((category) => (
                <div key={category.id}>
                  <ComplementsTitle>
                    {category.complement_category.name}:
                  </ComplementsTitle>
                  {category.order_complements.map((complement) => (
                    <Complements key={complement.id}>
                      - {complement.amount} x {complement.complement.name}{' '}
                    </Complements>
                  ))}
                </div>
              ))}
              {order.details !== '' && <Obs>Obs: {order.details}</Obs>}
            </div>
          ))
        ) : (
          <div style={{ marginTop: 20 }}>
            <Spinner />
          </div>
        )}
      </div>
      {item.motoboy && (
        <Items>
          <strong>Motoboy</strong>
          <MotoboyDiv>
            <span>{item.motoboy.name}</span>
            <span>
              {item.motoboy.phone
                ? `${item.motoboy.phone.substring(
                  0,
                  9
                )}-${item.motoboy.phone.substring(9)}`
                : ''}
            </span>
          </MotoboyDiv>
        </Items>
      )}

      {item?.table.table_type === 'delivery' && (
        <>
          <DeliveryPrice>
            {session?.discount_total ? (
              <div>
                <span>Pedido:</span>
                <span>{formatValue(item?.old_total_price)}</span>
              </div>
            ) : (
              <div>
                <span>Pedido:</span>
                <span>{formatValue(item?.total_service_price)}</span>
              </div>
            )}

            {session?.discount_total && (
              <div>
                <span>Desconto/ Cupom:</span>
                <span>-{formatValue(session?.discount_total)}</span>
              </div>
            )}
            {item?.delivery_tax_price && (
              <div>
                <span>Taxa de entrega:</span>
                <span>{formatValue(item?.delivery_tax_price)}</span>
              </div>
            )}
            {!item?.basket?.ifood_id && item?.delivery_fee_discount && item?.delivery_fee_discount > 0 && (
              <div>
                <span>
                  Cupom de entrega grátis aplicado
                </span>
              </div>
            )}

            <div>
              <span>Total:</span>

              <span>{formatValue(Number(item?.total_delivery_price))}</span>
            </div>

            {item?.user_change > 0 &&
              (item?.intended_payment_method?.name === 'Dinheiro' ||
                item?.intended_payment_method?.name === 'Dinheiro (iFood)') && (
                <div>
                  <span>Troco:</span>
                  <span>
                    {formatValue(
                      Number(item?.user_change) -
                      Number(item?.total_delivery_price)
                    )}
                  </span>
                </div>
              )}

            <div>
              <span>Pago:</span>
              <span>{formatValue(paid)}</span>
            </div>

            <div>
              {item?.payments &&
                item?.payments.length > 0 &&
                item?.payments.map((payment) => (
                  <div
                    key={payment.id}
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      - {payment?.payment_method?.name}
                    </span>
                    <span style={{ fontSize: 16 }}>
                      R$ {parseFloat(payment?.payment_value).toFixed(2)}
                    </span>
                  </div>
                ))}
            </div>

            <div>
              <span>Restante:</span>
              <span>
                {formatValue(
                  Number(item?.total_service_price) +
                  Number(item?.delivery_tax_price) -
                  Number(paid)
                )}
              </span>
            </div>
          </DeliveryPrice>

          <ReactToPrint
            // onBeforeGetContent={() => {
            //   getOrders();
            // }}
            trigger={() => (
              <Print>
                <AiOutlinePrinter color="black" size={20} />
              </Print>
            )}
            content={() => componentRef.current}
          />
          <DisplayNoneImprimir>
            <PrintNewOrderComponentDelivery
              restaurant={user}
              item={session}
              ref={componentRef}
              orders={orders}
              bills={bills}
              paid={paid}
              productBills={productBills}
              buyer={buyer}
            />
          </DisplayNoneImprimir>
        </>
      )}
    </Container>
  );
}
