import React from 'react';
import { Spinner } from 'reactstrap';
import { Content, ItemsCount, LoadingDiv } from '../styles';
import { Header, Bloco, StyleKanbanWarning } from './styles';
import OrderAndamentoDelivery from '../../Items/OrderAndamentoDelivery';
import { useAuth } from '~/context/AuthContext';

export default function CardOrdersAndamento({
  baskets,
  handleItemFinished,
  handleCancelItem,
  loading,
  handleItemReady,
  handleUpdate,
  hasOrderActive,
  countOrders,
}) {
  const { user } = useAuth();
  return (
    <Bloco>
      <Header>
        <div>{user.use_foody_delivery ? "COZINHA" : "EM ANDAMENTO"}</div>
        <ItemsCount style={{ color: '#ffa814' }}>{countOrders}</ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="warning" />
          </LoadingDiv>
        ) : (
          baskets
            .filter(
              (order) =>
                order.table.table_type === 'delivery' && !order.scheduled_to
            )
            .map((item) => (
              <OrderAndamentoDelivery
                key={item.basket.id}
                handleCancelItem={handleCancelItem}
                handleItemFinished={handleItemFinished}
                item={item}
                handleItemReady={handleItemReady}
                handleUpdate={handleUpdate}
              />
            ))
        )}
        {!hasOrderActive && (
          <StyleKanbanWarning>
            <p style={{ color: "#666666" }}>Não há pedido</p>
            <p style={{ color: "#666666" }}>no momento</p>
          </StyleKanbanWarning>
        )}


      </Content>
    </Bloco>
  );
}