import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from 'ui-kit-takeat';
import { ModalStyled } from './styles';
import React from 'react';
import { ReactComponent as Warning } from '~/assets/img/warning.svg';

export const ModalConfirmation = ({
  children,
  confirm,
  confirmText,
  cancel,
  cancelText,
  title,
  confirmDisabled,
  ...rest
}) => {
  // useEffect(() => {
  //   window.addEventListener('keypress', (e) => {
  //     if (e.key === 'Enter') {
  //       const confirmButton = document.getElementById('confirmButton');
  //       if (confirmButton) {
  //         confirmButton.click();
  //       }
  //     }
  //   });

  //   return () => {
  //     window.removeEventListener('keypress', () => {});
  //   };
  // }, []);

  return (
    <ModalStyled {...rest}>
      <div style={{ display: "flex", alignItems: "center", padding: 20 }}>
        <div>
          <Warning />
        </div>

        <span style={{ fontWeight: "bold", color: "#222222", paddingLeft: 8 }}>{title}</span>
      </div>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button
          title={cancelText || 'Cancelar'}
          onClick={() => {
            if (cancel) {
              cancel();
            }
          }}
        />
        <Button
          id="confirmButton"
          title={confirmText || 'Confirmar'}
          onClick={() => {
            if (confirm) {
              console.log('confirm function > ', confirm);
              confirm();
            }
          }}
          disabled={confirmDisabled}
          buttonColor="#2EC9B7"
        />
      </ModalFooter>
    </ModalStyled>
  );
};
