import React, { useState, useRef, useCallback } from 'react';

import { MdDelete } from 'react-icons/md';

import { format } from 'date-fns';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  ModalHeader,
  Container,
} from 'reactstrap';
import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';

import { Actions, ActionsInside, DeleteAndEditActions } from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';

import api from '~/services/api';

import RadioInput from '~/components/Form/RadioInput';
import { useAuth } from '~/context/AuthContext';
import { FaEye } from 'react-icons/fa';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function formatLocalDate(date, fmt = 'yyyy-MM-dd') {
  if (typeof date === 'string') {
    // Verifica se a string está no formato ano-mes-dia
    if (date.trim().match(/^\d\d\d\d-\d\d-\d\d$/)) {
      return format(new Date(`${date}T15:00Z`), fmt);
    } else {
      return format(new Date(date), fmt);
    }
  }

  if (date instanceof Date) {
    return format(date, fmt);
  }

  return format(new Date(0), fmt);
}

export default function Nfe({
  nfe,
  handleUpdate,
  inputs,
  cashFlows,
  getInputs,
}) {
  const selectRef = useRef(null);

  inputs = [{ value: null, label: 'Criar insumo', name: '' }, ...inputs];

  const { user } = useAuth();
  const history = useHistory();
  const [, setNfeInfo] = useState(null);
  const [nfeReq, setNfeReq] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputsToSend, setInputsToSend] = useState([]);
  const [productsLeft, setProductsLeft] = useState([]);
  const [inputsNfe, setInputsNfe] = useState([]);
  const [cashFlowToSend, setCashFlowToSend] = useState([]);
  const [cashFlowLeft] = useState([]);
  const [provider, setProvider] = useState([]);
  const [nfeId] = useState(nfe?.id);
  const [cfops, setCfops] = useState([]);
  const [, setDuplicatas] = useState([]);
  const [inputsInfo, setInputsInfo] = useState([]);
  const [inputToChange, setInputToChange] = useState([]);
  const [loadingNfe, setLoadingNfe] = useState(false);
  const [average, setAverage] = useState([]);
  const [medias, setMedias] = useState([]);

  function addInput({
    input,
    qnt,
    valor,
    produto,
    ncm,
    unidade,
    unitario,
    cashFlowToSend,
    item,
  }) {
    const data = {
      input,
      qnt,
      valor,
      produto,
      ncm,
    };

    if (input !== null) {
      const toSend = inputsToSend.filter((inp) => inp.produto !== produto);
      const newProductsLeft = productsLeft.filter(
        (prod) => prod.id !== produto
      );
      setProductsLeft(newProductsLeft);
      setInputsToSend([...toSend, data]);

      const newInputsAlready = inputsNfe.filter(
        (inp) => inp.produto !== produto
      );
      setInputsNfe(newInputsAlready);

      const unidadeUpdate =
        inputs.filter((inp) => inp.value === input)[0]?.unidade || '';
      // verificar a unidade atual do item e passar a taxa
      const new_tax =
        inputs
          .filter((inp) => inp.value === input)[0]
          ?.history_unit.filter(
            (hist) =>
              hist.old_unidade === unidade && hist.new_unidade === unidadeUpdate
          )[0]?.value || 1;

      inputsInfo.forEach((inp) => {
        if (inp.produto === produto) {
          inp.new_unidade = unidadeUpdate;
          inp.tax = parseFloat(new_tax);
        }
      });

      document.getElementById(`taxa_conversao_${produto}`).value = new_tax;
      document.getElementById(`unidade_insumo_${produto}`).value =
        unidadeUpdate;
      document.getElementById(`unidade_insumo_${produto}`).disabled = true;

      const inputSelect =
        inputs.filter((inp) => inp.value === input)[0] || null;

      const media =
        parseFloat(inputSelect.quantidade) *
          parseFloat(inputSelect.unitary_price) +
        (parseFloat(unitario) * parseFloat(qnt)) /
          (parseFloat(qnt) + parseFloat(inputSelect.quantidade));
      medias.find((med) => med.produto === produto).media = media.toFixed(2);
      if (media > 0) {
        medias.find((med) => med.produto === produto).hasMedia = true;
      } else {
        medias.find((med) => med.produto === produto).hasMedia = false;
      }

      const cashFlowId =
        inputs.filter((inp) => inp.value === input)[0]
          ?.cash_flow_category_subcategory_id || '';

      const thisValues = cashFlowValue;

      thisValues.find((cash) => cash.produto === produto).data =
        cashFlows.filter((flow) => flow.value === cashFlowId)[0]
          ? cashFlows.filter((flow) => flow.value === cashFlowId)[0]
          : null;

      if (thisValues.find((cash) => cash.produto === produto).data !== null) {
        addCashFlow({
          cashFlowToSend,
          duplicatas: cashFlowToSend,
          produto,
          cash: thisValues.find((cash) => cash.produto === produto).data,
          valor,
        });
      }

      setCashFlowValue([]);
      setCashFlowValue(thisValues);
    } else {
      const newInputsAlready = inputsNfe.filter(
        (inp) => inp.produto !== produto
      );
      setInputsNfe(newInputsAlready);

      const newInputsToSend = inputsToSend.filter(
        (inp) => inp.produto !== produto
      );
      setInputsToSend(newInputsToSend);

      const newProductsLeft = [
        {
          id: produto,
          label: `Produto #${produto}: ${item.descricao}`,
          name: item.descricao,
          unidade: item.unidade_comercial,
          quantidade: item.quantidade_comercial,
          valor: item.valor_bruto,
          cfop: item.cfop,
          ncm: item.codigo_ncm,
        },
        ...productsLeft.filter((prod) => prod.id !== produto),
      ];

      setProductsLeft(newProductsLeft);

      const thisValues = cashFlowValue;

      thisValues.find((cash) => cash.produto === produto).data = null;

      document.getElementById(`unidade_insumo_${produto}`).disabled = false;
      document.getElementById(`unidade_insumo_${produto}`).value = 'UN';

      // if (thisValues.find((cash) => cash.produto === produto).data !== null) {
      //   addCashFlow({
      //     cashFlowToSend,
      //     duplicatas: cashFlowToSend,
      //     produto,
      //     cash: thisValues.find((cash) => cash.produto === produto).data,
      //     valor,
      //   });
      // }

      setCashFlowValue([]);
      setCashFlowValue(thisValues);
    }
  }

  async function addCfop({ cfop, produto }) {
    const allCfops = cfops;
    allCfops.forEach((cf) => {
      if (cf.produto === produto) {
        cf.cfop = cfop;
      }
    });
    setCfops(allCfops);
  }

  async function addCfop({ cfop, produto }) {
    const allCfops = cfops;
    allCfops.forEach((cf) => {
      if (cf.produto === produto) {
        cf.cfop = cfop;
      }
    });
    setCfops(allCfops);
  }

  const [modal, setModal] = useState(false);
  function toggle() {
    setModal(!modal);
  }

  const [modalCreateDup, setModalCreateDup] = useState(false);
  function toggleCreateDup() {
    setModalCreateDup(!modalCreateDup);
  }

  const [modalProductsLeft, setModalProductsLeft] = useState(false);
  function toggleProductsLeft() {
    setModalProductsLeft(!modalProductsLeft);
  }

  const [modalRefuse, setModalRefuse] = useState(false);
  function toggleRefuse() {
    setModalRefuse(!modalRefuse);
  }

  const [modalUnit, setModalUnit] = useState(false);
  const toggleUnit = (produto) => {
    if (!produto) {
      setModalUnit(!modalUnit);
    } else {
      let input = inputsToSend.filter((inp) => inp.produto === produto);
      if (input.length === 0) {
        input = inputsNfe.filter((inp) => inp.produto === produto);
      }
      input = input[0];

      if (input) {
        // const { input_id } = input;
        const input_id = input?.input_id ? input.input_id : input.input;
        let inputFind = inputs.filter((inp) => inp.value === input_id);
        inputFind = inputFind[0];
        inputFind.produto = produto;
        setInputToChange(inputFind);
        if (inputFind) {
          setModalUnit(!modalUnit);
        } else {
          toast.error('Insumo não encontrado.');
        }
      } else {
        toast.error('Insumo não encontrado.');
      }
    }
  };

  const [newUnit, setNewUnit] = useState('');
  const [valueNewUnit, setValueNewUnit] = useState(1);

  const handleChangeUnit = useCallback(async () => {
    try {
      const handleData = {
        input_id: inputToChange.value,
        value: valueNewUnit,
        unit_change: newUnit,
      };

      if (!valueNewUnit || parseFloat(valueNewUnit) === 0) {
        toast.error('Valor de conversão deve ser válido e diferente de zero.');
      } else if (newUnit === '') {
        toast.error('Informe a nova unidade.');
      } else {
        await api.put(`/restaurants/change-unit`, handleData);
        document.getElementById(
          `unidade_insumo_${inputToChange.produto}`
        ).value = newUnit;
        inputs.forEach((inp) => {
          if (inp.value === inputToChange.value) {
            inp.label = `${inp.name} (${newUnit})`;
          }
        });
        setValueNewUnit(1);
        setNewUnit('');
        setModalUnit(false);
        toast.success('Unidades convertidas com sucesso!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao atualizar unidades.');
    }
  }, [newUnit, valueNewUnit]);

  const handleCancelNfe = useCallback(async () => {
    try {
      await api.put(`/restaurants/nfe-received/operation/${nfe.id}`, {
        tipo: 'desconhecimento',
      });
      handleUpdate();
      setModalRefuse(false);
      setModal(false);
      toast.success('Nota recusada.');
    } catch (error) {
      toast.error('Não foi possível recusar a Nfe desejada, tente novamente');
    }
  }, [nfe]);

  const cfop_options = [
    { value: 1101, label: '1101 - Industrialização' },
    { value: 1556, label: '1556 - Uso e consumo' },
    { value: 1403, label: '1403 - Revenda' },
    { value: 1102, label: '1102 - Revenda' },
    {
      value: 1201,
      label: '1201 - Devolução de venda de produção do estabelecimento',
    },
    {
      value: 1202,
      label:
        '1202 - Devolução de venda de mercadoria adquirida ou recebida de terceiros',
    },
    { value: 1908, label: '1908 - Bem por conta de contrato de comodato' },
    { value: 1910, label: '1910 - Entrada de bonificação, doação ou brinde' },
    {
      value: 1949,
      label:
        '1949 - Outra entrada de mercadoria ou prestação de serviço não especificada',
    },
    {
      value: 1551,
      label: '1551 - Compra de ativo imobilizado dentro do estado',
    },
    { value: 2551, label: '2551 - Compra de ativo imobilizado fora do estado' },
    {
      value: 1407,
      label:
        '1407 - Compra de mercadoria para uso ou consumo cuja mercadoria está sujeita ao regime de substituição tributária',
    },
    { value: 2101, label: '2101 - Industrilização originado de outro estado' },
    { value: 2102, label: '2102 - Revenda originado de outro estado' },
    {
      value: 2403,
      label:
        '2403 - Compra para comercialização em operação com mercadoria sujeita ao regime de substituição tributária',
    },
    {
      value: 2910,
      label: '2910 - Entrada de bonificação, doação ou brinde',
    },
    {
      value: 1651,
      label:
        '1651 - Compra de combustível ou lubrificante para industrialização subsequente',
    },
    {
      value: 1407,
      label:
        '1407 - Compra de mercadoria para uso ou consumo cuja mercadoria está sujeita ao regime de substituição tributária',
    },
    {
      value: 1556,
      label: '1556 - Compra de material para uso ou consumo',
    },
    {
      value: 1401,
      label:
        '1401 - Compra para industrialização em operação com mercadoria sujeita ao regime de substituição tributária',
    },
    {
      value: 1653,
      label:
        '1653 - Compra de combustível ou lubrificante por consumidor ou usuário final',
    },
    {
      value: 5909,
      label: '5909 - Retorno de bem recebido por conta de contrato de comodato',
    },
    {
      value: 6108,
      label:
        '6108 - Venda de mercadoria adquirida ou recebida de terceiros, destinada a não contribuinte',
    },
    {
      value: 1411,
      label:
        '1411 - Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária',
    },
    {
      value: 1909,
      label: '1909 - Retorno de bem remetido por conta de contrato de comodato',
    },
  ];

  const cfop_filter_options = [
    { default: 5403, value: 1403, label: '1403 - Revenda' },
    { default: 5405, value: 1403, label: '1403 - Revenda' },
    {
      default: 6403,
      value: 2403,
      label:
        '2403 - Compra para comercialização em operação com mercadoria sujeita ao regime de substituição tributária',
    },
    { default: 5102, value: 1102, label: '1102 - Revenda' },
    {
      default: 6102,
      value: 2102,
      label: '2102 - Revenda originado de outro estado',
    },
    {
      default: 6108,
      value: 2102,
      label: '2102 - Revenda originado de outro estado',
    },
  ];

  const [cashFlowValue, setCashFlowValue] = useState([]);

  const openModal = useCallback(
    async (id, inputs) => {
      try {
        setLoading(true);
        const response = await api.get(`/restaurants/nfe-received/info/${id}`);
        setNfeInfo(response.data);
        setNfeReq(
          response.data.requisicao_nota_fiscal
            ? response.data.requisicao_nota_fiscal
            : null
        );

        const newProductsLeft = [];
        const newInputsNfe = [];
        const mediasInfo = [];
        const cashFlowValues = [];

        // Itens e Insumos
        const items = response.data.requisicao_nota_fiscal?.itens || [];
        items.forEach((item) => {
          let hasInput = inputs.filter(
            (inp) => inp.name_sefaz === item.descricao
          );

          if (hasInput.length === 0) {
            hasInput = searchStrings(inputs, item.descricao);
          }

          if (hasInput.length === 0) {
            newProductsLeft.push({
              id: item.numero_item,
              label: `Produto #${item.numero_item}: ${item.descricao}`,
              name: item.descricao,
              unidade: item.unidade_comercial,
              quantidade: item.quantidade_comercial,
              valor: item.valor_bruto,
              cfop: item.cfop,
              ncm: item.codigo_ncm,
            });

            mediasInfo.push({
              produto: item.numero_item,
              media: 0,
              hasMedia: false,
            });
          } else {
            newInputsNfe.push({
              produto: item.numero_item,
              input_id: hasInput[0].value,
              name: item.descricao,
              unidade: item.unidade_comercial,
              quantidade: item.quantidade_comercial,
              valor: item.valor_bruto,
              cfop: item.cfop,
              ncm: item.codigo_ncm,
              cash_flow_category_subcategory_id:
                hasInput[0].cash_flow_category_subcategory_id,
            });

            mediasInfo.push({
              produto: item.numero_item,
              media: 0,
              hasMedia: parseFloat(hasInput[0].quantidade) > 0,
            });
          }

          cashFlowValues.push({
            produto: item.numero_item,
            data: null,
          });
        });

        const items_info = [];
        const averageInfo = [];

        items.forEach((item) => {
          const input = newInputsNfe.filter(
            (inp) => inp.produto === item.numero_item
          )[0];
          const input_id = input?.input_id || null;
          const unidadeInput =
            inputs.filter((inp) => inp.value === input_id)[0]?.unidade || null;

          if (input_id && unidadeInput) {
            const new_tax =
              inputs
                .filter((inp) => inp.value === input_id)[0]
                ?.history_unit.filter(
                  (hist) =>
                    hist.old_unidade === item.unidade_comercial &&
                    hist.new_unidade === unidadeInput
                )[0]?.value || 1;

            items_info.push({
              produto: item.numero_item,
              name: item.descricao,
              unidade: item.unidade_comercial,
              quantidade: item.quantidade_comercial,
              tax: new_tax,
              new_unidade: unidadeInput,
              atual: parseFloat(item?.valor_unitario_comercial || 0).toFixed(2),
            });

            // document.getElementById(`taxa_conversao_${item.numero_item}`).value = new_tax;
          } else {
            items_info.push({
              produto: item.numero_item,
              name: item.descricao,
              unidade: item.unidade_comercial,
              quantidade: item.quantidade_comercial,
              tax: 1,
              new_unidade: item.unidade_comercial,
            });
          }

          averageInfo.push({
            produto: item.numero_item,
            quantidade: item.quantidade_comercial,
            unitary_price: item.valor_unitario_comercial,
            valor: item.valor_bruto,
            average: false,
          });
        });

        setInputsInfo(items_info);
        setAverage(averageInfo);
        setMedias(mediasInfo);

        const cfops = items.map((item) => ({
          produto: item.numero_item,
          cfop_item: item.cfop,
          cfop:
            cfop_filter_options?.find(
              (filt) => filt.default === parseInt(item?.cfop || 0)
            )?.value || 1556,
        }));
        setCfops(cfops);
        setProductsLeft(newProductsLeft);
        setInputsNfe(newInputsNfe);

        // Centro de Custo
        const newCashFlow = [];
        const nome_fantasia_emitente =
          response.data.requisicao_nota_fiscal?.nome_fantasia_emitente ||
          response.data.requisicao_nota_fiscal?.nome_emitente;
        const numero = response.data.requisicao_nota_fiscal?.numero;
        const serie = response.data.requisicao_nota_fiscal?.serie;
        const uf = response.data.requisicao_nota_fiscal?.uf_emitente;

        const descricao = `${nome_fantasia_emitente} Nota #${numero}`;

        let duplicatas = response.data.requisicao_nota_fiscal?.duplicatas || [];
        duplicatas = duplicatas.map((dup) => ({
          numero: dup.numero,
          produto: null,
          data_vencimento: dup.data_vencimento,
          valor: dup.valor,
          descricao,
          cash_flow_category: null,
        }));

        setDuplicatas(duplicatas);

        duplicatas.forEach((dup) => {
          const cashFlow = {
            numero: dup.numero,
            produto: null,
            data_vencimento: dup.data_vencimento,
            valor: dup.valor,
            descricao,
            cash_flow_category: null,
            is_duplicata_default: true,
          };
          newCashFlow.push(cashFlow);
        });

        const provider = {
          name: response.data.requisicao_nota_fiscal?.nome_emitente,
          phone:
            response.data.requisicao_nota_fiscal?.telefone_emitente ||
            '00000000',
          cnpj:
            response.data.requisicao_nota_fiscal?.cnpj_emitente || '0000000000',
          numero_nota: numero,
          data_emissao: response.data.data_emissao,
          valor_total: response.data.requisicao_nota_fiscal?.valor_total,
          serie,
          uf,
          codigo_municipio:
            response.data.requisicao_nota_fiscal?.codigo_municipio_emitente,
          municipio: response.data.requisicao_nota_fiscal?.municipio_emitente,
          numero: response.data.requisicao_nota_fiscal?.numero_emitente,
          logradouro: response.data.requisicao_nota_fiscal?.logradouro_emitente,
          inscricao_estadual:
            response.data.requisicao_nota_fiscal?.inscricao_estadual_emitente,
          bairro: response.data.requisicao_nota_fiscal?.bairro_emitente,
        };

        setProvider(provider);

        // colocar aqui os default cashFlow
        const cashFlowsTo = [...newCashFlow];

        newInputsNfe.forEach((newInput) => {
          if (duplicatas.length === 1) {
            let toSend = duplicatas.map((duplicata) => ({
              produto: newInput.produto,
              data_vencimento: formatLocalDate(duplicata.data_vencimento),
              valor: newInput.valor,
              descricao: duplicata.descricao,
              cash_flow_category: newInput.cash_flow_category_subcategory_id,
            }));
            toSend = toSend[0];
            cashFlowsTo.push(toSend);
          } else if (duplicatas.length === 0) {
            const toSend = {
              produto: newInput.produto,
              data_vencimento: formatLocalDate(provider.data_emissao),
              valor: newInput.valor,
              descricao: ``,
              cash_flow_category: newInput.cash_flow_category_subcategory_id,
            };

            cashFlowsTo.push(toSend);
          } else if (duplicatas.length > 1) {
            const total_duplicatas = duplicatas.reduce(
              (Acc, dup) => Acc + parseFloat(dup.valor),
              0
            );
            duplicatas.forEach((dup) => {
              const percent_each = parseFloat(dup.valor) / total_duplicatas;
              let valor_each = newInput.valor * percent_each;
              valor_each = valor_each.toFixed(2);

              const send = {
                produto: newInput.produto,
                data_vencimento: formatLocalDate(dup.data_vencimento),
                valor: valor_each,
                descricao: dup.descricao,
                cash_flow_category: newInput.cash_flow_category_subcategory_id,
              };

              cashFlowsTo.push(send);
            });
          }
        });

        setCashFlowToSend(cashFlowsTo);

        cashFlowsTo.forEach((cash) => {
          if (cash.cash_flow_category > 0) {
            cashFlowValues.find(
              (value) => value.produto === cash.produto
            ).data = cashFlows.filter(
              (flow) => flow.value === cash.cash_flow_category
            )[0];
          }
        });

        setCashFlowValue(cashFlowValues);
        toggle();
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error('Não foi possível aceitar a Nfe desejada, tente novamente');
      }
    },
    [cashFlows]
  );

  function changeAverage({ numero, hasAverage, average }) {
    average.find((av) => av.produto === numero).average = hasAverage === 'true';
  }

  function addCashFlow({ cashFlowToSend, duplicatas, produto, cash, valor }) {
    let toSend = [];

    const already = cashFlowToSend.filter((cash) => cash.produto === produto);
    const cashFlowSend = cashFlowToSend;
    duplicatas = duplicatas.filter((dup) => dup.numero);

    if (already.length > 0) {
      cashFlowToSend.forEach((flow) => {
        if (flow.produto === produto) {
          flow.cash_flow_category = cash.value;
        }
      });
      setCashFlowToSend(cashFlowToSend);
    } else {
      if (duplicatas.length === 1) {
        toSend = duplicatas.map((duplicata) => ({
          produto,
          data_vencimento: formatLocalDate(duplicata.data_vencimento),
          valor,
          descricao: duplicata.descricao,
          cash_flow_category: cash.value,
        }));
        toSend = toSend[0];
        cashFlowSend.push(toSend);
      } else if (duplicatas.length === 0) {
        toSend = {
          produto,
          data_vencimento: formatLocalDate(provider.data_emissao),
          valor,
          descricao: ``,
          cash_flow_category: cash.value,
        };

        cashFlowSend.push(toSend);
      } else if (duplicatas.length > 1) {
        const total_duplicatas = duplicatas.reduce(
          (Acc, dup) => Acc + parseFloat(dup.valor),
          0
        );
        duplicatas.forEach((dup) => {
          const percent_each = parseFloat(dup.valor) / total_duplicatas;
          let valor_each = valor * percent_each;
          valor_each = valor_each.toFixed(2);

          const send = {
            produto,
            data_vencimento: formatLocalDate(dup.data_vencimento),
            valor: valor_each,
            descricao: dup.descricao,
            cash_flow_category: cash.value,
          };

          cashFlowSend.push(send);
        });
      }
      setCashFlowToSend(cashFlowSend);
    }

    const thisValues = cashFlowValue;
    thisValues.find((cash) => cash.produto === produto).data = cash;
    setCashFlowValue([]);
    setCashFlowValue(thisValues);
  }

  const onClickAccept = useCallback(
    async (
      productsLeft,
      nfe,
      inputsToSend,
      inputsNfe,
      nfeId,
      cashFlowToSend,
      provider,
      cfops,
      duplicatas,
      inputsInfo,
      average,
      medias
    ) => {
      const left = cashFlowToSend.filter(
        (cf) => cf.cash_flow_category === null
      );

      if (productsLeft.length > 0 || left.length > 0) {
        toggleProductsLeft();
      } else {
        processAndSend(
          productsLeft,
          nfe,
          inputsToSend,
          inputsNfe,
          nfeId,
          cashFlowToSend,
          provider,
          cfops,
          duplicatas,
          inputsInfo,
          average,
          medias
        );
      }
    },
    []
  );

  const processAndSend = useCallback(
    async (
      productsLeft,
      nfe,
      inputsToSend,
      inputsNfe,
      nfeId,
      cashFlowToSend,
      provider,
      cfops,
      duplicatas,
      inputsInfo,
      average,
      medias
    ) => {
      setLoadingNfe(true);
      try {
        const filtered = cashFlowToSend
          .filter((flow) => flow.produto !== null)
          .map((flow) => flow.produto); // cashFlowToSend.filter(flow => flow.produto !== null);
        const unique = [...new Set(filtered)];

        // medias.filter(med => parseFloat(med.media) < 0).map(med => {
        //   average.find(av => av.produto === med.produto).average = false;
        // });

        // console.log('mediass', medias, 'aver', average);

        const inputData = {
          toCreate: productsLeft,
          inputsToAdd: inputsToSend,
          inputsAlreadyExist: inputsNfe,
          cashFlow: cashFlowToSend,
          provider,
          cfops,
          inputsInfo,
          average,
        };

        if (productsLeft.length > 0 && user.allow_inputs === false) {
          toast.error(
            'Relacione todos os produtos a um insumo. Caso não apareça o insumo, contate o franqueado.'
          );
          setLoadingNfe(false);
          return false;
        }

        if (unique.length < duplicatas) {
          toast.error(
            'Todos os Produtos devem estar relacionados a um centro de custo'
          );
          setLoadingNfe(false);
        } else {
          await api.put(`/restaurants/nfe-received/operation/${nfeId}`, {
            tipo: 'confirmacao',
            inputData,
          });

          setModalProductsLeft(false);
          handleUpdate();
          setModal(false);
          toast.success('Nota aceita e insumos atualizados!');
          setLoadingNfe(false);
        }
      } catch (error) {
        toast.error('Não foi possível aceitar a Nfe desejada, tente novamente');
        setLoadingNfe(false);
      }
    },
    [nfeId]
  );

  function handleName(id, name) {
    inputsInfo.forEach((inp) => {
      if (inp.produto === id) {
        inp.name = name;
      }
    });
  }

  function handleUnity(id, un) {
    inputsInfo.forEach((inp) => {
      if (inp.produto === id) {
        inp.new_unidade = un;
      }
    });
  }

  function changeTax(tax, id, valor_bruto) {
    inputsInfo.forEach((inp) => {
      if (inp.produto === id) {
        const thisTax = tax === '' ? 1 : tax.replace(',', '.');
        inp.tax = thisTax;
        const x =
          parseFloat(valor_bruto || 0) /
          (parseFloat(thisTax) * parseFloat(inp.quantidade || 1));
        x.toFixed(2);
        inp.atual = x;
      }
    });
  }

  function handleQuantidade(id, qnt) {
    inputsInfo.forEach((inp) => {
      if (inp.produto === id) {
        inp.quantidade = qnt.replace(',', '.');
      }
    });
    document.getElementById(`valor_conversao_unidade_${id}`).value =
      parseFloat(inputsInfo.filter((inp) => inp.produto === id)[0]?.tax || 1) *
      parseFloat(qnt || 1);
  }

  function handleDateDuplicata(id, date) {
    cashFlowToSend.forEach((cash) => {
      if (cash?.numero === id) {
        cash.data_vencimento = formatLocalDate(date);
      }
    });
  }

  function handleCreateDup(data) {
    const many = cashFlowToSend.filter(
      (cash) => cash.numero && cash.numero !== null
    );
    const cashFlow = cashFlowToSend;
    const nome_fantasia_emitente =
      nfeReq?.nome_fantasia_emitente || nfeReq?.nome_emitente || '';
    const numero_nota = nfeReq?.numero || '';
    const descricao = `${nome_fantasia_emitente} Nota #${numero_nota}`;

    function addZeroes(num, len) {
      let numberWithZeroes = String(num);
      let counter = numberWithZeroes.length;

      while (counter < len) {
        numberWithZeroes = `0${numberWithZeroes}`;

        counter++;
      }

      return numberWithZeroes;
    }

    const numero = addZeroes(many.length + 1, 3);
    cashFlow.push({
      numero: numero.toString(),
      produto: null,
      data_vencimento: data.data_vencimento,
      valor: parseFloat(data.valor.replace(',', '.') || 0).toFixed(2),
      descricao,
      cash_flow_category: null,
      is_duplicata_default: false,
    });

    setCashFlowToSend(cashFlow);

    const nfeReqLocal = nfeReq;
    nfeReqLocal.duplicatas = nfeReqLocal?.duplicatas || [];
    nfeReqLocal.duplicatas.push({
      numero: numero.toString(),
      produto: null,
      data_vencimento: `${data.data_vencimento}T12:00:00`,
      valor: parseFloat(data.valor?.replace(',', '.') || 0).toFixed(2),
      descricao,
      cash_flow_category: null,
      is_new_duplicata: true,
    });

    setNfeReq(nfeReqLocal);
    toggleCreateDup();
  }

  function handleDeleteDup(id) {
    if (window.confirm('Tem certeza que deseja remover essa duplicata?')) {
      const cashFlow = cashFlowToSend.filter(
        (cash) => cash?.numero !== id || cash?.produto
      );
      setCashFlowToSend(cashFlow);

      const nfeReqLocal = nfeReq;
      nfeReqLocal.duplicatas = nfeReqLocal?.duplicatas || [];
      nfeReqLocal.duplicatas = nfeReqLocal.duplicatas.filter(
        (dup) => dup.numero !== id
      );
      setNfeReq(nfeReqLocal);
    }
  }

  // useEffect(() => {
  //   const x = parseFloat(item?.valor_bruto || 0) / (
  //     parseFloat(
  //       inputsInfo.filter(
  //         (inp) => inp.produto === item.numero_item
  //       )[0]?.tax || 1
  //     ) *
  //     parseFloat(
  //       inputsInfo.filter(
  //         (inp) => inp.produto === item.numero_item
  //       )[0]?.quantidade || 1
  //     )
  //   );

  //   setAtualLabel(x);
  // }, [inputsInfo]);

  function searchStrings(array, search) {
    const splited = search.split(' ');
    let lastFiltered = null;
    let filtered = null;

    if (splited.length > 0) {
      filtered = array.filter((arr) =>
        arr.name.toLowerCase().includes(splited[0].toLowerCase())
      );
    }

    if (splited.length > 1) {
      filtered = array.filter((arr) =>
        arr.name.toLowerCase().includes(splited[0].toLowerCase())
      );
      lastFiltered = filtered;

      if (filtered.length === 0) {
        filtered = lastFiltered;
      }

      if (filtered.length > 1) {
        filtered = filtered.filter((arr) =>
          arr.name.toLowerCase().includes(splited[1].toLowerCase())
        );
        if (filtered.length === 0) {
          filtered = lastFiltered;
        }
      }
    }

    if (splited.length > 2) {
      filtered = array.filter((arr) =>
        arr?.name?.toLowerCase().includes(splited[0].toLowerCase())
      );
      lastFiltered = filtered;

      if (filtered.length > 1) {
        filtered = filtered.filter((arr) =>
          arr?.name?.toLowerCase().includes(splited[1].toLowerCase())
        );

        if (filtered.length === 0) {
          filtered = lastFiltered;
        }

        if (filtered.length > 1) {
          filtered = filtered.filter((arr) =>
            arr?.name?.toLowerCase().includes(splited[2].toLowerCase())
          );

          if (filtered.length === 0) {
            filtered = lastFiltered;
          }
        } else if (filtered.length === 0) {
          filtered = lastFiltered;
        }
      } else if (filtered.length === 0) {
        filtered = lastFiltered;
      }
    }

    if (filtered?.length > 0) {
      filtered = [filtered[0]];
    }

    return filtered;
  }

  useState(() => {
    setNfeReq(nfeReq);
  }, [cashFlowValue]);

  return (
    <>
      <tr>
        <td>{nfe?.nome_emitente}</td>
        <td>{formatCompleteDate(nfe?.data_emissao)}</td>
        <td>{nfe?.chave_nfe}</td>
        <td>{nfe?.numero}</td>
        <td>
          R$
          {parseFloat(nfe?.valor_total || 0).toLocaleString('pt-br', {
            minimumFractionDigits: 2,
          })}
        </td>
        <td>
          {' '}
          <Actions>
            <ActionsInside>
              <DeleteAndEditActions>
                {/* {
                  !loading ?
                  <FaEye 
                    color="#01AFFF" 
                    onClick={() => openModal(nfe.id, inputs)} 
                    size={20} 
                    style={{cursor: 'pointer'}}
                  /> :
                  <Spinner size='sm' style={{ color:'#01AFFF'}}/>
                } */}

                {/* <StyledLink
                  to={{
                    pathname: '/financial/fiscal/manifest/info',
                    state: {
                      nfe_id: nfe.id
                    },
                  }}
                > */}
                <FaEye
                  color="#01AFFF"
                  onClick={() => {
                    localStorage.setItem('nfe_manifest_id', nfe.id);
                    history.push(`/operation/fiscal/manifest/info`);
                  }}
                  size={20}
                  style={{ cursor: 'pointer' }}
                />
                {/* </StyledLink> */}
              </DeleteAndEditActions>
            </ActionsInside>
          </Actions>
        </td>
      </tr>

      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalBody>
          <Form initialData={nfeReq}>
            {!nfeReq && (
              <Row>
                <Col>
                  <h5 style={{ color: 'red' }}>
                    <strong>
                      Informações da nota ainda não processada pela Sefaz. Tente
                      novamente mais tarde.
                    </strong>
                  </h5>
                </Col>
              </Row>
            )}
            <Row>
              <Col
                style={{ borderBottom: '1px solid #EBEBEB', marginBottom: 5 }}
              >
                <h5>Dados do Fornecedor:</h5>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Input
                  name="nome_fantasia_emitente"
                  label="Fornecedor"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="6">
                <Input
                  name="nome_emitente"
                  label="Razão Social"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Input name="cnpj_emitente" label="CNPJ" type="text" disabled />
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  marginBottom: 5,
                  paddingTop: 10,
                }}
              >
                <h5>Dados da Nota:</h5>
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <Input
                  name="valor_total"
                  label="Valor Total (R$)"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="2">
                <Input
                  name="data_emissao"
                  label="Data Emissão"
                  value={formatLocalDate(
                    nfeReq?.data_emissao || null,
                    'dd/MM/yyyy'
                  )}
                  type="text"
                  disabled
                />
              </Col>
              <Col md="8">
                <Input
                  name="chave_nfe"
                  label="Chave NFe"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <Input name="serie" label="Série" type="text" disabled />
              </Col>
              <Col md="2">
                <Input name="numero" label="Número" type="text" disabled />
              </Col>
              <Col md="2">
                <Input name="modelo" label="Modelo" type="text" disabled />
              </Col>
              <Col md="3">
                <Input
                  name="codigo_unico"
                  label="Código Único"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input name="versao" label="Versão" type="text" disabled />
              </Col>
            </Row>
          </Form>
          <Row>
            <Col
              style={{
                borderBottom: '1px solid #EBEBEB',
                marginBottom: 5,
                paddingTop: 10,
              }}
            >
              <h5>Duplicatas:</h5>
            </Col>
          </Row>
          {nfeReq && nfeReq?.duplicatas && nfeReq?.duplicatas.length > 0 ? (
            nfeReq?.duplicatas.map((dup) => (
              <Form initialData={dup}>
                <Row>
                  <Col md="3">
                    <Input name="numero" label="Número" type="text" disabled />
                  </Col>
                  <Col md="3">
                    <Input
                      name="data_vencimento"
                      label="Data Vencimento"
                      type="date"
                      onChange={(e) => {
                        handleDateDuplicata(dup.numero, e.target.value);
                      }}
                    />
                  </Col>
                  <Col md="3">
                    <Input
                      name="valor"
                      label="Valor (R$)"
                      type="text"
                      disabled
                    />
                  </Col>
                  {dup?.is_new_duplicata && (
                    <Col md="2">
                      <div style={{ marginTop: 50 }}>
                        <Button
                          onClick={() => handleDeleteDup(dup.numero)}
                          style={{
                            margin: 'auto',
                            background: 'transparent',
                            color: 'red',
                          }}
                          size="sm"
                        >
                          <MdDelete size={22} />
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>
              </Form>
            ))
          ) : (
            <Row>
              <Col>
                <p style={{ color: 'red' }}>Nota sem duplicatas</p>
              </Col>
            </Row>
          )}
          <Row style={{ textAlign: 'right' }}>
            <Col>
              <Button
                onClick={() => toggleCreateDup()}
                style={{ margin: 'auto' }}
                size="sm"
                color="info"
              >
                Criar duplicata
              </Button>
            </Col>
          </Row>
          <Form initialData={nfeReq}>
            <Row>
              <Col
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  marginBottom: 5,
                  paddingTop: 10,
                }}
              >
                <h5>Totais da Nota :</h5>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Input
                  name="valor_produtos"
                  label="Valor dos Produtos"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="icms_base_calculo"
                  label="Base de Cálculo do ICMS"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="icms_valor_total"
                  label="Valor do ICMS"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="icms_base_calculo_st"
                  label="Base de Cálc. do ICMS ST"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Input
                  name="icms_valor_total_st"
                  label="Valor Total do ICMS ST"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_pis"
                  label="Valor do PIS"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_cofins"
                  label="Valor do COFINS"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_ipi"
                  label="Valor do IPI"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Input
                  name="valor_frete"
                  label="Valor Total do Frete"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_outras_despesas"
                  label="Valor de Outras Despesas"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_desconto"
                  label="Valor Total de Desconto"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_total"
                  label="Valor Total da NFe"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
          </Form>
          <Row>
            <Col
              style={{
                borderBottom: '1px solid #EBEBEB',
                marginBottom: 5,
                paddingTop: 10,
              }}
            >
              <h5>Produtos:</h5>
            </Col>
          </Row>
          {nfeReq && nfeReq?.itens && nfeReq?.itens.length > 0 ? (
            nfeReq?.itens.map((item) => (
              <Form
                initialData={item}
                style={{ paddingTop: 15, borderBottom: '1px solid #EBEBEB' }}
              >
                <Row>
                  <Col>
                    <h5>Produto #{item.numero_item}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <Input
                      name="codigo_produto"
                      label="Cód. Forn."
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      name="descricao"
                      label="Produto"
                      type="text"
                      onChange={(e) =>
                        handleName(item.numero_item, e.target.value)
                      }
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="valor_unitario_comercial"
                      label="Valor de Custo"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="valor_bruto"
                      label="Valor Total"
                      type="text"
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  {/* <Col md="2">
                    <Input
                      name="valor_de_venda"
                      label="Valor de Venda"
                      type="text"
                    />
                  </Col> */}
                  <Col md="2">
                    <Input name="codigo_ncm" label="NCM" type="text" disabled />
                  </Col>

                  <Col md="4">
                    <Select
                      label="CFOP"
                      name="cfop"
                      options={cfop_options}
                      defaultValue={
                        cfop_filter_options?.find(
                          (filt) => filt.default === parseInt(item?.cfop || 0)
                        ) ||
                        cfop_options[0] ||
                        null
                      }
                      onChange={(e) =>
                        addCfop({
                          cfop: e.value,
                          produto: item.numero_item,
                        })
                      }
                    />
                  </Col>
                  <Col md="6">
                    {cashFlowValue[parseInt(item.numero_item) - 1]?.data ? (
                      <Select
                        label="Centro de Custo"
                        name="centro"
                        id={`select_select_${item.numero_item}`}
                        ref={selectRef}
                        options={cashFlows}
                        // defaultValue={
                        //   inputs.filter(
                        //     (inp) => inp.name_sefaz === item.descricao
                        //   )[0] ? cashFlows.filter(cash => cash.value === (inputs.filter(
                        //     (inp) => inp.name_sefaz === item.descricao
                        //   )[0].cash_flow_category_subcategory_id)) : null
                        // }
                        value={
                          cashFlowValue[parseInt(item.numero_item) - 1]?.data
                        }
                        isDisabled
                        onChange={(e) =>
                          addCashFlow({
                            cashFlowToSend,
                            duplicatas: cashFlowToSend,
                            produto: item.numero_item,
                            cash: e,
                            valor: item.valor_bruto,
                          })
                        }
                      />
                    ) : (
                      <Select
                        label="Centro de Custo"
                        name="centro"
                        id={`select_select_${item.numero_item}_1`}
                        options={cashFlows}
                        defaultValue={null}
                        onChange={(e) =>
                          addCashFlow({
                            cashFlowToSend,
                            duplicatas: cashFlowToSend,
                            produto: item.numero_item,
                            cash: e,
                            valor: item.valor_bruto,
                          })
                        }
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Select
                      label="Relacionar Insumo"
                      name="inputs"
                      id={`input_select_${item.numero_item}`}
                      options={inputs}
                      defaultValue={
                        inputs.filter(
                          (inp) => inp.name_sefaz === item.descricao
                        )[0] ||
                        // || inputs.filter(
                        //   (inp) => inp.name.toLowerCase().includes(item.descricao.toLowerCase().trim().split(' ')[0])
                        // )[0]

                        searchStrings(inputs, item.descricao) ||
                        null
                      }
                      onChange={(e) => {
                        addInput({
                          input: e.value,
                          qnt: item.quantidade_comercial,
                          valor: item.valor_bruto,
                          produto: item.numero_item,
                          ncm: item.codigo_ncm,
                          unidade: item.unidade_comercial,
                          unitario: item.valor_unitario_comercial,
                          cashFlowToSend,
                          item,
                        });
                      }}
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="quantidade_comercial"
                      label="Qnt. Nota"
                      type="text"
                      onChange={(e) =>
                        handleQuantidade(item.numero_item, e.target.value)
                      }
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="unidade_comercial"
                      label="Unidade"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="1">
                    <Input
                      name="taxa_conversao_unidade"
                      label="Taxa"
                      type="text"
                      id={`taxa_conversao_${item.numero_item}`}
                      defaultValue={
                        inputsInfo.filter(
                          (inp) => inp.produto === item.numero_item
                        )[0]?.tax || 1
                      }
                      onChange={(e) => {
                        changeTax(
                          e.target.value,
                          item.numero_item,
                          item.valor_bruto
                        );
                        document.getElementById(
                          `valor_conversao_unidade_${item.numero_item}`
                        ).value =
                          parseFloat(
                            inputsInfo.filter(
                              (inp) => inp.produto === item.numero_item
                            )[0]?.tax || 1
                          ) *
                          parseFloat(
                            inputsInfo.filter(
                              (inp) => inp.produto === item.numero_item
                            )[0]?.quantidade || 1
                          );
                      }}
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="valor_conversao_unidade"
                      label="Qnt. Insumo"
                      type="text"
                      id={`valor_conversao_unidade_${item.numero_item}`}
                      disabled
                      value={
                        parseFloat(
                          inputsInfo.filter(
                            (inp) => inp.produto === item.numero_item
                          )[0]?.tax || 1
                        ) *
                        parseFloat(
                          inputsInfo.filter(
                            (inp) => inp.produto === item.numero_item
                          )[0]?.quantidade || 1
                        )
                      }
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="unidade_insumo"
                      label="Un. Insumo"
                      id={`unidade_insumo_${item.numero_item}`}
                      type="text"
                      disabled={
                        !!inputs.filter(
                          (inp) => inp.name_sefaz === item.descricao
                        )[0]
                      }
                      onChange={(e) =>
                        handleUnity(item.numero_item, e.target.value)
                      }
                      defaultValue={
                        inputsInfo.filter(
                          (inp) => inp.produto === item.numero_item
                        )[0]?.new_unidade || ''
                      }
                    />
                  </Col>
                  {/* <Col md="1">
                    <div style={{ marginTop: 50}}>
                      <Button
                        class="btn btn-default"
                        size="sm"
                        style={{background: 'orange', margin: 'auto'}}
                        onClick={() => toggleUnit(item.numero_item)}
                        disabled={!(inputsToSend.filter(inp => inp.produto === item.numero_item).length > 0 || inputsNfe.filter(inp => inp.produto === item.numero_item).length)}
                      >
                        <RiArrowLeftRightLine size={12} color="white"/>
                      </Button>
                    </div>
                  </Col> */}
                </Row>
                <Row>
                  <Col md="10">
                    {!(
                      inputsToSend.filter(
                        (inp) => inp.produto === item.numero_item
                      ).length > 0 ||
                      inputsNfe.filter(
                        (inp) => inp.produto === item.numero_item
                      ).length
                    ) && (
                      <p>
                        Caso não seja selecionado nenhum insumo, será criado um
                        insumo com a conversão acima
                      </p>
                    )}
                  </Col>
                  {/* <Col md="2" style={{textAlign: 'right'}}>
                    
                  </Col> */}
                </Row>
                <Row
                  style={{
                    marginBottom: 5,
                    paddingTop: 2,
                    alignItems: 'center',
                  }}
                >
                  <Col
                    style={{ width: 'fit-content', flex: '0 0 fit-content' }}
                  >
                    <h5 style={{ margin: 0, width: 'fit-content' }}>
                      Valor custo unitário:
                    </h5>
                  </Col>
                  <Col md="3">
                    <RadioInput
                      value={false}
                      name="average"
                      defaultChecked
                      label="Novo"
                      onChange={(e) =>
                        changeAverage({
                          numero: item.numero_item,
                          hasAverage: e.target.value,
                          average,
                        })
                      }
                    />
                  </Col>
                  {medias.find((med) => med.produto === item.numero_item)
                    ?.hasMedia === true && (
                    <Col md="3">
                      <RadioInput
                        value
                        name="average"
                        id={`media_${item.numero_item}`}
                        label="Média"
                        onChange={(e) =>
                          changeAverage({
                            numero: item.numero_item,
                            hasAverage: e.target.value,
                            average,
                          })
                        }
                      />
                    </Col>
                  )}
                </Row>
              </Form>
            ))
          ) : (
            <p style={{ color: 'red' }}>Nota sem duplicatas.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => toggleRefuse()}>
            Recusar
          </Button>
          <Button
            onClick={() =>
              onClickAccept(
                productsLeft,
                nfe,
                inputsToSend,
                inputsNfe,
                nfeId,
                cashFlowToSend,
                provider,
                cfops,
                nfeReq?.itens.length,
                inputsInfo,
                average,
                medias
              )
            }
            color="success"
            disabled={loadingNfe}
          >
            Aceitar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalProductsLeft} toggle={toggleProductsLeft} size="md">
        <ModalBody>
          {productsLeft.length > 0 && (
            <Row style={{ paddingBottom: 15 }}>
              <Col>
                <h5>
                  {user.allow_inputs === true
                    ? 'Atenção! Os seguintes itens não foram vinculados a nenhum insumo e serão criados:'
                    : 'Atenção! Os seguintes itens não foram vinculados a nenhum insumo, e devem ser vinculados. Caso não encontre o insumo, contate o franqueador:'}
                </h5>
              </Col>
            </Row>
          )}
          {productsLeft.map((product) => (
            <Row>
              <Col>
                <p>
                  — {product.label} / <strong>Unidade: </strong>{' '}
                  {product.unidade} /<strong> Quantidade: </strong>{' '}
                  {product.quantidade} / <strong>Valor: </strong> R$
                  {product.valor}
                </p>
              </Col>
            </Row>
          ))}
          {productsLeft.length > 0 && user.allow_inputs === true && (
            <Row style={{ paddingBottom: 15 }}>
              <Col>
                <p>
                  <strong>
                    Ao aceitar a nota serão criados {productsLeft.length}{' '}
                    {productsLeft.length === 0 || productsLeft.length > 1
                      ? 'insumos'
                      : 'insumo'}{' '}
                  </strong>
                </p>
              </Col>
            </Row>
          )}
          {cashFlowLeft.length > 0 && (
            <Row>
              <Col>
                <h5>
                  As seguintes duplicatas não foram vinculados a nenhum centro
                  de custo:
                </h5>
              </Col>
            </Row>
          )}
          {cashFlowLeft.map((cash) => (
            <Row>
              <Col>
                <p>
                  — {cash.numero}: {cash.descricao} / <strong>Valor: </strong>{' '}
                  R${cash.valor} / <strong>Vencimento: </strong>{' '}
                  {formatLocalDate(cash?.data_vencimento || null)}
                </p>
              </Col>
            </Row>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            disabled={loadingNfe}
            onClick={() =>
              processAndSend(
                productsLeft,
                nfe,
                inputsToSend,
                inputsNfe,
                nfeId,
                cashFlowToSend,
                provider,
                cfops,
                nfeReq?.itens.length,
                inputsInfo,
                average,
                medias
              )
            }
          >
            Sim, aceitar nota!
          </Button>
          <Button color="grey" onClick={() => toggleProductsLeft()}>
            Voltar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalRefuse} toggle={toggleRefuse} size="md">
        <ModalBody>
          <h5>Tem certeza que deseja recusar essa nota fiscal?</h5>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => handleCancelNfe()}>
            Sim, recusar nota!
          </Button>
          <Button color="success" onClick={() => toggleRefuse()}>
            Não, voltar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalCreateDup} toggle={toggleCreateDup} size="md">
        <Form onSubmit={handleCreateDup}>
          <ModalBody>
            <h5>Criar duplicata</h5>
            <Row>
              <Col md="6">
                <Input
                  name="data_vencimento"
                  label="Data de Vencimento"
                  type="date"
                  defaultValue={formatLocalDate(new Date())}
                />
              </Col>
              <Col md="6">
                <Input name="valor" label="Valor" type="text" />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => toggleCreateDup()}>
              Fechar
            </Button>
            <Button color="success" type="submit">
              Adicionar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalUnit} toggle={() => toggleUnit(null)}>
        <ModalHeader>
          Converter Unidade de <strong>{inputToChange?.name}</strong>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Form>
              <Row>
                <Col md="4" style={{ margin: 'auto' }}>
                  1 {inputToChange?.unidade} equivale a:
                </Col>
                <Col md="4">
                  <Input
                    type="number"
                    step="0.1"
                    label="Valor"
                    name="value_change"
                    id="value_change"
                    value={valueNewUnit}
                    placeholder="Valor de conversão"
                    onChange={(e) => setValueNewUnit(e.target.value)}
                  />
                </Col>
                <Col md="4">
                  <Input
                    type="text"
                    name="unit_change"
                    placeholder="ex: KG"
                    label="Unidade"
                    value={newUnit}
                    onChange={(e) => setNewUnit(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <strong>Atualizado: </strong> {inputToChange?.quantidade}{' '}
                  {inputToChange?.unidade} {'=>'}{' '}
                  {(parseFloat(inputToChange?.quantidade) * valueNewUnit)
                    .toFixed(3)
                    .replace('.', ',')}{' '}
                  {newUnit || 'nova unidade'}
                </Col>
              </Row>
            </Form>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => handleChangeUnit()}>
            Converter
          </Button>
          <Button color="danger" onClick={() => setModalUnit(false)}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
