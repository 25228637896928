import React, { useState, useCallback, useRef, useEffect } from 'react';

import { Form } from '@unform/web';
import { Modal, ModalBody, ModalFooter, Collapse } from 'reactstrap';

import { Wrapper, Header, Card, Content, ButtonCollapse } from './styles';

import api from '~/services/api';

import {
  Button,
  Spinner,
  DefaultInput,
  DayCalendar,
  InformationDiv,
  OrderStatusTag,
  DefaultSelect,
} from 'ui-kit-takeat';
import { MdDelete } from 'react-icons/md';

import InputCurrency from '~/components/Form/InputCurrency';
import toast from 'react-hot-toast';
import BackButton from '~/components/Buttons/BackButton';
import formatCompleteDate from '~/services/formatCompleteDate';
import { addHours, addMonths, format } from 'date-fns';
import NfeManifestItem from '~/components/NfesEntry/NfeManifestItem';
import { useAuth } from '~/context/AuthContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const xml2js = require('xml2js');
const NFe = require('djf-nfe');

export default function NfeManifestInfo() {
  const { user } = useAuth();
  const history = useHistory();

  const nfe_id = localStorage.getItem('nfe_manifest_id');

  //saber se a nota ta vindo do XML ou da entrada da focus
  const params = new URLSearchParams(document.location.search);
  const is_xml = params?.get('xml') === 'true';

  const [inputs, setInputs] = useState([]);
  const [cashFlows, setCashFlows] = useState([]);
  const [itens, setItens] = useState([]);

  //pra evitar enviar duas vezes
  const [accepted, setAccepted] = useState(false);

  const getInputs = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/inputs?units=true');

      const resp = response.data.map((inp) => ({
        value: inp.id,
        label: `${inp.name} (${inp.unidade})`,
        name: inp.name,
        name_sefaz: inp.name_sefaz,
        unidade: inp.unidade,
        quantidade: inp.quantidade,
        history_unit: inp.history_unit,
        unitary_price: inp.unitary_price,
        cfop: inp.cfop,
        cash_flow_category_subcategory_id:
          inp.cash_flow_category_subcategory_id,
      }));

      setInputs([{ value: null, label: 'Criar insumo', name: '' }, ...resp]);
    } catch (err) {
      toast.error('Falha ao carregar dados');
    }
  }, []);

  DefaultInput.defaultProps = {
    ...DefaultInput.defaultProps,
    inputStyle: {
      fontSize: '14px',
    },
    titleStyles: {
      fontSize: '14px',
    },
    style: { height: 38, padding: '4px 20px' },
  };

  const [loadingNfe, setLoadingNfe] = useState(false);

  const formRefDev = useRef(null);

  const [isOpenDadosNota, setIsOpenDadosNota] = useState(false);
  const [isOpenDadosEmitente, setIsOpenDadosEmitente] = useState(true);
  const [isOpenDuplicatas, setIsOpenDuplicatas] = useState(true);

  function currencyFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const [nfeReq, setNfeReq] = useState(null);
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState({});
  const [duplicatas, setDuplicatas] = useState([]);

  const [modalCreateDup, setModalCreateDup] = useState(false);
  function toggleCreateDup() {
    if (!modalCreateDup === true) {
      setDuplicatas([
        ...duplicatas,
        {
          numero:
            duplicatas.length > 0
              ? parseInt(duplicatas[duplicatas.length - 1].numero || 0) + 1
              : 1,
          valor: 0,
          data_vencimento: format(new Date(), 'yyyy-MM-dd'),
          created: false,
          portion: 1
        },
      ]);
    } else {
      setDuplicatas(state => state.filter((dup) => dup.created !== false));
    }

    setModalCreateDup(!modalCreateDup);
  }

  const [modalDivideDup, setModalDivideDup] = useState(false);
  function toggleDivideDup() {
    if(modalDivideDup){
      setModalDivideDup(null);
    }
  }

  function createDivideDuplicata(duplicata) {
    const { numero, valor, data_vencimento } = duplicata;
    let { portion } = duplicata;

    if(!portion){
      //caso nao tenha portion é pq nao mudou o numero padrao do select que é 2
      //nao entrou no onchange entao nao existe essa informacao na duplicata
      portion = 2;
    }

    const thisDup = [...duplicatas.filter(dup => dup.numero !== numero)];
    let left = parseFloat(valor);
    const valuePerDup = parseFloat((left / portion).toFixed(2)); 
    const overdueDate = addHours(new Date(data_vencimento), 4);

    for(let i = 0; i < portion; i++){
      let thisValue = valuePerDup;

      if(i === portion - 1){
        thisValue = parseFloat((left).toFixed(2));
      }

      thisDup.push({
        numero: thisDup.length > 0
          ? parseInt(thisDup[thisDup.length - 1].numero || 0) + 1
          : 1,
        valor: thisValue,
        data_vencimento: format(addMonths(overdueDate, i), 'yyyy-MM-dd'),
        created: true
      });

      left = left - thisValue;
    }

    setDuplicatas(thisDup);

    toggleDivideDup();
  }

  const [modalResume, setModalResume] = useState(false);
  function toggleResume() {
    if (user.regime_tributario !== '1') {
      const hasCstNull = itens.filter((item) => item.cst === null);
      if (hasCstNull.length > 0) {
        let text = '';
        hasCstNull.forEach((cst) => {
          text === ''
            ? (text += `${cst.numero_item}`)
            : (text += `, ${cst.numero_item}`);
        });
        toast.error(`Obrigatório converter CSOSN para CST nos items: ${text}`);
        return;
      }
    }

    if (user.allow_inputs) {
      setModalResume(!modalResume);
    } else {
      const hasInputNull = itens.find((item) => item.input_id === null);
      if (hasInputNull) {
        toast.error(
          'Relacione todos os produtos a um insumo. Caso não apareça o insumo, contate o franqueador.'
        );
      } else {
        setModalResume(!modalResume);
      }
    }
  }

  const [modalRefuse, setModalRefuse] = useState(false);
  function toggleRefuse() {
    setModalRefuse(!modalRefuse);
  }

  const cfop_options = [
    { value: 1101, label: '1101 - Industrialização' },
    { value: 1556, label: '1556 - Uso e consumo' },
    { value: 1403, label: '1403 - Revenda' },
    { value: 1102, label: '1102 - Revenda' },
    {
      value: 1201,
      label: '1201 - Devolução de venda de produção do estabelecimento',
    },
    {
      value: 1202,
      label:
        '1202 - Devolução de venda de mercadoria adquirida ou recebida de terceiros',
    },
    { value: 1908, label: '1908 - Bem por conta de contrato de comodato' },
    { value: 1910, label: '1910 - Entrada de bonificação, doação ou brinde' },
    {
      value: 1949,
      label:
        '1949 - Outra entrada de mercadoria ou prestação de serviço não especificada',
    },
    {
      value: 1551,
      label: '1551 - Compra de ativo imobilizado dentro do estado',
    },
    { value: 2551, label: '2551 - Compra de ativo imobilizado fora do estado' },
    {
      value: 1407,
      label:
        '1407 - Compra de mercadoria para uso ou consumo cuja mercadoria está sujeita ao regime de substituição tributária',
    },
    { value: 2101, label: '2101 - Industrilização originado de outro estado' },
    { value: 2102, label: '2102 - Revenda originado de outro estado' },
    {
      value: 2403,
      label:
        '2403 - Compra para comercialização em operação com mercadoria sujeita ao regime de substituição tributária',
    },
    { value: 5202, label: '5202 - Devolução de compra para comercialização' },
    {
      value: 5929,
      label:
        '5929 - Lançamento efetuado em decorrência de emissão de documento fiscal relativo a operação ou prestação também registrada em equipamento Emissor de Cupom Fiscal - ECF',
    },
    {
      value: 1651,
      label:
        '1651 - Compra de combustível ou lubrificante para industrialização subsequente',
    },
    {
      value: 1407,
      label:
        '1407 - Compra de mercadoria para uso ou consumo cuja mercadoria está sujeita ao regime de substituição tributária',
    },
    {
      value: 1556,
      label: '1556 - Compra de material para uso ou consumo',
    },
    {
      value: 1401,
      label:
        '1401 - Compra para industrialização em operação com mercadoria sujeita ao regime de substituição tributária',
    },
    {
      value: 1653,
      label:
        '1653 - Compra de combustível ou lubrificante por consumidor ou usuário final',
    },
    {
      value: 5909,
      label: '5909 - Retorno de bem recebido por conta de contrato de comodato',
    },
    {
      value: 6108,
      label:
        '6108 - Venda de mercadoria adquirida ou recebida de terceiros, destinada a não contribuinte',
    },
    {
      value: 1411,
      label:
        '1411 - Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária',
    },
    {
      value: 1909,
      label: '1909 - Retorno de bem remetido por conta de contrato de comodato',
    },
    {
      value: 2556,
      label:
        '2556 - Classificam-se neste código as compras de produtos destinadas ao uso ou consumo do estabelecimento',
    },
    {
      value: 1653,
      label:
        '1653 - Compra de combustível ou lubrificante por consumidor ou usuário final',
    },
    {
      value: 2910,
      label: '2910 - Entrada de bonificação, doação ou brinde',
    },
    {
      value: 2406,
      label:
        '2406 - Compra de bem para o ativo imobilizado cuja mercadoria está sujeita ao regime de substituição tributária',
    },
    {
      value: 1902,
      label:
        '1902 - Retorno de mercadoria remetida para industrialização por encomenda',
    },
    {
      value: 1124,
      label: '1124 - Industrialização efetuada por outra empresa',
    },
    {
      value: 1152,
      label: '1152 - Transferência para comercialização',
    },
  ];

  const getInfo = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const responseCash = await api.get(
          '/restaurants/cash-flows/categories-detail'
        );

        const thisCashFlows = responseCash?.data?.map((category) => {
          return {
            value: category.id,
            label: category.text,
          };
        });

        setCashFlows(thisCashFlows);

        const response = await api.get(`/restaurants/nfe-received/info/${id}`);

        setNfeReq(
          response.data.requisicao_nota_fiscal
            ? response.data.requisicao_nota_fiscal
            : null
        );

        if (!response.data.requisicao_nota_fiscal) {
          setLoading(false);
          return;
        }

        setDuplicatas(response.data.requisicao_nota_fiscal?.duplicatas);
        setLoading(false);

        //Provider
        const numero = response.data.requisicao_nota_fiscal?.numero;
        const serie = response.data.requisicao_nota_fiscal?.serie;
        const uf = response.data.requisicao_nota_fiscal?.uf_emitente;

        const provider = {
          name: response.data.requisicao_nota_fiscal?.nome_emitente,
          phone:
            response.data.requisicao_nota_fiscal?.telefone_emitente ||
            '00000000000',
          cnpj:
            response.data.requisicao_nota_fiscal?.cnpj_emitente ||
            response.data.requisicao_nota_fiscal?.cpf_emitente ||
            '00000000000000',
          numero_nota: numero,
          data_emissao: response.data.data_emissao,
          valor_total: response.data.requisicao_nota_fiscal?.valor_total,
          serie,
          uf,
          codigo_municipio:
            response.data.requisicao_nota_fiscal?.codigo_municipio_emitente,
          municipio: response.data.requisicao_nota_fiscal?.municipio_emitente,
          numero: response.data.requisicao_nota_fiscal?.numero_emitente,
          logradouro: response.data.requisicao_nota_fiscal?.logradouro_emitente,
          inscricao_estadual:
            response.data.requisicao_nota_fiscal?.inscricao_estadual_emitente,
          bairro: response.data.requisicao_nota_fiscal?.bairro_emitente,
          nome_fantasia_emitente:
            response.data.requisicao_nota_fiscal?.nome_fantasia_emitente ||
            response.data.requisicao_nota_fiscal?.nome_emitente,
        };

        setProvider(provider);

        //items
        const thisItens = (
          response.data.requisicao_nota_fiscal?.itens ||
          response.data.requisicao_nota_fiscal?.items
        ).map((item) => {
          return {
            numero_item: item.numero_item,
            cfop: null,
            codigo_ncm: item.codigo_ncm,
            codigo_produto: item.codigo_produto,
            descricao: item.descricao,
            quantidade_comercial: item.quantidade_comercial,
            unidade_comercial: item.unidade_comercial,
            valor_bruto: item.valor_bruto,
            valor_unitario_comercial: item.valor_unitario_comercial,
            input_id: null,
            input_name: null,
            input_quantidade: parseFloat(item.quantidade_comercial || 0),
            input_unitary_price: parseFloat(item.valor_unitario_comercial || 0),
            input_unidade: item.unidade_comercial,
            is_average: false,
            cash_flow_id: thisCashFlows[0].value,
            cash_flow_disabled: false,
            cash_flow_name: thisCashFlows[0].label,
            cfop: cfop_options[0].value,
            icms_situacao_tributaria: item.icms_situacao_tributaria,
            cst:
              item?.icms_situacao_tributaria?.length === 3
                ? null
                : item.icms_situacao_tributaria,
          };
        });

        setItens(thisItens);
      } catch (error) {
        console.log(error);
        toast.error('Erro ao consultar NFe, tente novamente');
      }
    },
    [provider, duplicatas]
  );

  function parseNFeProducts(xmlString, thisCashFlows) {
    const parser = new xml2js.Parser({ explicitArray: false });
    const nfe = NFe(xmlString);

    return parser
      .parseStringPromise(xmlString)
      .then((result) => {
        const nfeReq = {
          valor_total: nfe.total().valorNota(),
          data_emissao: nfe.identificacaoNFe().dataEmissao(),
          chave_nfe: nfe.informacoesProtocolo().chave(),
          serie: nfe.identificacaoNFe().serie(),
          numero: nfe.identificacaoNFe().nrNota(),
          modelo: nfe.identificacaoNFe().modelo(),
          codigo_unico: '-',
          versao: '-',
          cnpj_emitente: nfe.emitente().cnpj(),
          nome_fantasia_emitente: nfe.emitente().fantasia(),
          nome_emitente: nfe.emitente().nome(),
        };

        const duplicatas = [];

        const cobranca = nfe.cobranca();

        if (cobranca) {
          const numDup = cobranca.nrDuplicatas();
          if (numDup) {
            for (let i = 1; i <= numDup; i++) {
              const duplicata = cobranca.duplicata(i);

              duplicatas.push({
                numero: duplicata.numeroDuplicata(),
                data_vencimento: duplicata.vencimentoDuplicata(),
                valor: duplicata.valorDuplicata(),
              });
            }
          }
        }
        //falta testar a parte da duplicata com alguma nota que tenha

        const provider = {
          name: nfe.emitente().nome(),
          phone: nfe.emitente().telefone() || '00000000000',
          cnpj:
            nfe.emitente().cnpj() || nfe.emitente().cpf() || '00000000000000',
          numero_nota: nfeReq.numero,
          data_emissao: nfeReq.data_emissao,
          valor_total: nfeReq.valor_total,
          serie: nfeReq.serie,
          uf: nfe.emitente().uf(),
          codigo_municipio: nfe.emitente().codigoMunicipio(),
          municipio: nfe.emitente().municipio(),
          numero: nfe.emitente().numero(),
          logradouro: nfe.emitente().logradouro(),
          inscricao_estadual: nfe.emitente().inscricaoEstadual(),
          bairro: nfe.emitente().bairro(),
          nome_fantasia_emitente:
            nfe.emitente().fantasia() || nfe.emitente().nome(),
        };

        const itens = [];

        const nrItens = nfe.nrItens();

        for (let i = 1; i <= nrItens; i++) {
          const item = nfe.item(i);

          itens.push({
            numero_item: i.toString(),
            cfop: null,
            codigo_ncm: item.ncm(),
            codigo_produto: item.codigo(),
            descricao: item.descricao(),
            quantidade_comercial: item.quantidadeComercial(),
            unidade_comercial: item.unidadeComercial(),
            valor_bruto: (
              parseFloat(item.valorUnitario() || 0) *
              parseFloat(item.quantidadeComercial() || 0)
            ).toFixed(2), //item.valor,
            valor_unitario_comercial: item.valorUnitario(),
            input_id: null,
            input_name: null,
            input_quantidade: parseFloat(item.quantidadeComercial() || 0),
            input_unitary_price: parseFloat(item.valorUnitario() || 0),
            input_unidade: item.unidadeComercial(),
            is_average: false,
            cash_flow_id: thisCashFlows[0].value,
            cash_flow_disabled: false,
            cash_flow_name: thisCashFlows[0].label,
            cfop: cfop_options[0].value,
            icms_situacao_tributaria:
              item.imposto().icms().cst() !== ''
                ? item.imposto().icms().cst()
                : item.imposto().icms().csosn(),
            cst:
              item.imposto().icms().cst() === ''
                ? null
                : item.imposto().icms().cst(),
          });
        }

        return {
          nfeReq,
          duplicatas,
          provider,
          itens,
        };
      })
      .catch((err) => {
        toast.error('Erro ao processar XML');
        console.log('erro ao processar xml > ', err);
        throw new Error('Erro ao processar o XML: ' + err.message);
      });
  }

  const getXmlInfo = useCallback(async () => {
    try {
      setLoading(true);
      const responseCash = await api.get(
        '/restaurants/cash-flows/categories-detail'
      );

      const thisCashFlows = responseCash?.data?.map((category) => {
        return {
          value: category.id,
          label: category.text,
        };
      });

      setCashFlows(thisCashFlows);

      const response = await parseNFeProducts(
        localStorage.getItem('nfe_manifest_xml'),
        thisCashFlows
      );

      setNfeReq(response.nfeReq);
      setDuplicatas(response.duplicatas);
      setProvider(response.provider);
      setItens(response.itens);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao consultar NFe, tente novamente');
    }
  }, [provider, duplicatas]);

  useEffect(() => {
    getInputs();
    if (is_xml) {
      getXmlInfo();
    } else {
      getInfo(nfe_id);
    }
  }, [nfe_id, is_xml]);

  const handleAccept = useCallback(async () => {
    setLoadingNfe(true);
    try {
      const descricao = `${provider.nome_fantasia_emitente} Nota #${provider.numero_nota}`;

      const data = {
        tipo: 'confirmacao',
        descricao,
        duplicatas,
        provider,
        data_emissao: provider.data_emissao,
        itens,
      };

      await api.put(
        `/restaurants/nfe-received/operation/${is_xml ? 'xml' : nfe_id}`,
        data
      );

      setAccepted(true);

      toast.success('Nota aceita com sucesso!');

      // window.location.href = '/operation/fiscal/manifest';
      history.push('/operation/fiscal/manifest');
    } catch (err) {
      toast.error('Erro ao aceitar nota, tente novamente');
      console.log(err);
    }
    setLoadingNfe(false);
  }, [duplicatas, provider, itens, nfe_id, is_xml]);

  const handleRefuse = useCallback(async () => {
    setLoadingNfe(true);
    try {
      const data = {
        tipo: 'desconhecimento',
      };

      await api.put(`/restaurants/nfe-received/operation/${nfe_id}`, data);

      setAccepted(true);

      toast.success('Nota recusada com sucesso!');
      history.push('/operation/fiscal/manifest');
      // window.location.href = '/operation/fiscal/manifest';
    } catch (err) {
      toast.error('Erro ao recusar nota, tente novamente');
      console.log(err);
    }
    setLoadingNfe(false);
  }, [nfe_id]);

  return (
    <Wrapper>
      <Header>
        <h4>Manifesto de Nota</h4>
        <BackButton
          onClick={() => {
            history.push('/operation/fiscal/manifest');
          }}
        />
      </Header>

      {nfeReq || loading ? (
        <Content>
          {
            <Card>
              {loading ? (
                <div style={{ width: '100%' }}>
                  <Spinner
                    color="#ffffff"
                    spinnerWidth={4}
                    size={40}
                    inverted
                    style={{ margin: 'auto' }}
                  />
                </div>
              ) : (
                <>
                  <Form ref={formRefDev}>
                    {/* Cabeçalho */}
                    <ButtonCollapse
                      onClick={() => setIsOpenDadosNota(!isOpenDadosNota)}
                      style={{ fontSize: 18, cursor: 'auto' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        Dados da Nota
                        {is_xml && (
                          <div style={{ width: '12%' }}>
                            <OrderStatusTag
                              color="#01afff"
                              reversed
                              text="Nota XML"
                              width="100%"
                            />
                          </div>
                        )}
                      </div>
                    </ButtonCollapse>
                    <div>
                      <div
                        style={{ display: 'flex', gap: '10px', marginTop: 15 }}
                      >
                        <DefaultInput
                          name="valor_total"
                          title="Valor"
                          type="text"
                          value={`R$ ${
                            nfeReq?.valor_total
                              ? parseFloat(nfeReq?.valor_total).toLocaleString(
                                  'pt-br',
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : '-'
                          }`}
                          disabled
                          width="300px"
                          inputStyle={{ fontSize: 14 }}
                        />

                        <DefaultInput
                          name="data_emissao"
                          title="Data de Emissão"
                          type="text"
                          value={
                            nfeReq?.data_emissao
                              ? formatCompleteDate(nfeReq?.data_emissao)
                              : '-'
                          }
                          disabled
                          width="350px"
                        />

                        <DefaultInput
                          name="chave_nfe"
                          title="Chave"
                          type="text"
                          value={nfeReq?.chave_nfe || '-'}
                          disabled
                        />
                      </div>

                      <div
                        style={{ display: 'flex', gap: '10px', marginTop: 15 }}
                      >
                        <DefaultInput
                          name="serie"
                          title="Série"
                          type="text"
                          value={nfeReq?.serie || '-'}
                          disabled
                        />

                        <DefaultInput
                          name="numero"
                          title="Número"
                          type="text"
                          value={nfeReq?.numero || '-'}
                          disabled
                        />

                        <DefaultInput
                          name="modelo"
                          title="Modelo"
                          type="text"
                          value={nfeReq?.modelo || '-'}
                          disabled
                        />

                        <DefaultInput
                          name="codigo_unico"
                          title="Código Único"
                          type="text"
                          value={nfeReq?.codigo_unico || '-'}
                          disabled
                        />

                        <DefaultInput
                          name="versao"
                          title="Versão"
                          type="text"
                          value={nfeReq?.versao || '-'}
                          disabled
                        />
                      </div>
                    </div>

                    {/* Dados do Emitente */}
                    <ButtonCollapse
                      // onClick={() => setIsOpenDadosEmitente(!isOpenDadosEmitente)}
                      style={{ marginTop: 20, fontSize: 18, cursor: 'auto' }}
                    >
                      {/* <MdExpandMore size={20} title="Mostrar mais" /> */}
                      Dados do Emitente
                    </ButtonCollapse>

                    <Collapse isOpen={isOpenDadosEmitente}>
                      <div
                        style={{ display: 'flex', gap: '10px', marginTop: 15 }}
                      >
                        <DefaultInput
                          name="cnpj_emitente"
                          title="CNPJ"
                          type="text"
                          value={nfeReq?.cnpj_emitente}
                          disabled
                          width="500px"
                        />
                        <DefaultInput
                          name="nome_fantasia_emitente"
                          title="Fornecedor"
                          type="text"
                          value={nfeReq?.nome_fantasia_emitente}
                          disabled
                        />
                        <DefaultInput
                          name="nome_emitente"
                          title="Razão Social"
                          type="text"
                          value={nfeReq?.nome_emitente}
                          disabled
                        />
                      </div>
                    </Collapse>

                    {/* Duplicatas */}
                    <ButtonCollapse
                      // onClick={() => setIsOpenDuplicatas(!isOpenDuplicatas)}
                      style={{ marginTop: 20, fontSize: 18, cursor: 'auto' }}
                    >
                      {/* <MdExpandMore size={20} title="Mostrar mais" /> */}
                      Duplicatas
                    </ButtonCollapse>

                    <Collapse isOpen={isOpenDuplicatas}>
                      {console.log('duplicatas', duplicatas)}
                      {duplicatas?.length > 0 ? (
                        duplicatas
                          .filter((dup) => dup.created !== false)
                          .map((dup, index) => (
                            <Form initialData={dup} key={index}>
                              <div
                                style={{
                                  display: 'flex',
                                  gap: '10px',
                                  marginTop: 15,
                                }}
                                key={index}
                              >
                                <DefaultInput
                                  name="numero"
                                  title="Número"
                                  type="text"
                                  value={
                                    index < 9
                                      ? `00${index + 1}`
                                      : `0${index + 1}`
                                  }
                                  disabled
                                  width="300px"
                                />

                                <DefaultInput
                                  name="data_vencimento"
                                  title="Data Vencimento"
                                  type="date"
                                  value={dup.data_vencimento}
                                  width="350px"
                                  disabled
                                />

                                <DefaultInput
                                  name="valor"
                                  title="Valor"
                                  value={`R$ ${
                                    dup?.valor
                                      ? parseFloat(dup.valor).toLocaleString(
                                          'pt-br',
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      : '-'
                                  }`}
                                  type="text"
                                  disabled
                                />

                                <Button
                                  size="sm"
                                  buttonColor="#FF2C3A"
                                  title={<MdDelete size={20} />}
                                  inverted
                                  textStyle={{ fontSize: '14px' }}
                                  onClick={(e) => {
                                    if (
                                      window.confirm(
                                        `Tem certeza que deseja remover a duplicata ${dup.numero}`
                                      )
                                    ) {
                                      let finded = duplicatas.filter(
                                        (dupli) => dupli.numero !== dup.numero
                                      );
                                      console.log(duplicatas, finded);
                                      setDuplicatas(finded);
                                    }
                                  }}
                                  containerStyles={{
                                    marginTop: '23px',
                                    height: 38,
                                  }}
                                />

                                <Button
                                  onClick={() => setModalDivideDup(dup)}
                                  size="sm"
                                  buttonColor="#ffa500"
                                  title="Parcelar"
                                  inverted
                                  width="350px"
                                  textStyle={{ fontSize: '14px' }}
                                  containerStyles={{
                                    marginTop: '23px',
                                    width: '200px',
                                  }}
                                />

                                {index === 0 ? (
                                  <Button
                                    onClick={() => toggleCreateDup()}
                                    size="sm"
                                    buttonColor="#2EC9B7"
                                    title="Nova duplicata"
                                    inverted
                                    width="350px"
                                    textStyle={{ fontSize: '14px' }}
                                    containerStyles={{
                                      marginTop: '23px',
                                      width: '400px',
                                    }}
                                  />
                                ) : (
                                  <div style={{ width: '450px' }}></div>
                                )}
                              </div>
                            </Form>
                          ))
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 15,
                          }}
                        >
                          <p style={{ color: 'red', marginTop: '10px' }}>
                            Nota sem duplicatas, você pode criar uma no botão ao
                            lado
                          </p>
                          <Button
                            onClick={() => toggleCreateDup()}
                            size="sm"
                            buttonColor="#2EC9B7"
                            title="Nova duplicata"
                            inverted
                            textStyle={{ fontSize: '14px' }}
                          />
                        </div>
                      )}
                    </Collapse>

                    {/* Itens */}
                    <ButtonCollapse
                      // onClick={() => setIsOpenItens(!isOpenItens)}
                      style={{ marginTop: 15, fontSize: 18, cursor: 'auto' }}
                    >
                      {/* <MdExpandMore size={20} title="Mostrar mais" /> */}
                      Itens
                    </ButtonCollapse>

                    <Collapse isOpen={true}>
                      {itens.map((item) => (
                        <NfeManifestItem
                          item={item}
                          inputs={inputs}
                          cashFlows={cashFlows}
                          cfop_options={cfop_options}
                          user={user}
                        />
                      ))}
                    </Collapse>
                  </Form>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      justifyContent: 'space-between',
                      marginTop: 15,
                    }}
                  >
                    <Button
                      type="button"
                      onClick={toggleResume}
                      disabled={loadingNfe || accepted}
                      buttonColor="#2EC9B7"
                      title={
                        loadingNfe
                          ? 'Aguarde...'
                          : is_xml
                          ? 'Confirmar'
                          : 'Aceitar Nota'
                      }
                    />

                    {!is_xml && (
                      <Button
                        type="button"
                        onClick={toggleRefuse}
                        disabled={loadingNfe}
                        buttonColor="#FF2C3A"
                        inverted
                        title={loadingNfe ? 'Aguarde...' : 'Recusar Nota'}
                        style={{ marginRight: 10 }}
                      />
                    )}
                  </div>
                </>
              )}
            </Card>
          }
        </Content>
      ) : (
        <Content>
          <InformationDiv
            text="Nota ainda em processamento pela Sefaz. Tente novamente mais tarde"
            containerStyles={{
              alignContent: 'center',
              height: '50px',
              marginTop: 10,
            }}
            type="warning"
          />
        </Content>
      )}

      <Modal isOpen={modalCreateDup} toggle={toggleCreateDup} size="sm">
        <Form onSubmit={() => {}}>
          <ModalBody>
            <h4>Nova duplicata</h4>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '10px',
                marginTop: 15,
              }}
            >
              {/* <DefaultInput
                name="data_vencimento"
                title="Data Vencimento"
                type="date"
                defaultValue={formatLocalDate(new Date())}
                onChange={(e) => {
                  console.log(e.target.value);
                  let finded = duplicatas[duplicatas.length - 1] || null;
                  if(finded){
                    finded.data_vencimento = format(new Date(e.target.value), 'yyyy-MM-dd');
                  }
                }}
              /> */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                  marginTop: 9,
                }}
              >
                <span>
                  <b>Data de Vencimento</b>
                </span>
                <DayCalendar
                  onDateSelected={(e) => {
                    let finded = duplicatas[duplicatas.length - 1] || null;
                    if (finded) {
                      finded.data_vencimento = format(
                        new Date(e.start),
                        'yyyy-MM-dd'
                      );
                    }
                  }}
                  value={{
                    end: new Date(),
                    start: new Date(),
                  }}
                  containerStyles={{ width: '50%' }}
                />
              </div>

              <InputCurrency
                name="valor"
                label="Valor"
                type="text"
                prefix="R$"
                placeholder="R$"
                fontSize={{ fontSize: 14 }}
                format={currencyFormatter}
                onValueChange={(e) => {
                  let finded = duplicatas[duplicatas.length - 1] || null;
                  if (finded) {
                    finded.valor = e.floatValue > 0 ? e.floatValue / 100 : 0;
                  }
                  setDuplicatas(duplicatas);
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <DefaultSelect
                defaultValue={{
                  label: 1,
                  value: 1
                }}
                label="Parcelas"
                onChange={e => {
                  let finded = duplicatas[duplicatas.length - 1] || null;
                  if (finded) {
                    finded.portion = e.value;
                  }
                }}
                options={Array.from({ length: 19 }, (v, i) => i + 1).map(i => { return { value: i, label: i}})}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              buttonColor="#FF2C3A"
              onClick={() => toggleCreateDup()}
              title="Fechar"
              inverted
            />

            <Button
              buttonColor="#2EC9B7"
              type="submit"
              title="Criar duplicata"
              inverted
              onClick={() => {
                let finded = duplicatas[duplicatas.length - 1] || null;
                if (finded && (!finded.portion || finded.portion === 1)) {
                  finded.created = true;
                  setDuplicatas(duplicatas);
                }else if(finded){
                  createDivideDuplicata(finded);
                }

                toggleCreateDup();
              }}
            />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalDivideDup} toggle={toggleDivideDup} size="md">
        <Form onSubmit={() => {}}>
          <ModalBody>
            <h4>Parcelar duplicata</h4>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '10px',
                marginTop: 15,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                  marginTop: 9,
                }}
              >
                <span>
                  <b>1ª Data de Vencimento</b>
                </span>
                <DayCalendar
                  onDateSelected={(e) => {
                    modalDivideDup.data_vencimento = format(
                      new Date(e.start),
                      'yyyy-MM-dd'
                    );
                  }}
                  value={{
                    end: addHours(new Date(modalDivideDup?.data_vencimento || null), 4),
                    start: addHours(new Date(modalDivideDup?.data_vencimento || null), 4)
                  }}
                  containerStyles={{ width: '50%' }}
                />
              </div>

              <InputCurrency
                name="valor"
                label="Valor"
                type="text"
                prefix="R$"
                placeholder="R$"
                fontSize={{ fontSize: 14 }}
                format={currencyFormatter}
                disabled
                value={parseFloat(modalDivideDup?.valor || 0) * 100}
                // onValueChange={(e) => {
                //   let finded = duplicatas[duplicatas.length - 1] || null;
                //   if (finded) {
                //     finded.valor = e.floatValue > 0 ? e.floatValue / 100 : 0;
                //   }
                //   setDuplicatas(duplicatas);
                // }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <DefaultSelect
                defaultValue={{
                  label: 2,
                  value: 2
                }}
                label="Parcelas"
                onChange={e => {
                  modalDivideDup.portion = e.value;
                }}
                options={Array.from({ length: 19 }, (v, i) => i + 2).map(i => { return { value: i, label: i}})}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              buttonColor="#FF2C3A"
              onClick={() => toggleDivideDup()}
              title="Fechar"
              inverted
            />

            <Button
              buttonColor="#2EC9B7"
              type="submit"
              title="Dividir duplicata"
              inverted
              onClick={() => createDivideDuplicata(modalDivideDup)}
            />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalResume} toggle={toggleResume} size="lg">
        <ModalBody>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <h4>
              Revise as informações antes de{' '}
              {is_xml ? 'confirmar' : 'aceitar a nota'}
            </h4>
            {is_xml && (
              <div style={{ width: '20%' }}>
                <OrderStatusTag
                  color="#01afff"
                  reversed
                  text="Nota XML"
                  width="100%"
                />
              </div>
            )}
          </div>

          {is_xml && (
            <InformationDiv
              text={
                <>
                  <b>
                    A nota não será disponibilizada no manifesto/entrada de
                    notas e também não será incluída no SPED.
                  </b>
                </>
              }
              type="info"
            />
          )}

          {itens.map((item) => (
            <div>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#F4F4F4',
                  marginTop: 20,
                }}
              >
                <h4 style={{ marginBottom: 5 }}>
                  Item <strong>#{item.numero_item}</strong>
                </h4>
              </div>
              <div>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 400,
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  <b>
                    {item?.input_quantidade?.toString()?.replace('.', ',')}{' '}
                    {item.input_unidade}
                  </b>{' '}
                  do {item.input_id === null && 'novo'} insumo{' '}
                  <b>{item.input_name}</b> custando
                  <b>
                    {` R$ ${
                      item.input_unitary_price
                        ? parseFloat(item.input_unitary_price).toLocaleString(
                            'pt-br',
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : item.valor_unitario_comercial
                    }`}
                  </b>{' '}
                  cada
                </p>
              </div>
              <div>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 400,
                    marginTop: 0,
                    marginTop: 0,
                  }}
                >
                  CFOP <b>{item.cfop}</b> e centro de custo{' '}
                  <b>{item.cash_flow_name}</b>
                </p>
              </div>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button
            buttonColor="#FF2C3A"
            onClick={() => toggleResume()}
            title="Voltar"
            disabled={loadingNfe}
            inverted
          />

          <Button
            buttonColor="#2EC9B7"
            type="submit"
            title={loadingNfe ? 'Aguarde' : is_xml ? 'Confirmar' : 'Aceitar'}
            disabled={loadingNfe || accepted}
            onClick={() => {
              handleAccept();
            }}
          />
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalRefuse} toggle={toggleRefuse} size="md">
        <ModalBody>
          <h4>
            Tem certeza que deseja <b>recusar</b> essa nota?
          </h4>
        </ModalBody>
        <ModalFooter>
          <Button
            buttonColor="#FF2C3A"
            onClick={() => toggleRefuse()}
            title="Não, voltar"
            disabled={loadingNfe}
            inverted
          />

          <Button
            buttonColor="#2EC9B7"
            type="submit"
            title={loadingNfe ? 'Aguarde' : 'Sim, recusar'}
            disabled={loadingNfe || accepted}
            onClick={() => {
              handleRefuse();
            }}
          />
        </ModalFooter>
      </Modal>
    </Wrapper>
  );
}
