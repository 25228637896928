import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';

import { FiTrash } from 'react-icons/fi';

import posIcon from '../../assets/img/pos-icon.svg';

import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from 'reactstrap';

import { FaExclamationTriangle, FaQuestionCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Container,
  BillExplanation,
  InformationDiv,
  Total,
  Paid,
  PaymentDiv,
  PaymentTable,
  FinalDiv,
  AddPaymentForm,
  DeletePaymentForm,
  PaymentMethodButton,
  BlueButton,
  RescueButton,
  RescueContainer,
  RescueDate,
  TooltipContainer,
} from './styles';

import { Button, IconInput, Tooltip } from 'ui-kit-takeat';

import api from '~/services/api';
import InputPaymentForm from '~/components/Form/InputPaymentForm';
import { useAuth } from '~/context/AuthContext';
import CheckboxInput from '../Form/Checkbox';
import Select from '../Form/SelectInput';
import Input from '../Form/Input';
import InputMask from 'react-input-mask';
import apiClube from '~/services/apiClube';
import { format } from 'date-fns';
import { usePos } from '~/context/PosContext';
import { BsCheckLg } from 'react-icons/bs';
import { useClube } from '~/context/ClubeContext';

export default function PaymentFormStillOpenSessions({ session, sessions }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [paymentsBill, setPaymentsBill] = useState(session?.payments);
  const toggleDrop = () => setDropdownOpen((prevState) => !prevState);
  const [metodo, setMetodo] = useState('Outros');
  const {
    toastUpdateSystemMessage3,
    setToastUpdateSystemMessage3,
    restaurantDiscountObs,
    user,
    setData,
    paymentMethodsState
  } = useAuth();

  const {
    stoneTransactions,
    cancelStoneTransactions,
    createStoneTransactions,
  } = usePos();

  const [modal, setModal] = useState(false);
  function toggle(payment_id) {
    setPaymentIdToCancelStone(payment_id);
    setModal(!modal);
  }

  const [paymentIdToCancelStone, setPaymentIdToCancelStone] = useState(null);

  const { rescues, setRescues, saveSendedSession } = useClube();
  const [applyDiscount, setApplyDiscount] = useState(false);
  const [discountObs, setDiscountObs] = useState(null);
  const [discountText, setDiscountText] = useState(false);
  const [discountObsOptions, setDiscountObsOptions] = useState([]);
  const [discount, setDiscount] = useState(null);
  const [discountPercent, setDiscountPercent] = useState(null);
  const [clubInfo, setClubInfo] = useState({
    phone: session.client?.phone,
    date: '',
  });
  const [clientCashback, setClientCashback] = useState({
    date: null,
    value: 0,
  });
  const [loadingCashback, setLoadingCashback] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const [paymentOption, setPaymentOption] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [allPaymentMethods, setAllPaymentMethods] = useState([]);
  const [hasMoneyMethod, setHasMoneyMethod] = useState(false);
  const [paid, setPaid] = useState(0);
  const [left, setLeft] = useState(0);

  const [porcent, setPorcent] = useState(0);

  const [favoriteMethods, setFavoriteMethods] = useState([]);

  async function getClient(phone) {
    try {
      const response = await api.post('restaurants/get-clients', {
        phone,
      });

      if (
        response.data.hasClient &&
        session.client?.phone !== response.data.client.phone
      ) {
        submitClient(response.data.client);
      }
    } catch (error) {
      toast.error(
        'Não foi possível obter informações do cliente! tente novamente'
      );
    }
  }

  async function submitClient(client) {
    try {
      const response = await api.post('restaurants/clients/relate', {
        session_id: session.id,
        id: client.id,
      });

      toast.success('Cliente adicionado a comanda com sucesso!');
    } catch (error) {
      toast.error(
        'Não foi possível adicionar o cliente a comanda! tente novamente'
      );
    }
  }

  const getPaymentMethods = useCallback(async () => {
    const methods = paymentMethodsState.filter(
      (method) => method.available
    );

    methods.sort((a, b) => Number(b.is_favorite) - Number(a.is_favorite));

    const parsedCompleteData = methods.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
    }));

    setAllPaymentMethods(parsedCompleteData);

    const favoriteMethodsSplice = methods.splice(0, 8);

    const parsedDataFav = favoriteMethodsSplice.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
    }));

    setFavoriteMethods(parsedDataFav);

    const parsedData = methods.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
    }));
    setPaymentMethods(parsedData);
  }, []);

  const [isSendingDiscount, setIsSendigDiscount] = useState(false);

  const addDiscountToSession = async (e) => {
    e.preventDefault();
    if (!discountObs || !discount) {
      toast.error('Selecione um valor e uma justificativa para o desconto');
    } else {
      try {
        setIsSendigDiscount(true);
        const response = await api.put('/restaurants/table-session/discount', {
          discount,
          discountObs,
          userId: user.user_id,
          sessionId: session.id,
        });

        setApplyDiscount(false);
        // setSession(response.data);
        toast.success('Desconto aplicado com sucesso!');
        setIsSendigDiscount(false);
      } catch (err) {
        console.log('addDiscountToSession error: ', err);
        toast.error('Não foi possível aplicar o desconto');
        setIsSendigDiscount(false);
      }
    }
  };

  const getPayments = useCallback(async () => {
    let total = 0;
    paymentsBill &&
      paymentsBill.forEach((payment) => {
        total += parseFloat(payment.payment_value);
      });
    setPaid(total.toFixed(2).toString());

    const localLeft = session?.is_delivery
      ? session?.total_delivery_price - total
      : session?.total_service_price - total;
    setLeft(parseFloat(localLeft.toFixed(2)));

    const troco =
      total > session?.total_service_price
        ? total - session?.total_service_price
        : 0;

    const porcentPaid = session?.is_delivery
      ? (total / session?.total_delivery_price) * 100
      : ((total - troco) / session?.total_service_price) * 100;
    setPorcent(parseFloat(porcentPaid.toFixed(2)));
  }, [paymentsBill, session]);

  const deletePayments = useCallback(
    async (id) => {
      if (window.confirm('Tem certeza que deseja remover esse pagamento?')) {
        await api.delete(`restaurants/payments/${id}`);

        const payments_bill_find = paymentsBill.filter(
          (payment) => payment.id === id
        );

        const all_payments_bill = paymentsBill.filter(
          (payment) => payment.id !== id
        );
        // all_payments = all_payments.filter((payment) => payment.id !== id);
        getPayments();

        setPaymentsBill(all_payments_bill);

        document.getElementById('value').value = (
          Number(left) + Number(payments_bill_find[0].payment_value)
        ).toFixed(2);

        // setPaymentsSession(payments_bill);

        const newSessions = [...sessions];
        const sessionFind = newSessions.findIndex((it) => it.id === session.id);

        if (sessionFind !== -1) {
          const filteredPayments = newSessions[sessionFind].payments.filter(
            (it) => it.id !== id
          );
          newSessions[sessionFind].payments = filteredPayments;
        }

        toast.success('Pagamento removido com sucesso!');
      }
    },
    [getPayments, left]
  );

  let total = 0;
  paymentsBill &&
    paymentsBill.forEach((payment) => {
      total += parseFloat(payment.payment_value);
    });

  const totalValue = session?.is_delivery
    ? session?.total_delivery_price - total
    : session?.total_service_price - total;
  const [value, setValue] = useState(totalValue.toFixed(2));

  const handleChangeDiscount = (target) => {
    const targetValue = target.value.replace(/(R|\$|%)/g, '');
    if (targetValue.length < 1) {
      setDiscount('');
      setDiscountPercent('');
    } else if (target.name === 'flat_discount') {
      setDiscount(targetValue);
      setDiscountPercent(
        (
          (parseFloat(targetValue.replace(',', '.')) /
            session?.total_service_price) *
          100
        ).toFixed(2)
      );
    } else {
      setDiscount(
        (
          (parseFloat(targetValue.replace(',', '.')) *
            session?.total_service_price) /
          100
        ).toFixed(2)
      );
      setDiscountPercent(parseFloat(targetValue));
    }
  };

  const [insertingPayment, setInsertingPayment] = useState(false);

  const newPayments = useCallback(async () => {
    try {
      if (paymentOption.id) {
        setInsertingPayment(true);
        const response = await api.post('restaurants/payments', {
          payment_value:
            paymentOption.id === 238 ? clientCashback.value : Number(value),
          table_session_id: session.id,
          payment_method_id: paymentOption.id,
          rescues
        });

        if(response.data.clube){
          saveSendedSession(response.data.clube);
        }

        // paymentsBill.push(response.data);
        getPayments();
        setPaymentsBill((state) => {
          return [...state, response.data];
        });

        document.getElementById('value').value = '';

        const newSessions = [...sessions];
        const sessionFind = newSessions.findIndex((it) => it.id === session.id);

        if (sessionFind !== -1) {
          newSessions[sessionFind].payments = [
            ...newSessions[sessionFind].payments,
            response.data,
          ];
        }

        const totalProduct = session?.is_delivery
          ? session?.total_delivery_price
          : session?.total_service_price;

        if (left === 0) {
          document.getElementById('value').value = (
            totalProduct - Number(value)
          ).toFixed(2);
        } else if (left < 0) {
          document.getElementById('value').value = (
            left - Number(value)
          ).toFixed(2);
        } else {
          document.getElementById('value').value = (
            left - Number(value)
          ).toFixed(2);
        }
        setInsertingPayment(false);

        setClientCashback((state) => {
          return { ...state, value: 0 };
        });

        toast.success('Pagamento inserido com sucesso!');
      }
    } catch (error) {
      if (!error?.response?.ok) {
        if (error?.response?.data.errorType === 'cashier_opening_not_found') {
          toast.error(
            'Não foi encontrado caixa aberto. Favor abrir seu caixa.'
          );
        } else {
          setInsertingPayment(false);
          toast.error(
            error.response.data.message || 'Selecione o método de pagamento.'
          );
          console.log(error.message);
        }
      }

      console.log(error);
    }
  }, [
    getPayments,
    paymentOption.id,
    paymentsBill,
    setPaymentsBill,
    left,
    session,
    value,
    rescues
  ]);

  const getCashback = useCallback(async () => {
    try {
      let login_club;
      setLoadingCashback(true);
      if (!user.club_login) {
        login_club = await apiClube.post('/public/sessions/takeat', {
          token: user.token_clube,
        });

        localStorage.setItem(
          '@gddashboard:user',
          JSON.stringify({
            ...user,
            club_login: login_club.data.token,
            minimo: login_club.data.user?.settings[0]?.minimo,
          })
        );

        setData((state) => {
          return {
            ...state,
            user: {
              ...user,
              club_login: login_club.data.token,
              minimo: login_club.data.user?.settings[0]?.minimo,
            },
          };
        });
      }
      setLoadingCashback(true);
      const response = await apiClube.get(`/store/cashback/${clubInfo.phone}`, {
        headers: {
          Authorization: `Bearer: ${user.club_login || login_club.data.token}`,
        },
      });

      setClientCashback(response.data);
    } catch (err) {
      if (err.response?.data?.errorType === 'default_error') {
        toast.error(err.response.data.message);
      } else {
        toast.error(
          'Não foi possível buscar o cashback referente a este telefone'
        );
      }
      console.log('getCashback error: ', err);
    }
    setLoadingCashback(false);
  }, [user.club_login, clubInfo.phone]);

  useEffect(() => {
    try {
      getPayments();
    } catch (error) {
      toast.error('Erro ao carregar informações');
    }
  }, [getPayments]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  useEffect(() => {
    if (!applyDiscount) {
      setDiscount(null);
      setDiscountObs(null);
      setDiscountPercent(null);
    }
  }, [applyDiscount]);

  useEffect(() => {
    newPayments();
  }, [paymentOption]);

  useEffect(() => {
    setValue(left.toFixed(2));
    setMetodo('Outros');
  }, [left]);

  useEffect(() => {
    if (user.has_clube && clubInfo.phone?.length === 15) {
      getCashback();
      getClient(clubInfo.phone);
    }
  }, [clubInfo.phone]);

  useEffect(() => {
    if (
      clubInfo.date.length === 10 &&
      format(new Date(clientCashback.date), 'dd/MM/yyyy') !== clubInfo.date
    ) {
      toast.error('Data informada não coincide com a cadastrada');
    }
  }, [clubInfo.date]);

  async function setMetodoAndPaymentOption(payment) {
    if (
      payment.id === 238 &&
      parseFloat(clientCashback.value) < parseFloat(user.minimo)
    ) {
      toast.error('Valor do resgate abaixo do mínimo do clube');
      return;
    }
    setMetodo(payment.label);
    setPaymentOption({ id: payment.id, label: payment.label });

    if (payment.id === 238) {
      const newRescues = [
        ...rescues,
        { phone: clubInfo.phone, clientCashback: clientCashback.value },
      ];
      setRescues(newRescues);
    }
  }

  const [toastMessage, setToastMessage] = useState(
    toastUpdateSystemMessage3.toString() === 'true'
  );

  function toggleToast() {
    setToastUpdateSystemMessage3(false);

    setToastMessage(false);
  }

  useEffect(() => {
    const obs_options = restaurantDiscountObs?.map((disc) => ({
      value: disc.id,
      label: disc.obs,
    }));
    setDiscountObsOptions([...obs_options, { value: 'other', label: 'Outro' }]);
  }, [restaurantDiscountObs]);

  useEffect(() => {
    const input = document.getElementById('value')?.focus();
  }, []);

  const [loadingPos, setLoadingPos] = useState(false);

  const handleStoneTransactions = useCallback(async (payment_id) => {
    setLoadingPos(true);
    try {
      await createStoneTransactions({
        payment_id,
        stone_device_id: user.stone_device_id,
      });
    } catch (err) { }
    setLoadingPos(false);
  }, []);

  const handleCancelStoneTransactions = useCallback(async () => {
    setLoadingPos(true);
    try {
      await cancelStoneTransactions({ payment_id: paymentIdToCancelStone });
      toggle();
    } catch (err) { }
    setLoadingPos(false);
  }, [paymentIdToCancelStone]);

  useEffect(() => {
    const paymentMethodFiltered = paymentsBill.filter(
      (payment) => payment?.keyword === 'dinheiro'
    );
    if (paymentMethodFiltered.length > 0) {
      setHasMoneyMethod(true);
    } else {
      setHasMoneyMethod(false);
    }

    console.log(paymentsBill);
  }, [paymentsBill, left]);

  const [methodsFiltered, setMethodsFiltered] = useState([]);

  const prohibitedPaymentMethods = [
    238, //  Resgate Clube
    572, //  Pagamento Online iFood
    2548, // Cartão de Crédito Online
    301, //  Pagamento Online Takeat
    50629, //Cupom iFood
  ];

  function handleFilterMethods(method) {
    if (method.length === 0) {
      setMethodsFiltered([]);
      return;
    }

    const paymentsMethodsFiltered = paymentMethods.filter(
      (payment) =>
        payment.available && !prohibitedPaymentMethods.includes(payment.id)
    );

    if (method.length >= 2) {
      const productsFound = paymentsMethodsFiltered.filter((item) =>
        item.label
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            method
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      );

      setMethodsFiltered(productsFound);
    }
  }

  return (
    <Container>
      <BillExplanation>
        <h4>Adicionar Pagamento(s)</h4>
        <p>
          Aqui você adiciona o(s) pagamento(s) já feitos da comanda e confere
          quanto falta a ser pago.
        </p>
      </BillExplanation>

      {user.has_clube && (
        <Form
          style={{
            borderBottom: '1px solid #dee2e6',
            transition: 'all 0.4s',
            width: '100%',
          }}
        >
          <RescueContainer>
            {loadingCashback && (
              <Spinner
                style={{
                  position: 'absolute',
                  top: 49,
                  left: 135,
                  zIndex: 10,
                  height: 15,
                  width: 15,
                }}
              />
            )}
            <InputMask
              mask="(99) 99999-9999"
              maskPlaceholder={null}
              defaultValue={clubInfo.phone}
              value={clubInfo.phone}
              onChange={(e) => {
                const newText = e.target.value;
                setClubInfo((state) => {
                  return { ...state, phone: newText };
                });
              }}
            >
              <Input
                label="Celular:"
                name="club-phone"
                type="text"
                placeholder="Celular para pontuar no clube"
              />
            </InputMask>

            {clientCashback.date && (
              <RescueDate>
                <InputMask
                  id="date"
                  mask="99/99/9999"
                  maskPlaceholder={null}
                  defaultValue={clubInfo.date}
                  value={clubInfo.date}
                  onChange={(e) => {
                    const newText = e.target.value;
                    setClubInfo((state) => {
                      return { ...state, date: newText };
                    });
                  }}
                >
                  <Input
                    label="Data de nascimento:"
                    name="club-birthday"
                    type="text"
                    placeholder="dd/mm/aaaa"
                  />
                </InputMask>
                <TooltipContainer
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <FaQuestionCircle />
                  <Tooltip
                    show={showTooltip}
                    content="Adicione a data de aniversário para validar o resgate do cashback"
                    containerStyles={{
                      padding: 8,
                      borderRadius: 8,
                      top: 22,
                      left: -60,
                      width: 220,
                    }}
                  />
                </TooltipContainer>
                <Button
                  disabled={
                    format(new Date(clientCashback.date), 'dd/MM/yyyy') !==
                    clubInfo.date || parseFloat(clientCashback.value) <= 0
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    if (paymentsBill.find((p) => p.payment_method_id === 238)) {
                      toast.error('Resgate do clube já foi adicionado');
                    } else if (parseFloat(clientCashback.value) > 0) {
                      setMetodoAndPaymentOption({
                        label: 'Resgate Clube',
                        id: 238,
                      });
                    }
                  }}
                  title={`Resgatar R$${clientCashback.value}`}
                />
              </RescueDate>
            )}
          </RescueContainer>
        </Form>
      )}

      {(session?.table?.table_type === 'delivery' ||
        session?.table?.table_type === 'balcony') && (
          <>
            <Form>
              <CheckboxInput
                label="Aplicar desconto"
                name="apply"
                value={applyDiscount}
                onChange={() => setApplyDiscount(!applyDiscount)}
              />
            </Form>
            {applyDiscount && (
              <>
                <span style={{ color: 'grey' }}>Desconto</span>
                <div
                  style={{
                    display: 'flex',
                    margin: '10px 0 0',
                    position: 'relative',
                  }}
                >
                  <input
                    name="flat_discount"
                    type="text"
                    placeholder="R$"
                    style={{ width: '50%', padding: '3px 10px' }}
                    value={discount ? 'R$' + discount : ''}
                    onChange={(e) => handleChangeDiscount(e.target)}
                  />
                  <input
                    name="discount"
                    placeholder="%"
                    type="number"
                    style={{
                      width: '50%',
                      padding: '3px 10px',
                      marginLeft: '5px',
                    }}
                    value={discountPercent ? discountPercent : ''}
                    onChange={(e) => handleChangeDiscount(e.target)}
                  />
                  {discount && (
                    <p
                      style={{
                        position: 'absolute',
                        right: '15px',
                        top: '5px',
                      }}
                    >
                      %
                    </p>
                  )}
                </div>
                <Form>
                  <span style={{ color: 'grey', marginTop: '10px' }}>Motivo</span>
                  <Select
                    label={null}
                    name="discount_obs"
                    options={discountObsOptions}
                    onChange={(e) => {
                      if (e.value === 'other') {
                        setDiscountText(true);
                      } else {
                        setDiscountText(false);
                        setDiscountObs(e.label);
                      }
                    }}
                  />
                  {discountText && (
                    <Input
                      type="text"
                      placeholder="Escreva o motivo"
                      id="discount_obs"
                      name="discount_obs"
                      onChange={(e) => setDiscountObs(e.target.value)}
                    />
                  )}
                  <BlueButton
                    onClick={(e) => addDiscountToSession(e)}
                    disabled={isSendingDiscount}
                  >
                    Aplicar
                  </BlueButton>
                </Form>
              </>
            )}
          </>
        )}
      <InformationDiv>
        <Total>
          <span>Total</span>
          {session?.is_delivery ? (
            <h4>R${session?.total_delivery_price}</h4>
          ) : (
            <h4>R${session?.total_service_price}</h4>
          )}
        </Total>
        <Paid>
          <span>
            {left > 0 ? 'Restante' : hasMoneyMethod ? 'Troco' : 'Extra'}
          </span>
          <h4>
            R$
            {left > 0 ? left.toFixed(2) : (left * -1).toFixed(2)}
          </h4>
        </Paid>
        <Form>
          <InputPaymentForm
            placeholder="R$"
            type="number"
            step="0.01"
            min="0"
            name="value"
            id="value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Form>
      </InformationDiv>

      <PaymentDiv>
        <div
          style={{
            position: 'fixed',
            top: 300,
            left: 290,
            width: '415px',
            height: 210,
            backgroundColor: '#fff',
            borderRadius: 7,
            padding: 20,
            filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
            display: toastMessage ? 'flex' : 'none',
            zIndex: 2000000,
          }}
        >
          <div style={{}}>
            <p style={{ fonteWeight: 'bold', fontSize: 18 }}>
              <FaExclamationTriangle color="darkOrange" />{' '}
              <strong>Atenção, nova Atualização!</strong>
            </p>
            <span>
              Agora é possível favoritar os métodos de pagamento para que eles
              apareçam nessa lista e agilize ainda mais a sua escolha. Se quiser
              alterar o método de pagamento favorito,{' '}
              <Link to="/box/payment"> clique aqui.</Link>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              style={{
                background: '#3BD2C1',
                color: '#fff',
                width: 54,
                height: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                borderRadius: 7,
                marginLeft: 10,
                fontWeight: 'bold',
                border: 'none',
              }}
              onClick={(e) => toggleToast(false)}
            >
              OK
            </button>
          </div>
        </div>

        <AddPaymentForm>
          {favoriteMethods.map((payment) =>
            payment.available &&
              !prohibitedPaymentMethods.includes(payment.id) ? (
              <div key={payment.id}>
                <PaymentMethodButton
                  onClick={() => setMetodoAndPaymentOption(payment)}
                  disabled={insertingPayment}
                >
                  {payment.label}
                </PaymentMethodButton>
              </div>
            ) : (
              <></>
            )
          )}

          {paymentMethods.length > 0 && favoriteMethods.length >= 8 && (
            <Dropdown isOpen={dropdownOpen} size="sm" toggle={toggleDrop}>
              <DropdownToggle
                color="danger"
                caret
                style={{ width: '135px', height: '40px', margin: 0 }}
              >
                {metodo}
              </DropdownToggle>
              <DropdownMenu style={{ overflowY: 'scroll', height: 300 }}>
                <IconInput
                  color="#6c6c6c"
                  placeholder="Buscar"
                  isClearable
                  deleteButton={() => setMethodsFiltered([])}
                  containerStyles={{
                    height: 40,
                    border: 'none',
                    borderBottom: '1px solid #6c6c6c',
                    borderRadius: 0,
                  }}
                  onChange={(e) => {
                    handleFilterMethods(e.target.value);
                  }}
                />
                {methodsFiltered && methodsFiltered.length > 0
                  ? methodsFiltered.map((payment) => (
                    <DropdownItem
                      onClick={() => setMetodoAndPaymentOption(payment)}
                      disabled={insertingPayment}
                    >
                      {payment.label}
                    </DropdownItem>
                  ))
                  : paymentMethods.map((payment) =>
                    payment.available &&
                      !prohibitedPaymentMethods.includes(payment.id) ? (
                      <div key={payment.id}>
                        <DropdownItem
                          onClick={() => setMetodoAndPaymentOption(payment)}
                          disabled={insertingPayment}
                        >
                          {payment.label}
                        </DropdownItem>
                      </div>
                    ) : (
                      <></>
                    )
                  )}
              </DropdownMenu>
            </Dropdown>
          )}
        </AddPaymentForm>

        <PaymentTable borderless>
          <tbody>
            {session.payments?.map((payment) =>
              payment.payment_method.id === 238 ? (
                <tr key={payment.id} style={{ color: '#017957' }}>
                  <td>Resgate Clube</td>
                  <td>R${payment.payment_value}</td>
                </tr>
              ) : payment?.payment_method.keyword === 'pix_auto' ? (
                <tr key={payment.id} style={{ color: '#017957' }}>
                  <td>Pix Online</td>
                  <td>R${payment.payment_value}</td>
                </tr>
              ) : payment?.payment_method.keyword === 'stone_credit' ? (
                <tr key={payment.id} style={{ color: '#017957' }}>
                  <td>Stone Crédito</td>
                  <td>R${payment.payment_value}</td>
                </tr>
              ) : (
                payment?.payment_method.keyword === 'stone_debit' && (
                  <tr key={payment.id} style={{ color: '#017957' }}>
                    <td>Stone Débito</td>
                    <td>R${payment.payment_value}</td>
                  </tr>
                )
              )
            )}
            {paymentsBill.map(
              (payment) =>
                payment.id !== 238 &&
                payment?.payment_method.keyword !== 'pix_auto' &&
                payment?.payment_method.keyword !== 'stone_credit' &&
                payment?.payment_method.keyword !== 'stone_debit' && (
                  <tr key={payment.id}>
                    {allPaymentMethods
                      .filter(
                        (method) => method.id === payment.payment_method.id
                      )
                      .map((method) => (
                        <td key={method.id}>{method.label}</td>
                      ))}
                    <td>R${payment.payment_value}</td>
                    <td />
                    <td>
                      <div>
                        <DeletePaymentForm
                          onClick={() => deletePayments(payment.id)}
                        >
                          <FiTrash size={15} />
                        </DeletePaymentForm>
                      </div>
                    </td>
                    {user.has_stone_pdv &&
                      ['CREDIT', 'DEBIT', 'credit', 'debit'].includes(
                        payment.payment_method.method
                      ) &&
                      parseFloat(payment.payment_value) > 0 && (
                        <td style={{ textAlign: 'right' }}>
                          {!stoneTransactions.find(
                            (trans) =>
                              trans.payment_id === payment.id &&
                              trans.status !== 'canceled'
                          ) && (
                              <div>
                                <DeletePaymentForm
                                  onClick={() =>
                                    !loadingPos &&
                                    handleStoneTransactions(payment.id)
                                  }
                                  style={
                                    loadingPos ? { pointerEvents: 'none' } : {}
                                  }
                                >
                                  <img src={posIcon} style={{ height: 18 }} />
                                </DeletePaymentForm>
                              </div>
                            )}

                          {stoneTransactions.find(
                            (trans) =>
                              trans.payment_id === payment.id &&
                              trans.status === 'pending'
                          ) && (
                              <div>
                                <DeletePaymentForm
                                  onClick={() => toggle(payment.id)}
                                  disabled={loadingPos}
                                >
                                  <Spinner
                                    size="sm"
                                    color="#3BD2C1"
                                    style={{
                                      width: 15,
                                      height: 15,
                                      marginBottom: 4,
                                      color: '#3BD2C1',
                                    }}
                                  />
                                </DeletePaymentForm>
                              </div>
                            )}

                          {stoneTransactions.find(
                            (trans) =>
                              trans.payment_id === payment.id &&
                              trans.paid_at !== null
                          ) && (
                              <div>
                                <DeletePaymentForm
                                  // onClick={() => handleStoneTransactions(payment.id)}
                                  disabled={loadingPos}
                                >
                                  <BsCheckLg
                                    style={{
                                      width: 20,
                                      height: 20,
                                      color: '#3BD2C1',
                                    }}
                                  />
                                </DeletePaymentForm>
                              </div>
                            )}
                        </td>
                      )}
                  </tr>
                )
            )}
          </tbody>
        </PaymentTable>
      </PaymentDiv>
      <FinalDiv>
        <Total>
          <span>Pago</span>
          <h4>R${paid}</h4>
        </Total>
        <Paid>
          <span>Pago</span>
          <h4>{porcent}%</h4>
        </Paid>
      </FinalDiv>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody style={{ padding: 20 }}>
          <Row style={{ paddingTop: 15 }}>
            <Col md="12">Deseja cancelar esse pagamento na POS?</Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ marginLeft: 'auto' }}>
          <Button
            title="Sim, cancelar"
            buttonColor="#2ec9b7"
            disabled={loadingPos}
            onClick={(e) => handleCancelStoneTransactions()}
          />
        </ModalFooter>
      </Modal>
    </Container>
  );
}