/* eslint-disable no-unused-expressions */
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FaAngleDown,
  FaCircle,
  FaPlus,
  FaTrash,
  FaUtensils,
} from 'react-icons/fa';
import ReactModal from 'react-modal';
import { Col, Modal, ModalBody, ModalFooter, Row, TabContent, TabPane } from 'reactstrap';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import OutlineButton from '~/components/Buttons/OutlineButton';
import PersonalButton from '~/components/Buttons/PersonalButton';
import CheckboxInput from '~/components/Form/Checkbox';
import RadioInput from '~/components/Form/RadioInput';
import Input from '~/components/Form/Input';
import logo1 from '~/assets/img/3partes.png';
import logo2 from '~/assets/img/4partes.png';
import logo3 from '~/assets/img/5partes.png';


import {
  Card,
  Content,
  AddKitchenCard,
  ChooseKitchenCard,
  ScreenTwo,
  ScreenOne,
  ButtonsArea,
  StyledTable,
  InputStyled,
  CheckboxInputStyled,
  LinkStyled,
} from './styles';
import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';
import { Tabs } from 'ui-kit-takeat';
import { useAuth } from '~/context/AuthContext';

ReactModal.setAppElement('#root');

const CheckState = {
  On: 0,
  Off: 1,
  Partial: 2,
};

function ChooseKitchen({ kds, getKds }) {
  const [groupItems, setGroupItems] = useState(false);
  const [kdsNotifyWhatsapp, setKdsNotifyWhatsapp] = useState(false);
  const [kdsNotifySound, setKdsNotifySound] = useState(false);
  const [modalCreateKitchen, setModalCreateKitchen] = useState(false);
  const [allChecked, setAllChecked] = useState(CheckState.Off);
  const [allComplementsChecked, setAllComplementsChecked] = useState(CheckState.Off);
  const { user } = useAuth()
  const toggleModalCreateKitchen = useCallback(() => {
    if (!modalCreateKitchen) {
      setGroupItems(false);
      setKdsNotifyWhatsapp(false);
      setKdsNotifySound(false)
      setAllChecked(CheckState.Off);
      setAllComplementsChecked(CheckState.Off)
    }
    setModalCreateKitchen(!modalCreateKitchen);
  }, [modalCreateKitchen]);

  const [activeOne, setActiveOne] = useState(true);

  const toogleActiveOne = useCallback(() => {
    setActiveOne(!activeOne);
  }, [activeOne]);

  const [arrayCategories, setArrayCategories] = useState([]);

  const getCategories = useCallback(async () => {
    const response = await api.get('/restaurants/kds/product-categories/0');

    const categories = response.data;

    setArrayCategories(categories);
  }, []);

  const [arrayComplementCategories, setArrayComplementCategories] = useState([]);

  const getComplementCategories = useCallback(async () => {
    const response = await api.get('/restaurants/kds/complement-categories/0');

    const categories = response.data;

    setArrayComplementCategories(categories);
  }, []);

  useEffect(() => {
    if (modalCreateKitchen) {
      getCategories();
    }
  }, [getCategories, modalCreateKitchen]);

  useEffect(() => {
    if (modalCreateKitchen && (user?.id === 2 || user?.id === 58284)) {
      getComplementCategories()
    }

  }, [getComplementCategories, user, modalCreateKitchen])

  const [categoryExpanded, setCategoryExpanded] = useState(null);

  function toggleExpand(id) {
    if (categoryExpanded === id) {
      setCategoryExpanded(null);
    } else {
      setCategoryExpanded(id);
    }
  }

  const [complementCategoryExpanded, setComplementCategoryExpanded] = useState(null);

  function toggleExpandComplementCategory(id) {
    if (complementCategoryExpanded === id) {
      setComplementCategoryExpanded(null);
    } else {
      setComplementCategoryExpanded(id);
    }
  }

  const updateAll = useRef(false);

  useEffect(() => {
    if (!updateAll.current) {
      return;
    }

    updateAll.current = false;

    if (allChecked === CheckState.On) {
      setArrayCategories((categs) => {
        for (const item of categs) {
          item.checked = true;
          for (const product of item.products) {
            product.checked = true;
          }
        }

        return categs.slice();
      });
    } else if (allChecked === CheckState.Off) {
      setArrayCategories((categs) => {
        for (const item of categs) {
          item.checked = false;
          for (const product of item.products) {
            product.checked = false;
          }
        }

        return categs.slice();
      });
    }
  }, [allChecked]);

  useEffect(() => {
    if (!updateAll.current) {
      return;
    }

    updateAll.current = false;

    if (allComplementsChecked === CheckState.On) {
      setArrayComplementCategories((categs) => {
        for (const item of categs) {
          item.checked = true;
          for (const complement of item.complements) {
            complement.checked = true;
          }
        }

        return categs.slice();
      });
    } else if (allComplementsChecked === CheckState.Off) {
      setArrayComplementCategories((categs) => {
        for (const item of categs) {
          item.checked = false;
          for (const complement of item.complements) {
            complement.checked = false;
          }
        }

        return categs.slice();
      });
    }
  }, [allComplementsChecked]);

  useEffect(() => {
    const checkedCount = arrayCategories.reduce(
      (acc, item) => item.checked ? acc + 1 : acc, 0
    );

    if (checkedCount === 0) {
      setAllChecked(CheckState.Off);
    } else if (checkedCount === arrayCategories.length) {
      setAllChecked(CheckState.On);
    } else {
      setAllChecked(CheckState.Partial);
    }
  }, [arrayCategories]);

  useEffect(() => {
    const checkedCount = arrayComplementCategories.reduce(
      (acc, item) => item.checked ? acc + 1 : acc, 0
    );

    if (checkedCount === 0) {
      setAllComplementsChecked(CheckState.Off);
    } else if (checkedCount === arrayComplementCategories.length) {
      setAllComplementsChecked(CheckState.On);
    } else {
      setAllComplementsChecked(CheckState.Partial);
    }
  }, [arrayComplementCategories]);

  function handleCheckCategory(id, old_status) {
    setArrayCategories((categs) => {
      const found = categs.find(
        (cat) => cat.product_category_id === id
      );

      if (!found) {
        return categs;
      }

      if (old_status) {
        found.checked = false;

        for (const product of found.products) {
          product.checked = false;
        }
      } else {
        found.checked = true;

        for (const product of found.products) {
          product.checked = true;
        }
      }

      return categs.slice();
    });
  }

  function handleCheckComplementCategory(id, old_status) {
    setArrayComplementCategories((categs) => {
      const found = categs.find(
        (cat) => cat.complement_category_id === id
      );

      if (!found) {
        return categs;
      }

      if (old_status) {
        found.checked = false;

        for (const complement of found.complements) {
          complement.checked = false;
        }
      } else {
        found.checked = true;

        for (const complement of found.complements) {
          complement.checked = true;
        }
      }

      return categs.slice();
    });
  }

  function handleCheckProduct(category_id, product_id, old_status) {
    setArrayCategories((categs) => {
      const found = categs.find(
        (cat) => cat.product_category_id === category_id
      );

      if (!found || !found.products) {
        return categs;
      }

      const productFound = found.products.find(
        (prod) => prod.product_id === product_id
      );

      if (!productFound) {
        return categs;
      }

      productFound.checked = !old_status;

      const checkedCount = found.products.reduce(
        (acc, prod) => prod.checked ? acc + 1 : acc, 0
      );

      found.checked = checkedCount !== 0;

      return categs.slice();
    });
  }

  function handleCheckComplement(category_id, complement_id, old_status) {
    setArrayComplementCategories((categs) => {
      const found = categs.find(
        (cat) => cat.complement_category_id === category_id
      );

      if (!found || !found.complements) {
        return categs;
      }

      const complementeFound = found.complements.find(
        (comp) => comp.complement_id === complement_id
      );

      if (!complementeFound) {
        return categs;
      }

      complementeFound.checked = !old_status;

      const checkedCount = found.complements.reduce(
        (acc, comp) => comp.checked ? acc + 1 : acc, 0
      );

      found.checked = checkedCount !== 0;

      return categs.slice();
    });
  }

  const [viewMode, setViewMode] = useState('');

  const formRef = useRef(null);

  const handleSubmit = useCallback(
    async (data) => {
      if (activeOne) {
        try {
          if (formRef.current) {
            formRef.current.setErrors({});
          }

          const schema = Yup.object().shape({
            average_time: Yup.string().required('Tempo de preparo obrigatório'),
            group_items: Yup.string().required('Agrupar itens Obrigatório'),
            name: Yup.string().required('Nome Obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          if (viewMode) {
            toogleActiveOne();
          } else {
            toast.error('Selecione o modo de visualização');
          }
        } catch (error) {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            if (formRef.current) {
              formRef.current.setErrors(errors);
            }
          }
        }
      } else {
        const productsMinimalInfo = arrayCategories.map(({ name, custom_order, products, ...rest }) => ({
          ...rest,
          products: products.map(({ name, custom_order, ...restComplement }) => restComplement)
        }));

        const complementsMinimalInfo = arrayComplementCategories.map(({ name, custom_order, complements, ...rest }) => ({
          ...rest,
          complements: complements.map(({ name, custom_order, ...restComplement }) => restComplement)
        }));

        try {
          await api.post('restaurants/kds', {
            average_time: data.average_time,
            group_items: data.group_items,
            notify_whatsapp: kdsNotifyWhatsapp,
            notify_sound: kdsNotifySound,
            name: data.name,
            view_mode: viewMode,
            product_categories: productsMinimalInfo.filter(
              (item) => item.checked && item.products.length > 0
            ),
            complement_categories: complementsMinimalInfo.filter(
              (item) => item.checked && item.complements.length > 0
            ),
          });

          toast.success('Tela cadastrada com sucesso');
          toggleModalCreateKitchen();
          getKds();
        } catch (error) {
          toast.error('Erro ao cadastrar tela');
        }
      }
    },
    [
      activeOne,
      arrayCategories,
      arrayComplementCategories,
      viewMode,
      toogleActiveOne,
      toggleModalCreateKitchen,
      kdsNotifyWhatsapp,
      getKds,
    ]
  );

  function handleChangeCategoryPreparationTime(id, value) {
    setArrayCategories((cat) => {
      const newArray = [...cat];
      const categoyFind = newArray.findIndex(
        (cat) => cat.product_category_id === id
      );

      newArray[categoyFind].average_time = value;

      return [...newArray];
    });
  }

  function handleChangeComplementCategoryPreparationTime(id, value) {
    setArrayComplementCategories((cat) => {
      const newArray = [...cat];
      const categoyFind = newArray.findIndex(
        (cat) => cat.complement_category_id === id
      );

      newArray[categoyFind].average_time = value;

      return [...newArray];
    });
  }

  function handleChangeProductPreparationTime(category_id, product_id, value) {
    setArrayCategories((cat) => {
      const newArray = [...cat];
      const categoyFind = newArray.findIndex(
        (cat) => cat.product_category_id === category_id
      );

      const productFind = newArray[categoyFind].products.findIndex(
        (prod) => prod.product_id === product_id
      );

      newArray[categoyFind].products[productFind].average_time = value;

      return [...newArray];
    });
  }

  function handleChangeComplementPreparationTime(category_id, complement_id, value) {
    setArrayCategories((cat) => {
      const newArray = [...cat];
      const categoyFind = newArray.findIndex(
        (cat) => cat.complement_category_id === category_id
      );

      const complementFind = newArray[categoyFind].complements.findIndex(
        (comp) => comp.complement_id === complement_id
      );

      newArray[categoyFind].complements[complementFind].average_time = value;

      return [...newArray];
    });
  }

  function handleGetAllProductsChecked(id) {
    const categoryFind = arrayCategories.find(
      (category) => category.product_category_id === id
    );

    const allCheckedItems = categoryFind?.products.every((item) => item.checked);

    return allCheckedItems;
  }

  function handleGetAllComplementsChecked(id) {
    const categoryFind = arrayComplementCategories.find(
      (category) => category.complement_category_id === id
    );

    const allCheckedItems = categoryFind?.complements.every((item) => item.checked);

    return allCheckedItems;
  }

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  const [isModalDeleteOpened, setIsModalDeleteOpened] = useState(false);
  const [kdsIdToDelete, setKdsIdToDelete] = useState();

  const handleDeleteKds = useCallback(async () => {
    try {
      await api.delete(`/restaurants/kds/${kdsIdToDelete}`);
      toast.success('Tela deletada com suceso!');
      setIsModalDeleteOpened(false);

      getKds();
    } catch (error) {
      toast.error('Erro ao deletar tela');
    }
  }, [kdsIdToDelete, getKds]);

  const toggleDeleteKdsModal = useCallback(
    (id) => {
      setIsModalDeleteOpened(!isModalDeleteOpened);
      setKdsIdToDelete(id);
    },
    [isModalDeleteOpened]
  );

  const [activeTab, setActiveTab] = useState(0);

  return (
    <Card>
      <Content>
        <AddKitchenCard onClick={toggleModalCreateKitchen}>
          <FaPlus size="34" />{' '}
          <p>
            Adicionar nova <br /> Tela
          </p>
        </AddKitchenCard>
        {kds &&
          kds?.length > 0 &&
          kds?.map((item) => (
            <ChooseKitchenCard key={item.id}>
              <LinkStyled
                to={{
                  pathname: '/kitchen',
                  state: {
                    item,
                  },
                }}
              >
                <FaUtensils size="34" color="#fff" /> <p>{item?.name}</p>{' '}
              </LinkStyled>

              <FaTrash
                color="#fff"
                size={17}
                title="Deletar tela"
                onClick={() => {
                  toggleDeleteKdsModal(item.id);
                }}
                cursor="pointer"
                style={{ position: 'absolute', top: 20, right: 20 }}
              />
            </ChooseKitchenCard>
          ))}
      </Content>

      <Modal
        isOpen={isModalDeleteOpened}
        toggle={() => setIsModalDeleteOpened(false)}
      >
        <ModalBody>Tem certeza que deseja deletar essa tela?</ModalBody>
        <ModalFooter>
          <PersonalButton
            color="#FF2C3A"
            message="Cancelar"
            type="button"
            onClick={() => setIsModalDeleteOpened(false)}
          />
          <PersonalButton
            color="#0CCAB4"
            message="Confirmar"
            type="button"
            onClick={handleDeleteKds}
          />
        </ModalFooter>
      </Modal>

      <ReactModal
        onRequestClose={toggleModalCreateKitchen}
        isOpen={modalCreateKitchen}
        className="modal-content-takeat"
        closeTimeoutMS={500}
      >
        <Form onSubmit={handleSubmit} ref={formRef}>
          <ScreenOne active={activeOne} browserZoomLevel={browserZoomLevel}>
            <Row>
              <Input label="Nome do KDS" name="name" maxlength="30" />
            </Row>
            <Row>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckboxInput
                  name="group_items"
                  disabled={kdsNotifyWhatsapp}
                  value={groupItems}
                  onChange={() => {
                    if (!kdsNotifyWhatsapp) {
                      setGroupItems(!groupItems);
                    } else {
                      setGroupItems(true);
                    }
                  }}
                />
                <span>
                  Agrupar itens do pedido (se desmarcado, será exibido um item
                  por vez)
                </span>
              </div>
            </Row>

            <Row>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }} >
                <CheckboxInput
                  name="notify_whatsapp"
                  value={kdsNotifyWhatsapp}
                  onChange={() => {
                    if (!kdsNotifyWhatsapp) {
                      updateAll.current = true;

                      setGroupItems(true);
                      setAllChecked(CheckState.On);
                    }

                    setKdsNotifyWhatsapp(!kdsNotifyWhatsapp);
                  }}
                />
                <span>
                  Avisar ao cliente (por Whatsapp) quando o pedido estiver pronto.
                </span>
              </div>
            </Row>

            <Row>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }} >
                <CheckboxInput
                  name="notify_sound"
                  value={kdsNotifySound}
                  onChange={() => {
                    setKdsNotifySound(!kdsNotifySound);
                  }}
                />
                <span>
                  Ativar som ao chegar novo pedido.
                </span>
              </div>
            </Row>

            <Row>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>
                Modo de visualização
              </p>
              <p style={{ fontSize: 14 }}>
                Selecione a quantidade de colunas a serem exibidas na tela
              </p>
            </Row>
            <Row className="mt-3">
              <Col md="4" className="d-flex flex-column">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {' '}
                  <RadioInput
                    name="view_mode"
                    value="3"
                    onChange={(e) => setViewMode(3)}
                  />
                  <span>3</span>
                </div>
                <img
                  src={logo1}
                  alt="imagem mostrando como a tela vai ficar dividida"
                  className="mt-3"
                />
              </Col>
              <Col md="4" className="d-flex flex-column">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {' '}
                  <RadioInput
                    name="view_mode"
                    value="4"
                    onChange={(e) => setViewMode(4)}
                  />
                  <span>4 (recomendado)</span>
                </div>
                <img
                  src={logo2}
                  alt="imagem mostrando como a tela vai ficar dividida"
                  className="mt-3"
                />
              </Col>
              <Col md="4" className="d-flex flex-column">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {' '}
                  <RadioInput
                    name="view_mode"
                    value="5"
                    onChange={(e) => setViewMode(5)}
                  />
                  <span>5</span>
                </div>
                <img
                  src={logo3}
                  alt="imagem mostrando como a tela vai ficar dividida"
                  className="mt-3"
                />
              </Col>
            </Row>
            <Row>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>
                Tempo médio (Em minutos)
              </p>
              <p style={{ fontSize: 14 }}>
                Tempo médio de preparação dos pratos. Caso algum prato tenha
                tempo específico, basta personalizar na próxima etapa.
              </p>
            </Row>
            <Row>
              <Input name="average_time" />
            </Row>
          </ScreenOne>

          <ScreenTwo active={!activeOne} browserZoomLevel={browserZoomLevel}>
            <Row>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>
                Tempo Personalizado
              </p>
              <p style={{ fontSize: 14 }}>
                Personalize o tempo de preparo para itens específicos. Essa
                etapa é opcional. Caso não seja definido será considerado a
                tempo médio definido na etapa anterior.
              </p>
            </Row>
            {user?.id === 2 || user?.id === 58284 ? (
              <Tabs
                containerStyles={{ marginTop: 10 }}
                tabs={['Produtos', 'Complementos']}
                selected={activeTab}
                onChangeTab={(e) => setActiveTab(e)}
                mediaQueries={[
                  {
                    maxWidth: 1024,
                    styles: 'font-size: 12px; padding: 0 5px;'
                  }
                ]}
                tabSelectedStyles={{
                  borderBottom: '2px solid red',
                  borderRadius: 0
                }}
                tabStyles={{
                  borderBottom: '2px solid transparent',
                  boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.1)'
                }}
              />
            ) : (
              <Tabs
                containerStyles={{ marginTop: 10 }}
                tabs={['Produtos']}
                selected={activeTab}
                onChangeTab={(e) => setActiveTab(e)}
                mediaQueries={[
                  {
                    maxWidth: 1024,
                    styles: 'font-size: 12px; padding: 0 5px;'
                  }
                ]}
                tabSelectedStyles={{
                  borderBottom: '2px solid red',
                  borderRadius: 0
                }}
                tabStyles={{
                  borderBottom: '2px solid transparent',
                  boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.1)'
                }}
              />
            )}

            <TabContent activeTab={activeTab}>
              <TabPane tabId={0}>
                <StyledTable>
                  <thead>
                    <tr>
                      <th>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#6c6c6c',
                          }}
                        >
                          {' '}
                          <CheckboxInputStyled
                            allChecksChecked={allChecked === CheckState.Partial}>
                            <input
                              name="all_selected"
                              checked={allChecked !== CheckState.Off}
                              onChange={(e) => {
                                updateAll.current = true;

                                if (allChecked === CheckState.On) {
                                  setAllChecked(CheckState.Off);
                                } else {
                                  setAllChecked(CheckState.On);
                                }
                              }}
                              type="checkbox"
                            />
                          </CheckboxInputStyled>
                          Todos os itens
                        </span>
                      </th>
                      <th>Tempo de preparo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arrayCategories.map((category) => (
                      <React.Fragment key={category?.product_category_id}>
                        <tr>
                          <td>
                            {' '}
                            <span
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                color: '#6c6c6c',
                                fontWeight: 'bold',
                              }}
                            >
                              <FaAngleDown
                                size={16}
                                onClick={() => {
                                  toggleExpand(category?.product_category_id);
                                }}
                                cursor="pointer"
                                style={{
                                  transition: 'all 0.2s',
                                  transform: categoryExpanded === category?.product_category_id
                                    ? 'rotate(-90deg)'
                                    : 'rotate(0)',
                                  marginLeft: 22,
                                  marginRight: 10,
                                }}
                              />
                              <CheckboxInputStyled
                                allChecksChecked={
                                  !handleGetAllProductsChecked(
                                    category?.product_category_id
                                  )
                                }
                              >
                                <input
                                  defaultChecked={category?.checked}
                                  name={category?.name}
                                  key={category?.checked}
                                  value={category?.checked}
                                  type="checkbox"
                                  onClick={() => {
                                    handleCheckCategory(
                                      category?.product_category_id,
                                      category?.checked
                                    );
                                  }}
                                />
                              </CheckboxInputStyled>

                              {category?.name}
                            </span>
                          </td>
                          <td>
                            {' '}
                            <InputStyled
                              type="number"
                              defaultValue={category?.average_time}
                              onChange={(e) =>
                                handleChangeCategoryPreparationTime(
                                  category?.product_category_id,
                                  e.target.value
                                )
                              }
                            />{' '}
                            min
                          </td>
                        </tr>

                        {category?.products &&
                          category?.products.map((product) => (
                            <tr
                              style={{
                                display:
                                  (categoryExpanded !== category.product_category_id) && 'none',
                              }}
                            >
                              <td style={{ border: 'none' }}>
                                {' '}
                                <span
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#6c6c6c',
                                    paddingLeft: 55,
                                  }}
                                >
                                  <CheckboxInput
                                    defaultChecked={product.checked}
                                    name={product.name}
                                    key={product.checked}
                                    value={product.checked}
                                    disabled={kdsNotifyWhatsapp}
                                    onClick={() =>
                                      handleCheckProduct(
                                        category.product_category_id,
                                        product.product_id,
                                        product.checked
                                      )
                                    }
                                  />{' '}
                                  {product.name}
                                </span>
                              </td>
                              <td style={{ border: 'none' }}>
                                <InputStyled
                                  defaultValue={product.average_time}
                                  onChange={(e) =>
                                    handleChangeProductPreparationTime(
                                      category.product_category_id,
                                      product.product_id,
                                      e.target.value
                                    )
                                  }
                                  type="number"
                                />{' '}
                                min
                              </td>
                            </tr>
                          ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </StyledTable>
              </TabPane>

              <TabPane tabId={1}>
                <StyledTable>
                  <thead>
                    <tr>
                      <th>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#6c6c6c',
                          }}
                        >
                          {' '}
                          <CheckboxInputStyled
                            allChecksChecked={allComplementsChecked === CheckState.Partial}>
                            <input
                              name="all_selected"
                              checked={allComplementsChecked !== CheckState.Off}
                              onChange={(e) => {
                                updateAll.current = true;

                                if (allComplementsChecked === CheckState.On) {
                                  setAllComplementsChecked(CheckState.Off);
                                } else {
                                  setAllComplementsChecked(CheckState.On);
                                }
                              }}
                              type="checkbox"
                            />
                          </CheckboxInputStyled>
                          Todos os itens
                        </span>
                      </th>
                      <th>Tempo de preparo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arrayComplementCategories.map((category) => (
                      <React.Fragment key={category?.complement_category_id}>
                        <tr>
                          <td>
                            {' '}
                            <span
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                color: '#6c6c6c',
                                fontWeight: 'bold',
                              }}
                            >
                              <FaAngleDown
                                size={16}
                                onClick={() => {
                                  toggleExpandComplementCategory(category?.complement_category_id);
                                }}
                                cursor="pointer"
                                style={{
                                  transition: 'all 0.2s',
                                  transform: complementCategoryExpanded === category?.complement_category_id
                                    ? 'rotate(-90deg)'
                                    : 'rotate(0)',
                                  marginLeft: 22,
                                  marginRight: 10,
                                }}
                              />
                              <CheckboxInputStyled
                                allChecksChecked={
                                  !handleGetAllComplementsChecked(
                                    category?.complement_category_id
                                  )
                                }
                              >
                                <input
                                  defaultChecked={category?.checked}
                                  name={category?.name}
                                  key={category?.checked}
                                  value={category?.checked}
                                  type="checkbox"
                                  onClick={() => {
                                    handleCheckComplementCategory(
                                      category?.complement_category_id,
                                      category?.checked
                                    );
                                  }}
                                />
                              </CheckboxInputStyled>

                              {category?.name}
                            </span>
                          </td>
                          <td>
                            {' '}
                            <InputStyled
                              type="number"
                              defaultValue={category?.average_time}
                              onChange={(e) =>
                                handleChangeComplementCategoryPreparationTime(
                                  category?.complement_category_id,
                                  e.target.value
                                )
                              }
                            />{' '}
                            min
                          </td>
                        </tr>

                        {category?.complements &&
                          category?.complements.map((complement) => (
                            <tr
                              style={{
                                display:
                                  (complementCategoryExpanded !== category.complement_category_id) && 'none',
                              }}
                            >
                              <td style={{ border: 'none' }}>
                                {' '}
                                <span
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#6c6c6c',
                                    paddingLeft: 55,
                                  }}
                                >
                                  <CheckboxInput
                                    defaultChecked={complement.checked}
                                    name={complement.name}
                                    key={complement.checked}
                                    value={complement.checked}
                                    disabled={kdsNotifyWhatsapp}
                                    onClick={() =>
                                      handleCheckComplement(
                                        category.complement_category_id,
                                        complement.complement_id,
                                        complement.checked
                                      )
                                    }
                                  />{' '}
                                  {complement.name}
                                </span>
                              </td>
                              <td style={{ border: 'none' }}>
                                <InputStyled
                                  defaultValue={complement.average_time}
                                  onChange={(e) =>
                                    handleChangeComplementPreparationTime(
                                      category.complement_category_id,
                                      complement.complement_id,
                                      e.target.value
                                    )
                                  }
                                  type="number"
                                />{' '}
                                min
                              </td>
                            </tr>
                          ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </StyledTable>
              </TabPane>
            </TabContent>

          </ScreenTwo>

          <ButtonsArea>
            <PersonalButton
              color="#FF2C3A"
              message="Cancelar"
              style={{
                marginLeft: 0,
              }}
              onClick={() => {
                toggleModalCreateKitchen();
              }}
              type="button"
            />

            <div style={{ display: 'flex' }}>
              <FaCircle color={activeOne ? '#FF2C3A' : '#D9D9D9'} />
              <FaCircle
                color={!activeOne ? '#FF2C3A' : '#D9D9D9'}
                style={{ marginLeft: 8 }}
              />
            </div>

            {!activeOne ? (
              <div style={{ display: 'flex' }}>
                <OutlineButton
                  text="Voltar"
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={toogleActiveOne}
                />
                <PersonalButton
                  color="#0CCAB4"
                  message="Salvar"
                  type="submit"
                />
              </div>
            ) : (
              <PersonalButton color="#0CCAB4" message="Próximo" type="submit" />
            )}
          </ButtonsArea>
        </Form>
      </ReactModal>
    </Card>
  );
}

export default ChooseKitchen;
