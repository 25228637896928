import React, { useRef, useState, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import { Row, Col, ModalBody } from 'reactstrap';
import { format, startOfDay, subHours } from 'date-fns';
import { FiX } from 'react-icons/fi';

import { Header, Container, StyledForm, Title } from './styles';

import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import RadioInput from '~/components/Form/RadioInput';
import PersonalButton from '~/components/Buttons/PersonalButton';

import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';

function NewTicket({ getTickets, toggleAddTicketModal }) {
  const formRef = useRef(null);
  const [discountType, setDiscountType] = useState('percentage');
  const [type, setType] = useState('Desconto (%)');
  const today = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), 'yyyy-MM-dd');
  }, []);
  const [expirationDate, setExpirationDate] = useState(null);

  function changeType(new_discount_type) {
    switch (new_discount_type) {
      case 'percentage':
        setType('Desconto (%)');
        break;
      case 'absolute':
        setType('Desconto (R$)');
        break;
      default:
        break;
    }

    setDiscountType(new_discount_type);
  }

  const [active, setActive] = useState(true);
  const [isPublic, setIsPublic] = useState(true);
  const [limited, setLimited] = useState(false);
  const [expires, setExpires] = useState(false);

  const [ticketLimit, setTicketLimit] = useState(null);

  function changeActive() {
    setActive(!active);
  }

  function changeIsPublic() {
    setIsPublic(!isPublic);
  }

  function changeLimited() {
    setLimited(!limited);
  }

  function changeExpires() {
    setExpires(!expires);
    !expires ? setExpirationDate(`${today}T03:00:00`) : setExpirationDate(null);
  }

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        code: Yup.string().required('O código é obrigatório'),
        discount: discountType !== 'free-shipping'
          ? Yup.string().required('O desconto é obrigatório')
          : Yup.string(),
          maximum_discount :Yup.number()
          .transform((value, originalValue) => originalValue === '' ? null : value) // Transforma string vazia em null
          .nullable() 
          .min(1, 'O desconto deve ser maior que 1 Real')
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      let formattedPrice = 0;
      let distance_limit = null;

      if (discountType === 'free-shipping') {
        data.distance_limit = data.distance_limit.trim();

        if (data.distance_limit) {
          distance_limit = parseFloat(data.distance_limit.replace(',', '.'));
        }
      } else if (discountType === 'percentage') {
        formattedPrice = parseFloat(data.discount.replace(',', '.')) / 100;
      } else {
        formattedPrice = parseFloat(data.discount.replace(',', '.'));
      }

      const dataToSend = {
        currency: 'BRL',
        name: data.name,
        code: data.code,
        discount_type: discountType,
        discount: formattedPrice,
        limit_date: expirationDate,
        is_active: active,
        is_public: isPublic,
        description: '',
        title: '',
        distance_limit,
      };

      if (data.minimum_price !== '') {
        dataToSend.minimum_price = parseFloat(
          data.minimum_price.replace(',', '.')
        ).toFixed(2);
      }

      if (data.maximum_discount !== '') {
        dataToSend.maximum_discount = parseFloat(
          data.maximum_discount.replace(',', '.')
        ).toFixed(2);
      }

      if (data.limit_amount !== '') {
        dataToSend.limit_amount = data.limit_amount;
      }

      if (ticketLimit !== null) {
        dataToSend.buyer_limit_buy = ticketLimit;
      }

      await api.post('/restaurants/discount-coupons', dataToSend);

      toast.success('Cupom cadastrado com sucesso!');
      getTickets();
      toggleAddTicketModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else if (error.response.data.errorType === "coupon_already_exists") {
        toast.error("Já existe um cupom cadastrado com esse código.")
      } else {
        // console.tron.log(error);
        toast.error(`Erro ao cadastrar cupom!`);
      }
    }
  }

  return (
    <ModalBody>
      <Header>
        <Title>Cadastrar cupons 1</Title>

        <FiX
          size={22}
          color="#FF2129"
          cursor="pointer"
          strokeWidth={3}
          onClick={() => toggleAddTicketModal()}
        />
      </Header>
      <Container>
        <Row>
          <Col md="12 ml-auto mr-auto">
            <StyledForm ref={formRef} onSubmit={handleSubmit}>
              <Row>
                <Col md="6">
                  <RadioInput
                    label="Cupom em Porcentagem"
                    name="discount_type"
                    value="percentage"
                    labelCss={{ fontWeight: 'normal' }}
                    onChange={() => changeType('percentage')}
                    defaultChecked
                  />
                  <RadioInput
                    label="Cupom em Reais"
                    name="discount_type"
                    value="absolute"
                    labelCss={{ fontWeight: 'normal' }}
                    onChange={() => changeType('absolute')}
                  />
                  <RadioInput
                    label="Frete Grátis"
                    name="discount_type"
                    value="free-shipping"
                    labelCss={{ fontWeight: 'normal' }}
                    onChange={() => changeType('free-shipping')}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  {discountType !== 'free-shipping'
                    ? <Input label={type} name="discount" type="text" />
                    : <Input label="Limite de Distância (km)" name="distance_limit" type="text" />
                  }

                  <Input
                    label="Nome do Cupom"
                    name="name"
                    type="text"
                    placeholder="60% OFF"
                  />

                  <Input
                    label="Código"
                    name="code"
                    type="text"
                    placeholder="60OFF"
                  />
                </Col>
                <Col md="6">
                  <Input
                    label="Valor Mínimo do Pedido"
                    name="minimum_price"
                    type="text"
                    placeholder=""
                  />

                  <Input
                    name="limit_amount"
                    type="text"
                    label="Limite de vezes que pode ser usado"
                  />

                  <Input
                    label="Desconto Máximo"
                    name="maximum_discount"
                    type="text"
                    disabled={discountType === 'free-shipping'}
                    placeholder=""
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <CheckboxInput
                    label="Limitar vezes que o mesmo cliente pode usar o cupom"
                    name="userLimit"
                    onChange={changeLimited}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  {' '}
                  {limited && (
                    <Input
                      name="buyer_limit_buy"
                      type="number"
                      step="1"
                      placeholder="Número Limite de vezes"
                      onChange={(e) => setTicketLimit(e.target.value)}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxInput
                    label="Definir data de expiração"
                    name="expiration"
                    onChange={changeExpires}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  {expires && (
                    <Input
                      id="date"
                      label="Expira em"
                      type="date"
                      name="finalDate"
                      onChange={(e) =>
                        setExpirationDate(`${e.target.value}T03:00:00`)
                      }
                      defaultValue={today}
                      className="data"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxInput
                    label="Ativo"
                    name="active"
                    defaultChecked={active}
                    onChange={changeActive}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxInput
                    label="Aparecer na lista de cupons disponíveis"
                    name="is_public"
                    defaultChecked={isPublic}
                    onChange={changeIsPublic}
                  />
                </Col>
              </Row>
              <div style={{ float: 'right' }}>
                <PersonalButton
                  color="#0CCAB4"
                  message="Salvar"
                  type="submit"
                />
              </div>
            </StyledForm>
          </Col>
        </Row>
      </Container>
    </ModalBody>
  );
}

export default NewTicket;
