import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { v4 } from 'uuid';

import produce from 'immer';
import { FaAngleDown } from 'react-icons/fa';
import OrderComplementContext from './context';

import {
  Container,
  ComplementsTable,
  ComplementItemHeaderTitle,
  SetSliceSizeButton,
} from './styles';
import api from '~/services/api';

import EditComplement from './Edit';
import { useAuth } from '~/context/AuthContext';
import ComplementsRow from './ComplementsRow';
import ReactModal from 'react-modal';

function Complements({
  category,
  getCategories,
  count,
  setCount,
  menewProducts,
  allCategories,
  setProductsCategories,
  productsCategories,
  setCategories,
  complementCategories,

  fiscalGroups,
}) {
  const { user: restaurant } = useAuth();

  const [modalEditComplement, setModalEditComplement] = useState(false);
  const [modalEditComplementData, setModalEditComplementData] = useState();

  function toggleModalEditComplement() {
    setModalEditComplement(!modalEditComplement);
  }

  function handleChangeEditComplement(complement) {
    toggleModalEditComplement();
    setModalEditComplementData(complement);
  }

  // const getComplements = useCallback(async () => {
  //   try {
  //     const response = await api.get(
  //       `/restaurants/product/complement-category/${category.id}`
  //     );
  //     if (category.is_ifood) {
  //       const filtered = response.data.complements.filter(
  //         (comp) => comp.is_ifood === true
  //       );
  //       setComplementsData(filtered);
  //     } else {
  //       setComplementsData(response.data.complements);
  //     }
  //   } catch (err) {
  //     toast.error('Falha ao carregar dados');
  //   }
  // }, [category]);

  // useEffect(() => {
  //   try {
  //     getComplements();
  //   } catch (err) {
  //     // console.tron.log(err);
  //   }
  // }, [getComplements, count, setCount]);

  async function handleDelete(id) {
    try {
      if (
        window.confirm(
          'Tem certeza que deseja deletar este Complemento? Lembre-se que ele será excluído de todas as categorias. Para deletar desta categoria, clique em "Adicionar/ Remover complementos já existentes"'
        )
      ) {
        await api.delete(`/restaurants/product/complements/${id}`);
        localStorage.setItem('@gddashboard:isMenuUpdated', true);

        const newCategories = [...allCategories];

        const categoryFind = newCategories.findIndex(
          (item) => item.id === category.id
        );

        if (categoryFind !== -1) {
          const complementsFiltrered = newCategories[
            categoryFind
          ].complements.filter((item) => item.id !== id);

          newCategories[categoryFind].complements = [
            ...(newCategories[categoryFind].complements = complementsFiltrered),
          ];
        }

        setCategories(newCategories);

        toast.success('Complemento deletado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar complemento');
    }
  }

  async function handleChangeComplement(value, event, idx) {
    try {
      const [type, compId] = idx.split('-');
      const id = Number(compId);

      const data = {};

      if (type === 'pdv') {
        data.available = value;

        // const newCategories = [...productsCategories];

        // newCategories.map((cat, idix) =>
        //   cat.products.map((prod, indx) =>
        //     prod?.complement_categories.map((com_cat, indice) =>
        //       com_cat.complements.map((item, idex) => {
        //         if (item.id === id) {
        //           newCategories[idix].products[indx].complement_categories[
        //             indice
        //           ].complements[idex].available = value;
        //         }
        //       })
        //     )
        //   )
        // );

        // setProductsCategories(newCategories);

        const newComplementsCategories = [...allCategories];

        newComplementsCategories.map((cat, idix) =>
          cat.complements.map((compl, idex) => {
            if (compl.id === id) {
              newComplementsCategories[idix].complements[idex].available =
                value;
            }
          })
        );

        setCategories(newComplementsCategories);
      } else {
        data.available_in_delivery = value;

        // const newCategories = [...productsCategories];

        // newCategories.map((cat, idix) =>
        //   cat.products.map((prod, indx) =>
        //     prod?.complement_categories.map((com_cat, indice) =>
        //       com_cat.complements.map((item, idex) => {
        //         if (item.id === id) {
        //           newCategories[idix].products[indx].complement_categories[
        //             indice
        //           ].complements[idex].available_in_delivery = value;
        //         }
        //       })
        //     )
        //   )
        // );

        // setProductsCategories(newCategories);

        const newComplementsCategories = [...allCategories];

        newComplementsCategories.map((cat, idix) =>
          cat.complements.map((compl, idex) => {
            if (compl.id === id) {
              newComplementsCategories[idix].complements[
                idex
              ].available_in_delivery = value;
            }
          })
        );

        setCategories(newComplementsCategories);
      }

      await api.put(`/restaurants/product/complements/${id}`, data);

      const aleatoryId = v4();

      setCount(aleatoryId);

      // getComplements();
      localStorage.setItem('@gddashboard:isMenuUpdated', true);
    } catch (error) {
      toast.error('Erro ao ativar/ desativar complemento');
      console.log(error.message);
    }
  }

  async function onDrop(from_complement_id, to_complement_id, from, to) {
    try {
      await api.put('/restaurants/complements-custom-order', {
        from_complement_id,
        to_position: to,
        category_id: category.id,
      });

      toast.success('Posição Atualizada com sucesso!');
    } catch (err) {
      toast.error('Erro ao atualizar posição da categoria');
    }
  }

  async function move(from_complement_id, to_complement_id, from, to) {
    try {
      // await api.put('/restaurants/complements-custom-order', {
      //   from_complement_id,
      //   to_position: to,
      //   category_id: category.id,
      // });

      const next = produce(category?.complements, (draft) => {
        const dragged = draft[from];

        dragged.custom_order = draft[to].custom_order;

        if (to > from) {
          for (let i = from + 1; i <= to; i++) {
            draft[i].custom_order -= 1;
          }
        } else {
          for (let i = to; i < from; i++) {
            draft[i].custom_order += 1;
          }
        }

        draft.splice(from, 1);
        draft.splice(to, 0, dragged);
      });

      // setComplementsData(next);

      const newComplementsCategories = [...allCategories];

      newComplementsCategories.map((cat, idix) => {
        if (cat.id === category.id) {
          newComplementsCategories[idix].complements = next;
        }
      });

      setCategories(newComplementsCategories);
    } catch (err) {
      toast.error('Erro ao atualizar posição da categoria');
    }
  }
  const [sliceSize, setSliceSize] = useState(5);
  const [angleChanged, setAngleChange] = useState(false);

  function handleSetSliceSize() {
    setSliceSize(sliceSize + 5);
    setAngleChange(true);
  }
  return (
    <OrderComplementContext.Provider
      value={{ complementsData: category?.complements, move, onDrop }}
    >
      <Container>
        <ComplementsTable>
          <thead>
            <tr>
              <th>Imagem</th>
              <th>Complemento</th>

              {!restaurant.only_delivery && (
                <ComplementItemHeaderTitle>
                  <th>PDV</th>
                </ComplementItemHeaderTitle>
              )}
              {restaurant.delivery_info.is_delivery_allowed && (
                <th>Delivery</th>
              )}

              <th>
                {!restaurant.only_delivery ? '$ Presencial' : '$ Delivery'}
              </th>
              {!restaurant.only_delivery &&
                restaurant.delivery_info.is_delivery_allowed && (
                  <th>$ Delivery</th>
                )}

              <th>Limite</th>

              <th />
            </tr>
          </thead>
          <tbody>
            {category?.complements &&
              category?.complements.length > 0 &&
              category?.complements
                .slice(0, sliceSize)
                .filter(item => item.available_multistore && (!category.is_ifood || (category.is_ifood && item.is_ifood)))
                .map((item, index) => (
                  <ComplementsRow
                    complement={item}
                    handleDelete={handleDelete}
                    handleChangeComplement={handleChangeComplement}
                    handleChangeEditComplement={handleChangeEditComplement}
                    index={index}
                    user={restaurant}
                    complementsData={category?.complements}
                    setProductsCategories={setProductsCategories}
                    productsCategories={productsCategories}
                    category={category}
                    setCategories={setCategories}
                    categories={allCategories}
                  />
                ))}
          </tbody>

          <ReactModal
            onRequestClose={toggleModalEditComplement}
            isOpen={modalEditComplement}
            className="modal-content-takeat-without-overflow"
            closeTimeoutMS={500}
          >
            <EditComplement
              complement={modalEditComplementData}
              getCategories={getCategories}
              toggleModal={toggleModalEditComplement}
              getComplements={false}
              setCount={setCount}
              menewProducts={menewProducts}
              allCategories={allCategories}
              setModalEditComplementData={setModalEditComplementData}
              setProductsCategories={setProductsCategories}
              productsCategories={productsCategories}
              categories={complementCategories}
              setCategories={setCategories}
              fiscalGroups={fiscalGroups}
              category={category}
            />
          </ReactModal>
        </ComplementsTable>

        {sliceSize < category?.complements.length && (
          <div>
            {' '}
            <SetSliceSizeButton type="button" onClick={handleSetSliceSize}>
              <FaAngleDown
                style={{
                  transition: 'all 0.2s',
                  transform: !angleChanged ? 'rotate(-90deg)' : 'rotate(0)',
                }}
              />{' '}
              Ver mais complementos
            </SetSliceSizeButton>
          </div>
        )}
      </Container>
    </OrderComplementContext.Provider>
  );
}

export default Complements;
