import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';

import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';

import * as Yup from 'yup';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import {
  ActionButton,
  Checkbox,
  DefaultInput,
  DefaultSelect,
  IconInput,
} from 'ui-kit-takeat';

import {
  FaCheck,
  FaExclamationTriangle,
  FaTimes,
  FaTrash,
} from 'react-icons/fa';

import {
  Container,
  BillExplanation,
  InformationDiv,
  Total,
  Paid,
  PaymentDiv,
  FinalDiv,
  AddPaymentForm,
  ButtonStyled,
  PaymentMethodButton,
  TotalSpan,
  PaymentsDiv,
} from './styles';

import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';
import PersonalButton from '../../Buttons/PersonalButton';

import { useAuth } from '~/context/AuthContext';

import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';

import formatValue from '~/utils/formatValue';
import { useOrder } from '~/context/orderContext';
import { useClube } from '~/context/ClubeContext';

export default function PaymentFormDelivery({
  payments_session,
  item,
  getPayments,
  setPaymentsState,
  discount,
  discountObs,
  setDiscount,
  setDiscountObs,
  setDiscountObservation,
  applyDiscount,
  setApplyDiscount,
  adminPassword,
  setAdminPassword,
  favoritePaymentMethods,
  allPaymentMethods,
  paymentMethods,

}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { setReadyBaskets } = useOrder();
  const { rescues, saveSendedSession } = useClube();
  const [isDeleting, setIsDeleting] = useState([]);
  const toggleDrop = () => setDropdownOpen((prevState) => !prevState);
  const {
    user,
    setToastUpdateSystemMessage3,
    toastUpdateSystemMessage3,
    restaurantDiscountObs,
  } = useAuth();

  const [metodo, setMetodo] = useState('Outros');
  const [discountPercent, setDiscountPercent] = useState(
    (
      (discount /
        (item.old_total_price
          ? parseFloat(item.old_total_price) +
          parseFloat(item.delivery_tax_price)
          : item.total_delivery_price)) *
      100
    ).toFixed(2) || ''
  );

  const [discountText, setDiscountText] = useState(false);
  const [discountObsOptions, setDiscountObsOptions] = useState([]);
  const discountDisabled =
    item.ifood_discount > 0 || item.merchant_discount > 0;

  const [priceToShow, setPriceToShow] = useState(
    item.old_total_price
      ? (
        parseFloat(item.old_total_price) +
        parseFloat(item.delivery_tax_price) -
        (discount && applyDiscount ? parseFloat(discount) : 0)
      ).toFixed(2)
      : (
        parseFloat(item.total_delivery_price) -
        (discount && applyDiscount ? parseFloat(discount) : 0)
      ).toFixed(2)
  );
  const [paymentOption, setPaymentOption] = useState(false);
  const [paymentMethodsState, setPaymentMethodsState] = useState([]);

  const [paid, setPaid] = useState(0);
  const [left, setLeft] = useState(0);
  const [value, setValue] = useState(0);
  const [isModalErrorUserPdvOpened, setIsModalUserPdvOpened] = useState(false);

  const toggleModalErrorUserPdv = useCallback(() => {
    setIsModalUserPdvOpened(!isModalErrorUserPdvOpened);
  }, [isModalErrorUserPdvOpened]);

  const getPaymentsSession = useCallback(async () => {
    let total = 0;
    payments_session.forEach((payment) => {
      total += parseFloat(payment.value);
    });
    setPaid(total.toFixed(2).toString());

    const localLeft = item.old_total_price
      ? parseFloat(item.old_total_price) +
      parseFloat(item.delivery_tax_price) -
      total
      : item.total_delivery_price - total;

    setLeft(
      (parseFloat(localLeft) - (discount ? parseFloat(discount) : 0)).toFixed(2)
    );
  }, [item, payments_session]);

  const deletePayments = useCallback(
    async (id) => {
      // if (window.confirm('Tem certeza que deseja remover esse pagamento?')) {

      try {
        await api.delete(`restaurants/payments/${id}`);

        const payments_bill_new = payments_session.filter(
          (payment) => payment.id !== id
        );

        const payments_bill_find = payments_session.filter(
          (payment) => payment.id === id
        );

        setPaymentsState(payments_bill_new);

        setValue((Number(left) + Number(payments_bill_find[0].value)).toFixed(2));

        const newTotalPayments = payments_bill_new.reduce((acc, curr) => {
          return acc + Number(curr.value)
        }, 0)

        setReadyBaskets(old => {
          const baskets = [...old]
          const basketFind = baskets.findIndex(bsk => bsk.basket.id === item.basket.id)

          if (basketFind !== -1) {
            baskets[basketFind].is_paid = newTotalPayments < item.total_delivery_price ? false : true
          }

          return baskets
        })


        toast.success('Pagamento removido com sucesso!');
      } catch (error) {
        if (error.response.data.errorType === "credit_register_already_finished") {
          toast.error('Essa conta a prazo já foi quitada, não pode ser removida.');
        } else {
          toast.error('Erro ao deletar pagamento');
        }
      }

    },
    // },
    [payments_session, setPaymentsState, left]
  );

  // Prazo a.k.a. credit registers
  const [modalRegisterCreditPayment, setModalRegisterCreditPayment] =
    useState(false);
  const [creditRegisterOptions, setCreditRegisterOptions] = useState([]);
  const [creditRegisterSelected, setCreditRegisterSelected] = useState(null);

  const getCreditRegisters = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/credit-register?details=true'
      );

      const creditRegisterOptions = response.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
          total_limit: parseFloat(item.total_limit),
          // balance: Math.abs(parseFloat(item.balance)),
          limit_available:
            parseFloat(item.total_limit) - Math.abs(parseFloat(item.balance)),
        };
      });

      setCreditRegisterOptions(creditRegisterOptions);
    } catch (error) {
      toast.error('Erro ao baixar contas a prazo');
    }
  }, []);

  function handleSetCreditRegisterSelected(value) {
    const register = creditRegisterOptions.find((item) => item.value === value);

    setCreditRegisterSelected(register);
  }

  function toggleModalInsertCreditRegisterPayment() {
    if (!modalRegisterCreditPayment) {
      getCreditRegisters();
    }

    setModalRegisterCreditPayment(!modalRegisterCreditPayment);
  }

  const submitRegisterCreditPayment = useCallback(
    async (data) => {
      const session = item;

      if (!session || !paymentOption.id) {
        return;
      }

      try {
        await api.post('/restaurants/credit-register/verify', {
          value: Number(value),
          credit_register_id: data.credit_register_id,
        });

        setInsertingPayment(true);

        const response = await api.post('restaurants/payments', {
          payment_value: Number(value),
          table_session_id: session.session_id,
          payment_method_id: paymentOption.id,
          credit_register_id: data.credit_register_id,
          rescues
        });

        const newPayment = {
          value: Number(response.data.payment_value).toFixed(2),
          payment_method_id: response.data.payment_method_id,
          id: response.data.id,
          removable: true,
          keyword: response.data.payment_method.keyword,
        };

        setPaymentsState([...payments_session, newPayment]);

        if (response.data.clube) {
          saveSendedSession(response.data.clube);
        }

        if (left === 0) {
          const total = session.total_delivery_price;
          setValue((total - Number(value)).toFixed(2));
        } else {
          setValue((left - Number(value)).toFixed(2));
        }

        const newPayments = [...payments_session, newPayment]
        const newTotalPayments = newPayments.reduce((acc, curr) => {
          return acc + Number(curr.value)
        }, 0)

        setReadyBaskets(old => {
          const baskets = [...old]
          const basketFind = baskets.findIndex(bsk => bsk.basket.id === item.basket.id)

          if (basketFind !== -1) {
            baskets[basketFind].is_paid = newTotalPayments < item.total_delivery_price ? false : true
          }

          return baskets
        })
        toast.success('Pagamento inserido com sucesso!');


        setInsertingPayment(false);
        setModalRegisterCreditPayment(false);
      } catch (error) {
        if (!error.response.ok) {
          switch (error.response.data.errorType) {
            case 'cashier_opening_not_found':
              toast.error(
                'Não foi encontrado caixa aberto. Favor abrir seu caixa.'
              );
              break;
            case 'credit_register_expired':
              toast.error('Conta a prazo com tempo expirado.');
              break;
            case 'credit_register_limit_reached':
              toast.error('Limite insuficiente nesta conta a prazo.');
              break;
            default:
              setInsertingPayment(false);
              toast.error(error.response.data.message || 'Selecione o método de pagamento.');
              break;
          }
        }
      }
    },
    [payments_session, setPaymentsState, paymentOption.id, left, item, value, rescues]
  );

  const [insertingPayment, setInsertingPayment] = useState(false);
  const newPayments = useCallback(async () => {
    if (paymentOption.id && paymentOption.label === 'Prazo') {
      toggleModalInsertCreditRegisterPayment();
      return;
    }

    try {
      const response = await api.post('restaurants/payments', {
        payment_value: value,
        table_session_id: item?.session_id,
        payment_method_id: paymentOption.id,
        rescues
      });

      const newPayment = {
        value: Number(response.data.payment_value).toFixed(2),
        payment_method_id: response.data.payment_method_id,
        id: response.data.id,
        removable: true,
        keyword: response.data.payment_method.keyword,
      };

      setPaymentsState([...payments_session, newPayment]);

      if (response.data.clube) {
        saveSendedSession(response.data.clube);
      }

      if (left === 0) {
        setValue((item?.total_delivery_price - value).toFixed(2));
      } else if (left < 0) {
        setValue((left - value).toFixed(2));
      } else {
        setValue((left - value).toFixed(2));
      }
      setInsertingPayment(false);

      const newPayments = [...payments_session, newPayment]
      const newTotalPayments = newPayments.reduce((acc, curr) => {
        return acc + Number(curr.value)
      }, 0)

      setReadyBaskets(old => {
        const baskets = [...old]
        const basketFind = baskets.findIndex(bsk => bsk.basket.id === item.basket.id)

        if (basketFind !== -1) {
          baskets[basketFind].is_paid = newTotalPayments < item.total_delivery_price ? false : true
        }
        return baskets
      })


      toast.success('Pagamento inserido com sucesso!');
    } catch (error) {
      setInsertingPayment(false);
      toast.error(error?.response?.data?.message || 'Selecione o método de pagamento.');
      console.log(error)
    }
  }, [paymentOption, item, payments_session, setPaymentsState, left, rescues]);

  useEffect(() => {
    let total = 0;
    payments_session.forEach((payment) => {
      total += parseFloat(payment.value);
    });

    const totalValue = item.old_total_price
      ? parseFloat(item.old_total_price) +
      parseFloat(item.delivery_tax_price) -
      (discount && applyDiscount ? discount : 0) -
      total
      : item.total_delivery_price -
      total -
      (discount && applyDiscount ? parseFloat(discount) : 0);

    setValue(totalValue.toFixed(2));
  }, [item, payments_session]);

  // useEffect(() => {
  //   try {
  //     getPayments();
  //   } catch (error) {
  //     toast.error('Erro ao carregar informações');
  //   }
  // }, [getPayments]);

  async function setMetodoAndPaymentOption(payment) {
    setInsertingPayment(true);
    setMetodo(payment.label);
    setPaymentOption({ id: payment.id, label: payment.label });
  }

  useEffect(() => {
    getPaymentsSession();
  }, [getPaymentsSession]);

  const [hasOpenedBox, setHasOpenedBox] = useState(false);
  const openingBoxRef = useRef();

  const toggleModalHasOpenedBoxMessage = useCallback(() => {
    setHasOpenedBox(!hasOpenedBox);
  }, [hasOpenedBox]);

  const verifyOpeningBox = useCallback(
    async (data) => {
      try {
        await api.get('/restaurants/cashier-opening-verify');

        if (paymentOption.id) {
          newPayments(data);
        }
      } catch (err) {
        if (err.response.data.errorType === 'cashier_opening_not_found') {
          toggleModalHasOpenedBoxMessage();
          setInsertingPayment(false);
        } else {
          toast.error('Erro ao carregar valores totais');
        }
      }
    },
    [toggleModalHasOpenedBoxMessage, newPayments]
  );

  const [isModalOnlineDepositsOpened, setIsModalOnlineDepositsOpened] =
    useState(false);
  const [onlineDeposits, setOnlineDeposits] = useState([]);

  const toggleOnlineDepositisModal = useCallback(() => {
    setIsModalOnlineDepositsOpened(!isModalOnlineDepositsOpened);
  }, [isModalOnlineDepositsOpened]);

  const openingBox = useCallback(
    async (data) => {
      if (!user?.is_pdv) {
        try {
          if (openingBoxRef?.current) {
            openingBoxRef.current.setErrors({});
          }

          const schema = Yup.object().shape({
            opening_value: Yup.string().required('Valor obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const response = await api.post('/restaurants/cashier-opening', {
            initial_value: data.opening_value,
          });

          const { online_deposits } = response.data;
          setOnlineDeposits(online_deposits);

          if (online_deposits.length > 0) {
            toggleOnlineDepositisModal();
          }

          toggleModalHasOpenedBoxMessage();

          toast.success('Caixa aberto com sucesso.');
        } catch (error) {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            if (openingBoxRef?.current) {
              openingBoxRef.current.setErrors(errors);
            }
          } else if (error.response.data.errorType === 'cashier_already_open') {
            toast.error('Caixa já aberto');
          } else {
            toast.error('Erro ao abrir o caixa');
          }
        }
      } else {
        toggleModalErrorUserPdv();
      }
    },
    [
      toggleModalHasOpenedBoxMessage,
      user,
      toggleModalErrorUserPdv,
      toggleOnlineDepositisModal,
    ]
  );
  const [favoriteMethodsState, setFavoriteMethodsState] = useState([]);
  const [allPaymentMethodsState, setAllPaymentMethodsState] = useState([]);

  const getPaymentMethods = useCallback(async () => {

    if (item.basket.ifood_id === null) {
      const parsedCompleteData = allPaymentMethods.map((data) => ({
        id: data.id,
        label: data.name,
        available: item?.with_withdrawal
          ? data.withdrawal_accepts
          : data.delivery_accepts,
        keyword: data.keyword,
        is_ifood: data.is_ifood,
      }));

      setAllPaymentMethodsState(parsedCompleteData.filter((p) => !p.is_ifood));

      const parsedDataFav = favoritePaymentMethods.map((data) => ({
        id: data.id,
        label: data.name,
        available: item?.with_withdrawal
          ? data.withdrawal_accepts
          : data.delivery_accepts,
        keyword: data.keyword,
        is_ifood: data.is_ifood,
      }));

      setFavoriteMethodsState(parsedDataFav.filter((p) => !p.is_ifood));

      const parsedData = paymentMethods.map((data) => ({
        id: data.id,
        label: data.name,
        available: item?.with_withdrawal
          ? data.withdrawal_accepts
          : data.delivery_accepts,
        keyword: data.keyword,
        is_ifood: data.is_ifood,
      }));
      setPaymentMethodsState(parsedData.filter((p) => !p.is_ifood));
    } else {
      const parsedCompleteData = allPaymentMethods.map((data) => ({
        id: data.id,
        label: data.name,
        available: item?.with_withdrawal
          ? data.withdrawal_accepts
          : data.delivery_accepts,
        keyword: data.keyword,
        is_ifood: data.is_ifood,
      }));

      setAllPaymentMethodsState(parsedCompleteData.filter((p) => !p.is_ifood));

      const parsedDataFav = favoritePaymentMethods.map((data) => ({
        id: data.id,
        label: data.name,
        available: item?.with_withdrawal
          ? data.withdrawal_accepts
          : data.delivery_accepts,
        keyword: data.keyword,
        is_ifood: data.is_ifood,
      }));

      setFavoriteMethodsState(parsedDataFav);

      const parsedData = paymentMethods.map((data) => ({
        id: data.id,
        label: data.name,
        available: item?.with_withdrawal
          ? data.withdrawal_accepts
          : data.delivery_accepts,
        keyword: data.keyword,
        is_ifood: data.is_ifood,
      }));
      setPaymentMethodsState(parsedData);
    }
  }, [item]);

  const handleChangeDiscount = (target) => {
    const targetValue = target.value.replace(/(R|\$|%)/g, '').replace(',', '.');

    const price = item?.old_total_price
      ? parseFloat(item.old_total_price) + parseFloat(item.delivery_tax_price)
      : item?.total_delivery_price;

    if (targetValue.length < 1) {
      setDiscount('');
      setDiscountPercent('');
      setPriceToShow(price);
      setLeft(price - paid);
      setValue(price - paid);
    } else if (target.name === 'flat_discount') {
      setDiscount(targetValue);
      setDiscountPercent(
        ((parseFloat(targetValue.replace(',', '.')) / price) * 100).toFixed(
          2
        ) || '-'
      );
      setPriceToShow(
        (price - parseFloat(targetValue.replace(',', '.'))).toFixed(2)
      );
      setLeft(
        (price - parseFloat(targetValue.replace(',', '.')) - paid).toFixed(2)
      );
      setValue(
        (price - parseFloat(targetValue.replace(',', '.')) - paid).toFixed(2)
      );
    } else {
      setDiscount(
        ((parseFloat(targetValue.replace(',', '.')) * price) / 100).toFixed(2)
      );
      setDiscountPercent(targetValue);
      setPriceToShow(
        (
          price -
          (parseFloat(targetValue.replace(',', '.')) * price) / 100
        ).toFixed(2)
      );
      setLeft(
        (
          price -
          (parseFloat(targetValue.replace(',', '.')) * price) / 100 -
          paid
        ).toFixed(2)
      );
      setValue(
        (
          price -
          (parseFloat(targetValue.replace(',', '.')) * price) / 100 -
          paid
        ).toFixed(2)
      );
    }
  };

  useEffect(() => {
    const obs_options = restaurantDiscountObs?.map((disc) => ({
      value: disc.id,
      label: disc.obs,
    }));
    setDiscountObsOptions([
      ...(obs_options || []),
      { value: 'other', label: 'Outro' },
    ]);
    setDiscountObs(item.discount_obs);
  }, [restaurantDiscountObs]);

  useEffect(() => {
    getPaymentMethods()
  }, [getPaymentMethods])

  useEffect(() => {
    if (paymentOption) {
      verifyOpeningBox();
    }
  }, [paymentOption]);

  const [toastMessage, setToastMessage] = useState(
    toastUpdateSystemMessage3.toString() === 'true'
  );

  function toggleToast() {
    setToastUpdateSystemMessage3(false);
    setToastMessage(false);
  }

  useEffect(() => {
    if (
      !discountObsOptions.find((o) => o.label === discountObs) &&
      discountObs?.length > 0
    ) {
      setDiscountText(true);
    }

  }, []);

  const [methodsFiltered, setMethodsFiltered] = useState([])

  const prohibitedPaymentMethods = [
    238, //  Resgate Clube
    572, //  Pagamento Online iFood
    2548, // Cartão de Crédito Online
    301, //  Pagamento Online Takeat
    50629 //Cupom iFood
  ]

  function handleFilterMethods(method) {
    if (method.length === 0) {
      setMethodsFiltered([]);
      return;
    }

    const paymentsMethodsFiltered = paymentMethodsState.filter(
      (payment) =>
        payment.available &&
        !prohibitedPaymentMethods.includes(payment.id)
    );

    if (method.length >= 2) {
      const productsFound = paymentsMethodsFiltered.filter((item) =>
        item.label
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            method
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      );

      setMethodsFiltered(productsFound);
    }
  }

  return (
    <Container>
      <Modal
        isOpen={isModalOnlineDepositsOpened}
        toggle={toggleOnlineDepositisModal}
      >
        <ModalBody>
          <strong>
            Os valores a seguir, que foram pagamentos online feitos enquanto seu
            caixa estava fechado, entrarão no caixa que você está abrindo:
          </strong>
          {onlineDeposits &&
            onlineDeposits.length > 0 &&
            onlineDeposits
              .filter((dep) => dep.total > 0)
              .map((deposit) => (
                <p>
                  {deposit.description} - R$ {deposit.total.toFixed(2)}
                </p>
              ))}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              toggleOnlineDepositisModal();
              setOnlineDeposits([]);
            }}
          >
            Ok, entendi
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={hasOpenedBox} toggle={toggleModalHasOpenedBoxMessage}>
        <Form onSubmit={openingBox} ref={openingBoxRef}>
          <ModalBody>
            <FaExclamationTriangle color="darkOrange" size={32} />
            <h3>Você precisa abrir seu caixa para inserir pagamentos.</h3>

            <Input
              name="opening_value"
              label="Valor inicial no caixa (Dinheiro)"
              type="number"
              step="0.01"
              min="0"
            />
          </ModalBody>
          <ModalFooter>
            <ButtonStyled
              operationType="withdrawal"
              type="button"
              onClick={toggleModalHasOpenedBoxMessage}
            >
              Cancelar
            </ButtonStyled>
            <ButtonStyled operationType="deposit" type="submit">
              Abrir caixa
            </ButtonStyled>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalErrorUserPdvOpened}
        toggle={toggleModalErrorUserPdv}
      >
        <ModalBody>
          Seu perfil de usuário não possui permissão de abrir caixa. Solicite ao
          seu administrador
        </ModalBody>
        <ModalFooter>
          <div style={{ width: '100%' }}>
            <ButtonStyled
              operationType="deposit"
              type="button"
              onClick={toggleModalErrorUserPdv}
              style={{ float: 'right' }}
            >
              Entendi, irei solicitar.
            </ButtonStyled>
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalRegisterCreditPayment}
        toggle={toggleModalInsertCreditRegisterPayment}
      >
        <Form onSubmit={submitRegisterCreditPayment}>
          <ModalBody>
            <p style={{ fontWeight: 'bold' }}>Fiado</p>
            <Select
              options={creditRegisterOptions}
              name="credit_register_id"
              label="Selecione a conta a prazo"
              placeholder="Selecione ..."
              onChange={(e) => handleSetCreditRegisterSelected(e.value)}
            />
          </ModalBody>

          <ModalFooter
            style={{ display: 'flex', flexjustifyContent: 'space-between' }}
          >
            {creditRegisterSelected &&
              creditRegisterSelected.total_limit > 0 && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p
                    style={{
                      color:
                        creditRegisterSelected.limit_available > 0
                          ? '#FF2C3A'
                          : '#3BD2C1',
                    }}
                  >
                    Limite disponível: R${' '}
                    {creditRegisterSelected.limit_available.toFixed(2)}
                  </p>

                  {creditRegisterSelected.limit_available <= 0 && (
                    <p style={{ color: '#FF2C3A', fontWeight: 'bold' }}>
                      LIMITE INSUFICIENTE
                    </p>
                  )}
                </div>
              )}

            <PersonalButton
              type="submit"
              message="Inserir pagamento"
              color="#2ec9b7"
            />
          </ModalFooter>
        </Form>
      </Modal>

      <BillExplanation>
        <h5>Adicionar Pagamento(s) </h5>
        <h6>
          Aqui você adiciona o(s) pagamento(s) já feitos da comanda e confere
          quanto falta a ser pago.
        </h6>
      </BillExplanation>

      <Form>
        <Checkbox
          label="Aplicar desconto"
          disabled={discountDisabled}
          checked={applyDiscount}
          onClick={(e) => {
            setApplyDiscount((state) => !state);
            if (!e) {
              setLeft(
                (state) =>
                  parseFloat(state) + (discount ? parseFloat(discount) : 0)
              );
              setValue(
                (state) =>
                  parseFloat(state) + (discount ? parseFloat(discount) : 0)
              );
              setPriceToShow(
                (state) =>
                  parseFloat(state) + (discount ? parseFloat(discount) : 0)
              );
            } else {
              setLeft(
                (state) =>
                  parseFloat(state) - (discount ? parseFloat(discount) : 0)
              );
              setValue(
                (state) =>
                  parseFloat(state) - (discount ? parseFloat(discount) : 0)
              );
              setPriceToShow(
                (state) =>
                  parseFloat(state) - (discount ? parseFloat(discount) : 0)
              );
            }
          }}
        />
      </Form>

      {applyDiscount && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <span style={{ color: 'grey', lineHeight: '14px' }}>Desconto</span>
          <div
            style={{
              display: 'flex',
              position: 'relative',
              gap: 10,
            }}
          >
            <DefaultInput
              name="flat_discount"
              placeholder="R$"
              value={discount && `R$${discount}`}
              onChange={(e) => handleChangeDiscount(e.target)}
              disabled={discountDisabled}
              style={{ height: 40, padding: '7px 14px', margin: 0 }}
            />
            <DefaultInput
              name="discount"
              placeholder="%"
              type="text"
              style={{ height: 40, padding: '7px 14px' }}
              disabled={discountDisabled}
              value={discountPercent && discountPercent}
              onChange={(e) => handleChangeDiscount(e.target)}
            />

            {discount && (
              <p style={{ position: 'absolute', right: '15px', top: '5px' }}>
                %
              </p>
            )}
          </div>
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              gap: 10,
            }}
          >
            <DefaultSelect
              name="discount_obs"
              placeholder="Motivo do desconto"
              value={
                discountObsOptions.find((o) => o.label === discountObs) ||
                (discountObs?.length > 0 &&
                  discountObsOptions.find((o) => o.value === 'other'))
              }
              options={discountObsOptions}
              onChange={(e) => {
                if (e.value === 'other') {
                  setDiscountText(true);
                } else {
                  setDiscountText(false);
                  setDiscountObs(e.label);
                }
              }}
            />
            {/* <Select
              label={null}
              name="discount_obs"
              options={discountObsOptions}
              // disabled={discountDisabled}
              value={
                discountObsOptions.find((o) => o.label === discountObs) ||
                (discountObs?.length > 0 &&
                  discountObsOptions.find((o) => o.value === 'other'))
              }
              onChange={(e) => {
                if (e.value === 'other') {
                  setDiscountText(true);
                } else {
                  setDiscountText(false);
                  setDiscountObs(e.label);
                }
              }}
            /> */}
            {discountText && (
              <DefaultInput
                type="text"
                placeholder="Escreva o motivo"
                id="discount_obs"
                name="discount_obs"
                style={{ height: 40, padding: '7px 14px' }}
                defaultValue={discountObs}
                onChange={(e) => setDiscountObs(e.target.value)}
              />
              // <Input
              //   type="text"
              //   placeholder="Escreva o motivo"
              //   id="discount_obs"
              //   name="discount_obs"
              //   // disabled={discountDisabled}
              //   defaultValue={discountObs}
              //   onChange={(e) => setDiscountObs(e.target.value)}
              // />
            )}
            <DefaultInput
              type="text"
              placeholder="Observação"
              id="observation"
              name="observation"
              style={{ height: 40, padding: '7px 14px' }}
              onChange={(e) => setDiscountObservation(e.target.value)}
            />
            {user.order_cancel_password !== '' && item.ifood_discount === 0 && (
              <Input
                placeholder="Senha administrativa"
                id="cancel_password"
                name="cancel_password"
                type="password"
                value={adminPassword}
                autocomplete="off"
                onChange={(e) => setAdminPassword(e.target.value)}
              />
            )}
          </Form>
        </div>
      )}
      <InformationDiv>
        <Total>
          <span>Total</span>
          <h4>{formatValue(priceToShow)}</h4>
        </Total>
        <Paid>
          <span>{left > 0 ? 'Restante' : 'Troco'}</span>
          <h4>{formatValue(left > 0 ? left : left * -1)}</h4>
        </Paid>
        <Form>
          <DefaultInput
            type="number"
            step="0.01"
            placeholder="R$"
            color="#3BD2C1"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            width="145px"
            inputStyle={{
              fontSize: '16px',
              fontWeight: '700',
              textAlign: 'center',
            }}
          />
        </Form>
      </InformationDiv>

      <PaymentDiv>
        <div
          style={{
            position: 'fixed',
            top: 260,
            left: 290,
            width: '415px',
            height: 210,
            backgroundColor: '#fff',
            borderRadius: 7,
            padding: 20,
            filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
            display: toastMessage ? 'flex' : 'none',
            zIndex: 2000000,
          }}
        >
          <div style={{}}>
            <p style={{ fonteWeight: 'bold', fontSize: 18 }}>
              <FaExclamationTriangle color="darkOrange" />{' '}
              <strong>Atenção, nova Atualização!</strong>
            </p>
            <span>
              Agora é possível favoritar os métodos de pagamento para que eles
              apareçam nessa lista e agilize ainda mais a sua escolha. Se quiser
              alterar o método de pagamento favorito,{' '}
              <Link to="/box/payment"> clique aqui.</Link>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              style={{
                background: '#3BD2C1',
                color: '#fff',
                width: 54,
                height: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                borderRadius: 7,
                marginLeft: 10,
                fontWeight: 'bold',
                border: 'none',
              }}
              onClick={(e) => toggleToast(false)}
            >
              OK
            </button>
          </div>
        </div>
        <AddPaymentForm>
          {favoriteMethodsState.map((payment) =>
            payment.available &&
              !prohibitedPaymentMethods.includes(payment.id) ? (
              <div key={payment.id}>
                <PaymentMethodButton
                  onClick={() => setMetodoAndPaymentOption(payment)}
                  disabled={
                    insertingPayment ||
                    (payments_session.some((p) => p?.keyword === 'prazo') && user.credit_register_with_nfce)
                  }
                >
                  {payment.label}
                </PaymentMethodButton>
              </div>
            ) : (
              <></>
            )
          )}

          {paymentMethodsState.length > 0 && favoriteMethodsState.length >= 8 && (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDrop} >
              <DropdownToggle
                color="danger"
                caret
                style={{ background: '#ff2c3a' }}
              >
                {metodo}
              </DropdownToggle>
              <DropdownMenu
                style={{
                  overflowY: 'scroll',
                  height: 300,
                }}
              >
                <IconInput
                  color='#6c6c6c'
                  placeholder='Buscar'
                  isClearable
                  deleteButton={() => setMethodsFiltered([])}
                  containerStyles={
                    {
                      height: 40,
                      border: "none",
                      borderBottom: "1px solid #6c6c6c",
                      borderRadius: 0,
                    }
                  }
                  onChange={(e) => { handleFilterMethods(e.target.value) }}
                />
                {methodsFiltered && methodsFiltered.length > 0 ?
                  methodsFiltered.map(payment => (
                    <DropdownItem
                      onClick={() => setMetodoAndPaymentOption(payment)}
                      disabled={
                        insertingPayment ||
                        (payments_session.some((p) => p?.keyword === 'prazo') && user.credit_register_with_nfce)
                      }
                    >
                      {payment.label}
                    </DropdownItem>
                  ))
                  : paymentMethodsState.map((payment) =>
                    payment.available &&
                      !prohibitedPaymentMethods.includes(payment.id) ? (
                      <div key={payment.id}>
                        <DropdownItem
                          onClick={() => setMetodoAndPaymentOption(payment)}
                          disabled={
                            insertingPayment ||
                            (payments_session.some((p) => p?.keyword === 'prazo') && user.credit_register_with_nfce)
                          }
                        >
                          {payment.label}
                        </DropdownItem>
                      </div>
                    ) : (
                      <></>
                    )
                  )}
              </DropdownMenu>
            </Dropdown>
          )}
        </AddPaymentForm>
      </PaymentDiv>

      <div
        style={{
          paddingBottom: 20,
          borderBottom: '1px solid #ccc',
        }}
      >
        {payments_session.map((payment) => {
          const isClub = payment.payment_method_id === 238;
          const isIfood = payment.payment_method_id === 572;

          return (
            <PaymentsDiv key={payment.id}>
              {isClub ? (
                <p>Resgate Clube</p>
              ) : (
                <p>
                  {isIfood
                    ? 'Pagamento Online iFood'
                    : allPaymentMethodsState
                      .filter(
                        (method) => method.id === payment.payment_method_id
                      )
                      .map((method) => method.label)}
                </p>
              )}

              <span>
                R$
                {payment.value}
              </span>
              {!isClub && isDeleting.includes(payment.id) ? (
                <div
                  style={{
                    width: '80px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                  }}
                >
                  <span
                    style={{
                      position: 'absolute',
                      top: -14,
                      right: 0,
                      fontSize: 14,
                      lineHeight: '14px',
                    }}
                  >
                    Confirmar?
                  </span>

                  <ActionButton
                    height={20}
                    width={40}
                    icon={<FaTimes />}
                    onClick={() =>
                      setIsDeleting((state) =>
                        state.filter((id) => id !== payment.id)
                      )
                    }
                  />

                  <ActionButton
                    height={20}
                    width={40}
                    icon={<FaCheck />}
                    buttonColor="#2EC9B7"
                    onClick={() => deletePayments(payment.id)}
                  />
                </div>
              ) : (
                <>
                  {payment.payment_method_id !== 238 &&
                    <FaTrash
                      style={{
                        cursor: 'pointer',
                        display:
                          payment.removable === false ||
                            payment.keyword === 'credit_card_auto' ||
                            payment.keyword === 'pix_auto' ||
                            payment.keyword === 'online_ifood'
                            ? 'none'
                            : 'block',
                      }}
                      color="#ff2c3a"
                      size={18}
                      onClick={() =>
                        setIsDeleting((state) => [...state, payment.id])
                      }
                    />
                  }
                </>
              )}
            </PaymentsDiv>
          );
        })}
      </div>
      <FinalDiv>
        <p>Total pago </p>
        <div style={{ display: 'flex', gap: 10 }}>
          <TotalSpan>R${paid}</TotalSpan>
          <TotalSpan>
            (
            {(paid / priceToShow) * 100 > 100
              ? '100'
              : ((paid / priceToShow) * 100).toFixed(2)}
            %)
          </TotalSpan>
        </div>
      </FinalDiv>
    </Container>
  );
}
