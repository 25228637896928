import React, { useEffect, useRef, useState } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { Content, LoadingDiv } from '../styles';
import { Header, Bloco, StyleKanbanWarning, FilterArea, ItemsCount, OrderArea, OrderButton } from './styles';
import OrderAndamentoDelivery from '../../Items/OrderAndamentoDelivery';
import OrderReadyDelivery from '../../Items/OrderReadyDelivery';
import { Button, Checkbox } from 'ui-kit-takeat';
import { FaExclamationTriangle } from 'react-icons/fa';
import InsertPayment from "~/assets/img/insert_payment.jpeg"
import FinishBillsInBatch from '~/components/FinishBillsInBatch';
import { IoCheckmark, IoFilter } from 'react-icons/io5';
import { HiArrowsUpDown } from 'react-icons/hi2';

export default function CardOrdersDelivery({
  baskets,
  handleItemFinished,
  handleCancelItem,
  loading,
  handleUpdate,
  hasOrderActive,
  getAllOrders,
  paymentMethods,
  allPaymentMethods,
  favoritePaymentMethods,
  countOrders,
  headerTitle,
  headerColor,
  showFinishBills,
  hasOrderBy,
  hasFilterBy,
  handleItemReady,
}) {
  const [isModalFinishBillsInBatchOpened, setIsModalFinishBillsInBatchOpened] = useState(false)
  function toggleModalFinishBillsInBatch() {
    setIsModalFinishBillsInBatchOpened(!isModalFinishBillsInBatchOpened)
  }
  const [isModalConfirmationOpened, setIsModalConfirmationOpened] = useState(false)
  function toggleModalConfirmFinishBills() {
    setIsModalConfirmationOpened(!isModalConfirmationOpened)
  }
  const [showPaid, setShowPaid] = useState(true);
  const [showUnpaid, setShowUnpaid] = useState(true);
  const [orderBy, setOrderBy] = useState('created_at');

  const handleFilterChange = (filterType) => {
    if (filterType === 'paid') {
      if (showPaid && !showUnpaid) {
        return
      }
      setShowPaid(!showPaid);
    } else if (filterType === 'unpaid') {
      if (showUnpaid && !showPaid) {
        return
      }
      setShowUnpaid(!showUnpaid);
    }
  };

  const [isFilterModalOpened, setIsFilterModalOpened] = useState(false)
  const [isOrderModalOpened, setIsOrderModalOpened] = useState(false)

  function toggleFilterModal() {
    setIsFilterModalOpened(!isFilterModalOpened)
  }

  const areaRef = useRef(null);

  const handleClickOutside = (event) => {
    if (areaRef.current && !areaRef.current.contains(event.target)) {
      setIsFilterModalOpened(false);
      setIsOrderModalOpened(false);
    }
  };

  useEffect(() => {
    if (isFilterModalOpened || isOrderModalOpened) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFilterModalOpened, isOrderModalOpened]);

  return (
    <Bloco color={headerColor}>
      {showFinishBills && (
        <>
          <Modal isOpen={isModalFinishBillsInBatchOpened} toggle={toggleModalFinishBillsInBatch} size='lg'>
            <FinishBillsInBatch isDelivery={true} toggleModalFinishBillsInBatch={toggleModalFinishBillsInBatch} getSessions={getAllOrders} />
          </Modal>
          <Modal isOpen={isModalConfirmationOpened} toggle={toggleModalConfirmFinishBills}>
            <ModalBody>
              <div style={{ textAlign: "center" }}>
                <FaExclamationTriangle size={40} color='#FFA814' />
                <h1 style={{ fontSize: 18, fontWeight: "bold", marginTop: 10 }}>
                  Apenas as comandas com ”Método de pagamento” selecionado serão finalizadas.
                </h1>
                <p>
                  Há dois modos de selecionar o “Método de Pagamento”:
                </p>
                <img src={InsertPayment} alt="Imagem mostrando onde inserir pagamento na comanda" />
              </div>
              <footer style={{ display: "flex", justifyContent: "space-between" }}>
                <Button inverted buttonColor='#FF2C3A' title='Cancelar' onClick={toggleModalConfirmFinishBills} />
                <Button
                  buttonColor={headerColor}
                  title='Confirmar'
                  onClick={() => { toggleModalFinishBillsInBatch(); setIsModalConfirmationOpened(false) }}
                />
              </footer>
            </ModalBody>
          </Modal>
        </>
      )}
      <Header color={headerColor}>
        <div style={{
          lineHeight: 1.2,
          textAlign: "left"
        }}>{headerTitle}</div>
        <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
          {hasFilterBy && (
            <Button
              buttonColor="#1d9688"
              style={{
                marginRight: 8,
                padding: 8,
                height: 32,
              }}
              title='Filtrar'
              icon={<IoFilter color='#fff' size={21} />}
              onClick={toggleFilterModal}
            />
          )}
          {hasOrderBy && (
            <OrderButton onClick={() => setIsOrderModalOpened(true)}>
              <HiArrowsUpDown color='#fff' size={21} />
              Ordenar
            </OrderButton>
          )}
          <ItemsCount color={headerColor}>{countOrders}</ItemsCount>
          {isFilterModalOpened && (
            <FilterArea ref={areaRef}>
              <p>
                <Checkbox label='Pago' checked={showPaid}
                  onClick={() => handleFilterChange('paid')} />
              </p>
              <p>
                <Checkbox label='Não Pagos' checked={showUnpaid}
                  onClick={() => handleFilterChange('unpaid')} />
              </p>
            </FilterArea>
          )}
          {isOrderModalOpened && (
            <OrderArea ref={areaRef}>
              <button
                onClick={() => {
                  setOrderBy('created_at')
                  setIsOrderModalOpened(false)
                }}
              >
                Data de criação
                {orderBy === 'created_at' && <IoCheckmark size={22} color='#2EC9B7' />}
              </button>
              <button
                onClick={() => {
                  setOrderBy('neighborhood')
                  setIsOrderModalOpened(false)
                }}
              >
                Bairro
                {orderBy === 'neighborhood' && <IoCheckmark size={22} color='#2EC9B7' />}
              </button>
            </OrderArea>
          )}
        </div>
      </Header>
      <Content>
        {showFinishBills && (
          <Button
            title="Finalizar comandas"
            onClick={() => {
              toggleModalConfirmFinishBills()
            }}
            containerStyles={{
              width: '-webkit-fill-available',
              height: 30,
              margin: '5px 5px 0',
            }}
            inverted
            buttonColor={headerColor}
          />
        )}
        {loading ? (
          <LoadingDiv>
            <Spinner color="pink" />
          </LoadingDiv>
        ) : (
          baskets
          .sort((a, b) => {
            if (orderBy === 'created_at') {
              return a.basket.start_time > b.basket.start_time ? 1 : -1;
            }
            if (orderBy === 'neighborhood') {
              return a.buyer.delivery_address?.neighborhood > b.buyer.delivery_address?.neighborhood ? 1 : -1;
            }
          })
            .filter(
              (order) =>
                order.table.table_type === 'delivery' && !order.scheduled_to
            )
            .filter(item => {
              if (showPaid && showUnpaid) {
                return true;
              }
              if (showPaid) {
                return item.is_paid === true;
              }
              if (showUnpaid) {
                return item.is_paid === false;
              }
              return false;
            })
            .map((item) => (
              <>
                {hasFilterBy ? (
                  <OrderReadyDelivery
                    key={item.basket.id}
                    handleCancelItem={handleCancelItem}
                    handleItemFinished={handleItemFinished}
                    item={item}
                    paymentMethods={paymentMethods}
                    allPaymentMethods={allPaymentMethods}
                    favoritePaymentMethods={favoritePaymentMethods}
                    getAllOrders={getAllOrders}
                    handleUpdate={handleUpdate}
                  />
                ) : (
                  <OrderAndamentoDelivery
                  key={item.basket.id}
                  handleCancelItem={handleCancelItem}
                  handleItemFinished={handleItemFinished}
                  item={item}
                  handleItemReady={handleItemReady}
                  handleUpdate={handleUpdate}
                  hasOrderBy={hasOrderBy}
                  isOngoing={(!hasOrderBy && !hasFilterBy) ? true : false}
                />
                )}
              </>
            ))
        )}
        {!hasOrderActive && (
          <StyleKanbanWarning>
            <p style={{ color: "#666666" }}>Não há pedido</p>
            <p style={{ color: "#666666" }}>no momento</p>
          </StyleKanbanWarning>
        )}
      </Content>
    </Bloco>
  );
}