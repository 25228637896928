import React, { useState, useCallback, useEffect } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';

import { Modal, ModalBody, ModalFooter, Row, Col, Collapse } from 'reactstrap';
import Input from '~/components/Form/Input';
import api from '~/services/api';
import CheckboxInput from '~/components/Form/Checkbox';
import { ButtonCollapse } from './styles';
import { MdExpandMore } from 'react-icons/md';
import nfeInfo from '~/services/NFeInfo';
import Select from '~/components/Form/SelectInput';
import PersonalButton from '~/components/Buttons/PersonalButton';
import { Hyperlink } from 'ui-kit-takeat';

export default function NfeDevolucao({
  nfe,
  modal,
  toggle,
  nfeReq,
  itens,
  setItens,
  data,
  user,
  nfeData
}) {
  const [allChecked, setAllChecked] = useState(false);

  const [isProcessing, setIsProcessing] = useState(nfeData?.isProcessingDevolucao || false);
  const [isOpenDadosEmitente, setIsOpenDadosEmitente] = useState(false);
  const [isOpenDadosDestinatario, setIsOpenDadosDestinatario] = useState(false);
  // const [isOpenDadosDaNota, setIsOpenDadosDaNota] = useState(false);
  const [errosSefaz, setErrosSefaz] = useState(null);

  const icms_origem_options = nfeInfo.icms_origem.map((icms) => ({
    value: icms.value,
    label: icms.description,
  }));
  
  const icms_s_t_devolucao = user.regime_tributario === '1' ? 
    nfeInfo.csosn.map((icms) => ({
      value: icms.csosn,
      label: `${icms.csosn} - ${icms.description}`
    }))
    : 
    nfeInfo.cst.map((icms) => ({
      value: icms.cst,
      label: icms.label
    }));

  function changeSend(item) {
    itens[item].send = !itens[item].send;

    const thisItens = itens.map((it) => {
      if (it.numero_item === item + 1) {
        it.send = !it.send;
      } else {
        it.send = it.send;
      }

      return it;
    });

    setItens(thisItens);
  }

  function checkAll() {
    if (allChecked) {
      itens.map((item) => {
        item.send = false;
      });
    } else {
      itens.map((item) => {
        item.send = true;
      });
    }

    setAllChecked(!allChecked);
  }

  const [loadingNfe, setLoadingNfe] = useState(false);
  const [mensagemSefaz, setMensagemSefaz] = useState('');

  const local_options = [
    { value: '1', label: 'Operação Interna' },
    { value: '2', label: 'Operação Interestadual' },
  ];

  const ie_options = [
    {
      value: '1',
      label: '1 – Contribuinte ICMS (informar a IE do destinatário);',
    },
    {
      value: '2',
      label:
        '2 – Contribuinte isento de Inscrição no cadastro de Contribuintes do ICMS;',
    },
    {
      value: '9',
      label:
        '9 – Não Contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes do ICMS.',
    },
  ];

  const handleDevolucao = useCallback(async () => {
    const thisItens = itens.filter((it) => it.send === true);
    if (thisItens.length === 0) {
      toast.error('Selecione pelo menos um produto');
    } else {
      
      try {
        setLoadingNfe(true);
        const handle = data;

        handle.itens = thisItens;

        thisItens.forEach(item => {
          if(item.valor_ipi_devolvido && item.valor_ipi_devolvido !== '' && item.valor_ipi_devolvido !== '0.00'){
            let percentualDevolvido = parseFloat(item.quantidade_comercial) / parseFloat(item._quantidade_original);
            percentualDevolvido = parseFloat(percentualDevolvido.toFixed(2)) * 100;
            item.percentual_devolvido = percentualDevolvido.toFixed(2);
          }else{
            delete item.valor_ipi_devolvido;
            delete item.percentual_devolvido;
          }

          if(item.fcp_percentual && item.fcp_percentual !== '' && item.fcp_percentual !== '0.00' && item.fcp_percentual !== '0'){
            item.fcp_base_calculo = item.valor_bruto;
            item.fcp_valor = (parseFloat(item.valor_bruto) * (parseFloat(item.fcp_percentual) / 100)).toFixed(2);
          }else{
            delete item.fcp_base_calculo;
            delete item.fcp_valor;
          }
        });

        console.log(handle);
        // setLoadingNfe(false);
        // return;

        const response = await api.post(
          `/restaurants/new-devolucao-nfe/${nfe.id}`,
          {
            data: handle,
            updateProcessing: false
          }
        );

        // const response = null;
        // console.log(handle);
        // setLoadingNfe(false);
        // return;

        if (response.data.status === 'erro_autorizacao') {
          setMensagemSefaz(response.data.mensagem || response.data.mensagem_sefaz);
          setErrosSefaz(response.data);
        } else if (response.data.status === 'autorizado') {
          window.open(
            response.data.url,
            'janela',
            'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
          );
          toggle();
        } else {
          if(response.data.status === 'processando_autorizacao'){
            setIsProcessing(true);
          }
          setMensagemSefaz(response.data?.mensagem || response.data.mensagem_sefaz || '');
          setErrosSefaz(response.data);
        }

        setLoadingNfe(false);
      } catch (err) {
        console.log(err);
        setLoadingNfe(false);
        setErrosSefaz(err?.response?.data);
      }
    }
  });

  const handleUpdate = useCallback(async () => {
    try {
      setLoadingNfe(true);

      const response = await api.post(
        `/restaurants/new-devolucao-nfe/${nfe.id}`,
        {
          data: null,
          updateProcessing: true
        }
      );

      if (response.data.status === 'erro_autorizacao') {
        setMensagemSefaz(response.data.mensagem || response.data.mensagem_sefaz);
        setErrosSefaz(response.data);
      } else if (response.data.status === 'autorizado') {
        window.open(
          response.data.url,
          'janela',
          'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
        );
        toggle();
      } else {
        setMensagemSefaz(response.data?.mensagem || response.data.mensagem_sefaz || '');
        setErrosSefaz(response.data);
      }

      setLoadingNfe(false);
    } catch (err) {
      console.log(err);
      setLoadingNfe(false);
      setErrosSefaz(err?.response?.data);
    }
  });

  const changeRef = useCallback(async () => {
    try {
      await api.put(`/restaurants/change-ref-devolucao/${nfe.id}`);
      toast.success('Refêrencia alterada com sucesso!');
    } catch (err) {
      console.log(err);
      toast.error('Erro ao mudar refêrencia.');
    }
  }, [nfe]);

  useEffect(() => {
    setIsProcessing(nfeData?.isProcessingDevolucao || false);
  }, [nfeData]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalBody>
          <Form initialData={data}>
            {!nfeReq && (
              <Row>
                <Col>
                  <h5 style={{ color: 'red' }}>
                    <strong>
                      Informações da nota ainda não processada pela Sefaz. Tente
                      novamente mais tarde.
                    </strong>
                  </h5>
                </Col>
              </Row>
            )}

            {/* Dados do Emitente */}
            <ButtonCollapse onClick={() => setIsOpenDadosEmitente(!isOpenDadosEmitente)}>
              <MdExpandMore size={20} title="Mostrar mais" />
              Dados do Emitente: 
            </ButtonCollapse>
            
            <Collapse isOpen={isOpenDadosEmitente}>
              <Row>
                <Col md="6">
                  <Input
                    name="nome_emitente"
                    label="Razão Social"
                    type="text"
                    onChange={(e) => (data.nome_emitente = e.target.value)}
                  />
                </Col>
                <Col md="3">
                  <Input
                    name="cnpj_emitente"
                    label="CNPJ"
                    type="text"
                    onChange={(e) => (data.cnpj_emitente = e.target.value)}
                  />
                </Col>
                <Col md="3">
                  <Input
                    name="inscricao_estadual_emitente"
                    label="Inscrição Estadual"
                    type="text"
                    onChange={(e) =>
                      (data.inscricao_estadual_emitente = e.target.value)
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md="7">
                  <Input
                    name="logradouro_emitente"
                    label="Logradouro"
                    type="text"
                    onChange={(e) => (data.logradouro_emitente = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="numero_emitente"
                    label="Nº"
                    type="text"
                    onChange={(e) => (data.numero_emitente = e.target.value)}
                  />
                </Col>
                <Col md="3">
                  <Input
                    name="complemento_emitente"
                    label="Complemento"
                    type="text"
                    onChange={(e) => (data.complemento_emitente = e.target.value)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="3">
                  <Input
                    name="municipio_emitente"
                    label="Município"
                    type="text"
                    onChange={(e) => (data.municipio_emitente = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="uf_emitente"
                    label="UF"
                    type="text"
                    onChange={(e) => (data.uf_emitente = e.target.value)}
                  />
                </Col>
                <Col md="3">
                  <Input
                    name="bairro_emitente"
                    label="Bairro"
                    type="text"
                    onChange={(e) => (data.bairro_emitente = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="cep_emitente"
                    label="CEP"
                    type="text"
                    onChange={(e) => (data.cep_emitente = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="regime_tributario_emitente"
                    label="Regime Tributário"
                    type="text"
                    fontSize={{ fontSize: 12 }}
                    value={user.regime_tributario === '1' ? 'Simples Nacional' : 'Regime Normal'} 
                    // onChange={(e) =>
                    //   (data.regime_tributario_emitente = e.target.value)
                    // }
                    disabled
                  />
                </Col>
              </Row>
            </Collapse>

            {/* Dados do Destinatário */}
            <ButtonCollapse onClick={() => setIsOpenDadosDestinatario(!isOpenDadosDestinatario)}>
              <MdExpandMore size={20} title="Mostrar mais" />
              Dados do Destinatário: 
            </ButtonCollapse>

            <Collapse isOpen={isOpenDadosDestinatario}>
              <Row>
                <Col md="6">
                  <Input
                    name="nome_destinatario"
                    label="Razão Social"
                    type="text"
                    onChange={(e) => (data.nome_destinatario = e.target.value)}
                  />
                </Col>
                <Col md="3">
                  <Input
                    name="cnpj_destinatario"
                    label="CNPJ"
                    type="text"
                    onChange={(e) => (data.cnpj_destinatario = e.target.value)}
                  />
                </Col>
                <Col md="3">
                  <Input
                    name="inscricao_estadual_destinatario"
                    label="Inscrição Estadual"
                    type="text"
                    onChange={(e) =>
                      (data.inscricao_estadual_destinatario = e.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Input
                    name="logradouro_destinatario"
                    label="Logradouro"
                    type="text"
                    onChange={(e) =>
                      (data.logradouro_destinatario = e.target.value)
                    }
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="numero_destinatario"
                    label="Nº"
                    type="text"
                    onChange={(e) => (data.cep_emitente = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="complemento_destinatario"
                    label="Complemento"
                    type="text"
                    onChange={(e) =>
                      (data.complemento_destinatario = e.target.value)
                    }
                  />
                </Col>
                <Col md="4">
                  <Input
                    name="bairro_destinatario"
                    label="Bairro"
                    type="text"
                    onChange={(e) => (data.bairro_destinatario = e.target.value)}
                  />
                </Col>
                
              </Row>
              <Row>
                <Col md="3">
                  <Input
                    name="municipio_destinatario"
                    label="Município"
                    type="text"
                    onChange={(e) =>
                      (data.municipio_destinatario = e.target.value)
                    }
                  />
                </Col>
                <Col md="1">
                  <Input
                    name="uf_destinatario"
                    label="UF"
                    type="text"
                    onChange={(e) => (data.uf_destinatario = e.target.value)}
                  />
                </Col>
                
                <Col md="2">
                  <Input
                    name="cep_destinatario"
                    label="CEP"
                    type="text"
                    onChange={(e) => (data.cep_destinatario = e.target.value)}
                  />
                </Col>
                <Col md="3">
                  <Select
                    name="indicador_inscricao_estadual_destinatario"
                    options={ie_options}
                    label="Indicador Insc. Est."
                    defaultValue={ie_options.filter(opt => opt.value === data.indicador_inscricao_estadual_destinatario?.toString()) || null}
                    onChange={(e) => (data.indicador_inscricao_estadual_destinatario = e.value)}
                  />
                </Col>
                <Col md="3">
                  <Select
                    name="local_destino"
                    options={local_options}
                    label="Local Destino"
                    defaultValue={local_options.filter(opt => opt.value === data.local_destino?.toString()) || null}
                    onChange={(e) => (data.local_destino = e.value)}
                  />
                </Col>
              </Row>
            </Collapse>
          </Form>

          <Row>
            <Col
              style={{
                borderBottom: '1px solid #EBEBEB',
              }}
            >
              <Form>
                Produtos:
                <CheckboxInput
                  name="all_item_select"
                  label={`Selecionar todos`}
                  onChange={(e) => checkAll()}
                  value={allChecked}
                />
              </Form>
            </Col>
          </Row>

          {nfeReq && (nfeReq?.itens || nfeReq?.items) && (nfeReq?.itens?.length > 0 || nfeReq?.items?.length > 0) ? (
            (nfeReq?.itens || nfeReq?.items).map((item) => (
              <Form
                initialData={itens[parseInt(item?.numero_item) - 1] || []}
                style={{ borderBottom: '1px solid #EBEBEB' }}
              >
                <Row>
                  <Col>
                    <CheckboxInput
                      name="item_select"
                      id={`item_select_${item.numero_item}`}
                      label={`Produto #${item.numero_item} - ${item.descricao}`}
                      checked={
                        (itens &&
                          itens[parseInt(item.numero_item) - 1]?.send) ||
                        false
                      }
                      onChange={(e) =>
                        changeSend(parseInt(item.numero_item) - 1)
                      }
                    />
                  </Col>
                </Row>

                <Collapse isOpen={(itens && itens[parseInt(item.numero_item) - 1]?.send) || false}>
                  <Row>
                    <Col md="2">
                      <Input
                        name="codigo_produto"
                        label="Cód. Prod."
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).codigo_produto = e.target.value)
                        }
                      />
                    </Col>
                    <Col md="6">
                      <Input
                        name="descricao"
                        label="Descrição"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).descricao = e.target.value)
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="codigo_ncm"
                        label="NCM"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).ncm = e.target.value)
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="cfop"
                        label="CFOP"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).cfop = e.target.value)
                        }
                      />
                    </Col>
                  </Row>

                  {/* QTD comercial | Valor unit. Comercial | UN comercial | QTD tributável | Valor unit. Tributável | UN tributável */}
                  <Row>
                    <Col md="2">
                      <Input
                        name="quantidade_comercial"
                        label="Qnt. Comercial"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).quantidade_comercial = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="valor_unitario_comercial"
                        label="Valor Un. Comercial (R$)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).valor_unitario_comercial = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="unidade_comercial"
                        label="Un. Comercial"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).unidade_comercial = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="quantidade_tributavel"
                        label="Qnt. Tributavel"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).quantidade_tributavel = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="valor_unitario_tributavel"
                        label="Valor Un. Tributavel (R$)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).valor_unitario_tributavel = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="unidade_tributavel"
                        label="Un. Tributável"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).unidade_tributavel = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>
                    
                  </Row>
                  
                  {/* Valor bruto | Valor Desconto | Valor Frete |Origem Mercadoria | CEST */}
                  <Row>
                    <Col md="2">
                      <Input
                        name="valor_bruto"
                        label="Valor Bruto (R$)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).valor_bruto = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="valor_desconto"
                        label="Valor Desconto (R$)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).valor_desconto = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="valor_frete"
                        label="Valor Frete (R$)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).valor_frete = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="cest"
                        label="CEST"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).cest = e.target.value)
                        }
                      />
                    </Col>

                    <Col md="4">  
                      <Select
                        name="icms_situacao_tributaria"
                        options={icms_s_t_devolucao}
                        label="ICMS Situação Trib."
                        defaultValue={icms_s_t_devolucao.filter(icms => icms.value === item.icms_situacao_tributaria) || null}
                        onChange={(e) => {
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).icms_situacao_tributaria = e.value)
                        }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                      />
                      {/* <Input
                        name="icms_origem"
                        label="ICMS Origem"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).icms_origem = e.target.value)
                        }
                      /> */}
                    </Col>

                    {/* <Col md="3">
                      <Input
                        name="pis_situacao_tributaria"
                        label="PIS Situação Trib."
                        type="text"
                        fontSize={{ fontSize: 10 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).pis_situacao_tributaria = e.target.value)
                        }
                      />
                    </Col> */}
                  </Row>

                  <Row>
                    <Col md="3">
                      <Select
                        name="icms_origem"
                        options={icms_origem_options}
                        label="ICMS de Origem"
                        defaultValue={icms_origem_options.filter(icms => icms.value === item.icms_origem)}
                        onChange={(e) => {
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).icms_origem = e.value)
                        }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                      />
                      {/* <Input
                        name="icms_situacao_tributaria"
                        label="ICMS Situação Trib."
                        type="text"
                        fontSize={{ fontSize: 10 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).icms_situacao_tributaria = e.target.value)
                        }
                      /> */}

                      
                    </Col>

                    <Col md="3">
                      <Select
                        name="icms_modalidade_base_calculo"
                        options={nfeInfo.icms_modalidade_base_calculo}
                        label="ICMS Mod. B. Calc."
                        defaultValue={nfeInfo.icms_modalidade_base_calculo.filter(icms => icms.value === item.icms_modalidade_base_calculo)}
                        onChange={(e) => {
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).icms_modalidade_base_calculo = e.value)
                        }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="icms_base_calculo"
                        label="ICMS Base Calc. (R$)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).icms_base_calculo = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="icms_aliquota"
                        label="ICMS Alíquota (%)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).icms_aliquota = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="icms_valor"
                        label="ICMS Valor (R$)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).icms_valor = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="3">
                      <Select
                        name="pis_situacao_tributaria"
                        options={nfeInfo.pis_situacao_tributaria}
                        label="PIS Situação Trib."
                        defaultValue={nfeInfo.pis_situacao_tributaria.filter(icms => icms.value === item.pis_situacao_tributaria)}
                        onChange={(e) => {
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).pis_situacao_tributaria = e.value)
                        }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                      />
                    </Col>
                    
                    <Col md="3">
                      <Input
                        name="pis_base_calculo"
                        label="PIS Base Calc. (R$)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).pis_base_calculo = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="pis_aliquota_porcentual"
                        label="PIS Alíquota (%)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).pis_aliquota_porcentual = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>
                    
                    <Col md="2">
                      <Input
                        name="pis_valor"
                        label="PIS Valor (R$)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).pis_valor = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col> 

                    <Col md="2"> 
                      <Input
                        name="fcp_percentual"
                        label="F. de Comb. a Pobr. (%)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).fcp_percentual = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="3">
                      <Select
                        name="cofins_situacao_tributaria"
                        options={nfeInfo.pis_situacao_tributaria}
                        label="COFINS Situação Trib."
                        defaultValue={nfeInfo.pis_situacao_tributaria.filter(icms => icms.value === item.cofins_situacao_tributaria)}
                        onChange={(e) => {
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).cofins_situacao_tributaria = e.value)
                        }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                      />
                    </Col>
                    
                    <Col md="3">
                      <Input
                        name="cofins_base_calculo"
                        label="COFINS Base Calc. (R$)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).cofins_base_calculo = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="cofins_aliquota_porcentual"
                        label="COFINS Alíquota (%)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).cofins_aliquota_porcentual = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col>
                    
                    <Col md="2"> 
                      <Input
                        name="cofins_valor"
                        label="COFINS Valor (R$)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).cofins_valor = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col> 

                    <Col md="2"> 
                      <Input
                        name="valor_ipi_devolvido"
                        label="IPI Devolvido (R$)"
                        type="text"
                        fontSize={{ fontSize: 12 }}
                        disabled={
                          !itens?.find(
                            (it) => it.numero_item === item.numero_item
                          )?.send || false
                        }
                        onChange={(e) =>
                          (itens.find(
                            (it) => it.numero_item === item.numero_item
                          ).valor_ipi_devolvido = e.target.value?.replace(',','.'))
                        }
                      />
                    </Col> 
                  </Row>
                </Collapse>
              </Form>
            ))
          ) : (
            <p style={{ color: 'red' }}>Nota sem itens.</p>
          )}

          {/* Observações da Nota */}
          <Form>
            <Row>
              <Col md="12">
                <Input
                  name="informacoes_adicionais_contribuinte"
                  label="Observações da Nota"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) =>
                    (data.informacoes_adicionais_contribuinte = e.target.value)
                  }
                />
              </Col>
            </Row>
          </Form>

          {/* Dados da Nota */}
          {/* <Form initialData={data}>
            <ButtonCollapse onClick={() => setIsOpenDadosDaNota(!isOpenDadosDaNota)} style={{marginTop: 10}}>
              <MdExpandMore size={20} title="Mostrar mais" />
              Dados da Nota: 
            </ButtonCollapse>

            <Collapse isOpen={isOpenDadosDaNota}>
              <Row>
                <Col md="2">
                  <Input
                    name="valor_total"
                    label="Valor Total"
                    type="text"
                    value={itens
                      .filter(item => item.send === true && item.valor_bruto && parseFloat(item.valor_bruto) > 0)
                      .reduce((acc, item) => acc + parseFloat(item.valor_bruto?.replace(',','.')), 0)}
                    fontSize={{ fontSize: 12 }}
                    onChange={(e) => (data.valor_total = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="valor_desconto"
                    label="Valor Desconto"
                    type="text"
                    fontSize={{ fontSize: 12 }}
                    onChange={(e) => (data.valor_desconto = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="valor_frete"
                    label="Valor Frete"
                    type="text"
                    fontSize={{ fontSize: 12 }}
                    onChange={(e) => (data.valor_frete = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="valor_pis"
                    label="Valor PIS"
                    type="text"
                    fontSize={{ fontSize: 12 }}
                    onChange={(e) => (data.valor_pis = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="valor_ipi"
                    label="Valor IPI"
                    type="text"
                    fontSize={{ fontSize: 12 }}
                    onChange={(e) => (data.valor_ipi = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="valor_cofins"
                    label="Valor COFINS"
                    type="text"
                    fontSize={{ fontSize: 12 }}
                    onChange={(e) => (data.valor_cofins = e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <Input
                    name="icms_aliquota"
                    label="ICMS Aliquota"
                    type="text"
                    fontSize={{ fontSize: 12 }}
                    onChange={(e) => (data.icms_aliquota = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="icms_base_calculo"
                    label="ICMS Base Cálculo"
                    type="text"
                    fontSize={{ fontSize: 10 }}
                    onChange={(e) => (data.icms_base_calculo = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="icms_valor_total"
                    label="ICMS Valor Total"
                    type="text"
                    fontSize={{ fontSize: 10 }}
                    onChange={(e) => (data.icms_valor_total = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="icms_aliquota_st"
                    label="ICMS Aliquota ST"
                    type="text"
                    fontSize={{ fontSize: 10 }}
                    onChange={(e) => (data.icms_aliquota_st = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="icms_base_calculo_st"
                    label="ICMS Base Cálc. ST"
                    type="text"
                    fontSize={{ fontSize: 10 }}
                    onChange={(e) => (data.icms_base_calculo_st = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="icms_valor_total_st"
                    label="ICMS Valor Total ST"
                    type="text"
                    fontSize={{ fontSize: 10 }}
                    onChange={(e) => (data.icms_valor_total_st = e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <Input
                    name="issqn_aliquota"
                    label="ISSQN Aliquota"
                    type="text"
                    fontSize={{ fontSize: 12 }}
                    onChange={(e) => (data.issqn_aliquota = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="issqn_base_calculo"
                    label="ISSQN Base Cálculo"
                    type="text"
                    fontSize={{ fontSize: 10 }}
                    onChange={(e) => (data.issqn_base_calculo = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="issqn_valor_total"
                    label="ISSQN Valor Total"
                    type="text"
                    fontSize={{ fontSize: 10 }}
                    onChange={(e) => (data.issqn_valor_total = e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="pis_aliquota_porcentual"
                    label="PIS Aliq. Porcent."
                    type="text"
                    fontSize={{ fontSize: 10 }}
                    onChange={(e) =>
                      (data.pis_aliquota_porcentual = e.target.value)
                    }
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="cofins_aliquota_porcentual"
                    label="COFINS Aliq. Porc."
                    type="text"
                    fontSize={{ fontSize: 10 }}
                    onChange={(e) =>
                      (data.cofins_aliquota_porcentual = e.target.value)
                    }
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="modalidade_frete"
                    label="Modalidade Frete"
                    type="text"
                    fontSize={{ fontSize: 10 }}
                    onChange={(e) => (data.modalidade_frete = e.target.value)}
                  />
                </Col>
              </Row>
            </Collapse>
          </Form> */}

          {mensagemSefaz !== '' && (
            <Row style={{ marginTop: 10 }}>
              <h5 style={{ color: 'red' }}>
                <strong>Mensagem SEFAZ:</strong> {mensagemSefaz}
              </h5>
            </Row>
          )}

        {errosSefaz &&
          errosSefaz.status ===
          'erro_validacao_schema' &&
          errosSefaz.erros_schema &&
          errosSefaz.erros_schema.map((e) => (
            <h5>{' '}
              - <strong>{e.name}:</strong>{' '}
              {e.erro}
            </h5>
          ))}

        </ModalBody>
        <ModalFooter>
          <div
            style={{
              display: 'flex',
              flexDirection: 'main-start',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <div style={{ width: '30%'}}>
              <Hyperlink
                textDecoration="underline" 
                fontSize={14} 
                width="fit-content" 
                onClick={() => { changeRef(); }}
                style={{float: 'left'}}
              >
                Mudar referência
              </Hyperlink>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'flex-start',
                width: '100%'
              }}
            >
              <PersonalButton
                color="#229688"
                type="button"
                onClick={() => handleDevolucao()}
                disabled={loadingNfe}
                message={loadingNfe ? 'Aguarde' : 'Enviar'}
                style={{marginLeft: 10}}
              />

              {isProcessing &&
                <PersonalButton
                  type="button"
                  color="#2EC9B7"
                  id="atualizar_status"
                  onClick={() => handleUpdate()}
                  message={
                    loadingNfe ? 'Aguarde...' : 'Atualizar status'
                  }
                  disabled={loadingNfe}
                />
              }
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
