/* eslint-disable no-shadow */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { toast } from 'react-hot-toast';
import {
  startOfDay,
  endOfDay,
  differenceInDays,
} from 'date-fns';

import { Row, Col } from 'reactstrap';
import {
  Wrapper,
  Header,
  Card,
  Content,
  FilterArea,
} from './styles';

import Bills from '~/components/Bills';

import api from '~/services/api';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { PageTitle } from '~/components/PageTitle';
import { Button, Checkbox, FullCalendar } from 'ui-kit-takeat';
import { useAuth } from '~/context/AuthContext';
import { IoFilter } from 'react-icons/io5';

export default function ViewBills() {
  const { user } = useAuth();

  const params = new URLSearchParams(document.location.search);

  const [activeTab, setActiveTab] = useState(
    params?.get('isCompleted') === 'true' ? 2 : user.only_delivery ? 3 : 0
  );

  const [rangeDate, setRangeDate] = useState({
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  });

  const [showPaid, setShowPaid] = useState(true);
  const [showUnpaid, setShowUnpaid] = useState(true);

  const [sessionsFiltered, setSessionsFiltered] = useState([])
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSessions = useCallback(async () => {
    if (differenceInDays(rangeDate.end, rangeDate.start) > 31) {
      toast.error('Escolha um período de no máximo 31 dias');
      return false;
    }

    try {
      const response = await api.get('/restaurants/table-sessions-minimal', {
        params: {
          start_date: rangeDate.start,
          end_date: rangeDate.end,
          filter_all: true,
          is_delivery: false,
        },
      });

      setSessions(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [rangeDate]);

  useEffect(() => {
    setLoading(true);
    getSessions();
    setLoading(false);
  }, [getSessions]);

  async function handleUpdate() {
    setLoading(true);
    getSessions();
    setLoading(false);
  }

  async function orderByTableNumber() {
    if (differenceInDays(rangeDate.end, rangeDate.start) > 31) {
      return false;
    }

    try {
      const response = await api.get('/restaurants/table-sessions-minimal', {
        params: {
          start_date: rangeDate.start,
          end_date: rangeDate.end,
        },
      });

      const sessionsOrder = response.data.sort((a, b) => {
        return a.table.table_number - b.table.table_number;
      });

      setSessions(sessionsOrder);
    } catch (err) {
      // console.log('setsessions', response.data, inicialDate, finalDate);
      toast.error('Erro ao carregar informações');
    }
  }

  async function orderByTableDate() {
    if (differenceInDays(rangeDate.end, rangeDate.start) > 31) {
      return false;
    }
    try {
      const response = await api.get('/restaurants/table-sessions-minimal', {
        params: {
          start_date: rangeDate.start,
          end_date: rangeDate.end,
        },
      });

      setSessions(response.data);
    } catch (err) {
      // console.log('setsessions', response.data, inicialDate, finalDate);
      toast.error('Erro ao carregar informações');
    }
  }

  const [permission, setPermission] = useState(true);

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'OperationAllSessions'}`
      );

      const { can_read } = response.data.OperationAllSessions;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);


  const handleFilterChange = (filterType) => {
    if (filterType === 'paid') {
      if (showPaid && !showUnpaid) {
        return
      }
      setShowPaid(!showPaid);
    } else if (filterType === 'unpaid') {
      if (showUnpaid && !showPaid) {
        return
      }
      setShowUnpaid(!showUnpaid);
    }
  };

  const [isFilterModalOpened, setIsFilterModalOpened] = useState(false)

  function toggleFilterModal() {
    setIsFilterModalOpened(!isFilterModalOpened)
  }

  const filterAreaRef = useRef(null);

  const handleClickOutside = (event) => {
    if (filterAreaRef.current && !filterAreaRef.current.contains(event.target)) {
      setIsFilterModalOpened(false); // Fecha o modal de filtros
    }
  };

  useEffect(() => {
    if (isFilterModalOpened) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFilterModalOpened]);

  const filterSessions = useCallback(() => {
    const filteredSessions = sessions?.filter(item => {
      let total = 0;

      if (item.payments && Array.isArray(item.payments)) {
        item.payments.forEach(payment => {
          total += parseFloat(payment.payment_value);
        });
      }

      if (showPaid && showUnpaid) {
        return true;
      } else if (showPaid) {
        return total >= Number(item.total_service_price);
      } else if (showUnpaid) {
        return total < Number(item.total_service_price);
      }

      return false;
    });

    setSessionsFiltered(filteredSessions);
  }, [sessions, showPaid, showUnpaid]);

  useEffect(() => {
    filterSessions();
  }, [sessions, showPaid, showUnpaid]);



  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Wrapper>
      <Header>
        <PageTitle>Acompanhar Comandas</PageTitle>
        <p>
          Aqui você acompanha as comandas das mesas e consegue controlar as
          contas individuais fechadas e a(s) forma(s) de pagamento usada(s).
        </p>
      </Header>
      <Card>
        <Content>
          {!user.only_delivery && (
            <Row style={{ minHeight: 40, marginBottom: 10 }}>
              {activeTab !== 3 && (
                <Col md="12" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    buttonColor="#2EC9B7"
                    style={{ marginRight: 8, border: (!showPaid || !showUnpaid) ? "1px solid #fff" : "none" }}
                    title='Filtrar'
                    icon={<IoFilter color='#fff' size={21} />}
                    onClick={toggleFilterModal}
                  />
                  {isFilterModalOpened && (
                    <FilterArea ref={filterAreaRef}>
                      <p>
                        <Checkbox label='Pago' checked={showPaid}
                          onClick={() => handleFilterChange('paid')} />
                      </p>
                      <p>
                        <Checkbox label='Não Pagos' checked={showUnpaid}
                          onClick={() => handleFilterChange('unpaid')} />
                      </p>
                    </FilterArea>
                  )}
                  <div style={{ flexDirection: 'column' }}>
                    <FullCalendar
                      isRange
                      onDateSelected={setRangeDate}
                      value={rangeDate}
                      hideFields={[
                        'this_year'
                      ]}
                    />

                    {differenceInDays(rangeDate.end, rangeDate.start) > 31 &&
                      <p style={{ marginTop: 3, color: 'red' }}>
                        Escolha um período de no máximo 31 dias
                      </p>
                    }
                  </div>
                </Col>
              )}
            </Row>
          )}

          <Bills
            sessions={sessionsFiltered}
            getSessions={getSessions}
            loading={loading}
            setSessions={setSessions}
            finalDate={rangeDate.end}
            inicialDate={rangeDate.start}
            orderByTableNumber={orderByTableNumber}
            orderByTableDate={orderByTableDate}
            handleUpdate={handleUpdate}
            params={params}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Content>
      </Card>
    </Wrapper>
  );
}
