import React, { useState, useEffect, useCallback } from 'react';

import { Form } from '@unform/web';
import { BiTransfer } from 'react-icons/bi';
import { format, addHours } from 'date-fns';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-hot-toast';
import formatPhone from '~/services/formatPhoneString';
import {
  Body,
  Phone,
  Price,
  Items,
  Item,
  NamePrice,
  ItemName,
  PriceItem,
  ReopenButton,
} from './styles';
import { useAuth } from '~/context/AuthContext';
import api from '~/services/api';
import SelectTable from '~/components/Form/SelectTable';
import EyeOrderBillItem from '../EyeOrderBillItem';
import Input from '~/components/Form/Input';
import { useTables } from '~/context/TablesContext';
import { Button } from 'ui-kit-takeat';

export default function EyeOrderBill({
  bill,
  session,
  setSessions,
  inicialDate,
  finalDate,
  toggleEyeOpen,
  length,
  getSessions,
  handleUpdateBills,
}) {
  const { user } = useAuth();
  const [, setPendingBills] = useState([]);
  const [, setClosedBills] = useState([]);
  const [tables, setTables] = useState([]);
  const [, setTableChanged] = useState(null);
  const [modalTransfer, setModalTransfer] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isModalTransferPasswordOpen, setIsModalTransferPasswordOpen] =
    useState(false);

  const { getTablesAvailable } = useTables();

  function setAllTablesAvailable() { }

  function setInputValue() { }
  function setTablesFilteredSearch() { }

  const [transferDataState, setTransferDataState] = useState();

  const userName = bill?.buyer
    ? formatPhone(bill?.buyer.phone)
    : bill?.waiter?.name;
  useEffect(() => {
    try {
      const pending = session?.bills.filter(
        (bill) => bill?.status !== 'finished'
      );
      setPendingBills(pending);

      const closed = session.bills.filter((bill) => bill.status === 'finished');
      setClosedBills(closed);
    } catch (error) { }
  }, [session]);

  async function toggleTransfer() {
    await getTables();
    setModalTransfer(!modalTransfer);
  }

  const getTables = useCallback(async (id) => {
    const response = await api.get('/restaurants/tables');
    const tablesData = response.data;

    const tablesFiltered = tablesData.filter(
      (table) => table.table_type === 'table' || table.table_type === 'command'
    );
    const tablesOptions = [];

    tablesFiltered.forEach((table) => {
      let status;
      if (table.status === 'available') {
        status = 'Disponível';
      } else {
        status = 'Em Uso';
      }

      tablesOptions.push({
        value: table.id,
        label: `${table.table_number.toString()} - ${status}`,
        status: table.status,
      });
    });

    setTables(tablesOptions);
  }, []);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  function toggleModalTransferPassword() {
    setIsModalTransferPasswordOpen(!isModalTransferPasswordOpen);
  }

  function handleTransferTable(data) {
    if (user.has_order_transfer_password) {
      toggleModalTransferPassword();
      setTransferDataState(data);
    } else {
      handleTransferTableBill(data);
    }
  }

  async function handleVerifyTransferPassword(data) {
    setIsSubmiting(true);
    const response = await api.post('/restaurants/verify-transfer', {
      order_transfer_password: data.order_transfer_password,
    });

    if (response.data.success) {
      handleTransferTableBill(transferDataState);
      toggleModalTransferPassword();
    } else {
      toast.error('Senha incorreta');
      setIsSubmiting(false);
    }
  }

  async function handleTransferTableBill(data) {
    setIsSubmiting(true);
    try {
      if (bill.buyer) {
        await api.put('restaurants/transfer-table-client', {
          key: session.key,
          table_id: data.new_table_id,
          buyer_id: bill?.buyer.id,
        });
      } else {
        await api.put('restaurants/transfer-table-client', {
          key: session?.key,
          table_id: data.new_table_id,
          waiter_id: bill?.waiter.id,
        });
      }

      await delay(1000);
      const response = await api.get('/restaurants/table-sessions-minimal', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      const tablesFiltered = response.data.filter(
        (item) => item.table.table_type !== 'delivery'
      );

      setSessions(tablesFiltered);
      setTableChanged(data.new_table_id);
      toggleTransfer();
      toggleEyeOpen();
      getTablesAvailable();
      setIsSubmiting(false);
      toast.success('Cliente transferido com sucesso!');
    } catch (error) {
      toast.error('Erro ao transferir cliente.');
      console.log('error', error);
      setIsSubmiting(false);
    }
  }

  function changeValue(value) {
    const newValue = value.replace('.', ',');

    return newValue;
  }

  function getTableSessions() { }

  return (
    <React.Fragment key={bill.id}>
      <Body>
        <Phone>
          <strong>{userName} </strong>
          <p style={{ marginTop: 8 }}>{bill?.buyer?.name}</p>
        </Phone>

        {length > 1 && (
          <Price>
            <ReopenButton
              onClick={toggleTransfer}
              style={{ background: '#ffc107', color: 'black' }}
            >
              <div style={{ fontSize: 15 }}>
                <BiTransfer size={15} style={{ marginRight: 3 }} />
                {bill?.buyer
                  ? 'Trocar Cliente de Mesa'
                  : 'Trocar Pedidos do Garçom de Mesa'}
              </div>
            </ReopenButton>
          </Price>
        )}
      </Body>
      <Items>
        {bill?.order_baskets.map((basket) => (
          <React.Fragment key={basket.id}>
            {basket.orders.map((order) => (
              <EyeOrderBillItem
                order={order}
                bill={bill}
                basket={basket}
                basketLength={basket.orders.length}
                billLength={bill?.order_baskets.length}
                length={length}
                getTables={getTables}
                changeValue={changeValue}
                inicialDate={inicialDate}
                finalDate={finalDate}
                setSessions={setSessions}
                session={session}
                toggleEyeOpen={toggleEyeOpen}
                userName={userName}
                tables={tables}
                getSessions={getSessions}
                getTableSessions={getSessions}
                getTablesAvailable={getTablesAvailable}
                setAllTablesAvailable={setAllTablesAvailable}
                setInputValue={setInputValue}
                setTablesFilteredSearch={setTablesFilteredSearch}
              />
            ))}
          </React.Fragment>
        ))}

        {user?.has_service_tax && (
          <Item>
            <NamePrice>
              <ItemName>Serviços ({user.service_tax}% - Opcional)</ItemName>
              <PriceItem>
                R$
                {(bill?.total_service_price - bill?.total_price).toFixed(2)}
              </PriceItem>
            </NamePrice>
          </Item>
        )}

        <Item style={{ borderBottom: '1px solid black', paddingBottom: 10 }}>
          <NamePrice>
            <ItemName>
              {' '}
              <strong>Total</strong>
            </ItemName>
            <PriceItem>
              <strong>
                R$
                {bill?.total_service_price}
              </strong>
            </PriceItem>
          </NamePrice>
        </Item>
      </Items>

      <Modal
        isOpen={isModalTransferPasswordOpen}
        toggle={toggleModalTransferPassword}
      >
        <Form onSubmit={handleVerifyTransferPassword}>
          <ModalBody>
            <Input
              name="order_transfer_password"
              label="Senha de Transferência"
              type="password"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggleModalTransferPassword}
              title="Cancelar"
            />
            <Button
              type="submit"
              title="Transferir"
              buttonColor="#2EC9B7"
              onClick={() => { }}
              disabled={isSubmiting}
            />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalTransfer} toggle={toggleTransfer}>
        <Form onSubmit={handleTransferTable}>
          <ModalBody style={{ padding: 20 }}>
            <h3 style={{ fontSize: 20, fontWeight: 700, marginBottom: 10 }}>
              Transferência de Mesa
            </h3>
            <h5>{userName}</h5>
            {tables.length !== 0 ? (
              <div>
                <h6 style={{ fontSize: 16 }}>
                  Mesa atual: <strong>{session?.table?.table_number}</strong>
                </h6>
                <SelectTable
                  name="new_table_id"
                  label="Nova mesa:"
                  options={tables.filter((a) => a.value !== session?.table?.id)}
                />
              </div>
            ) : (
              <p style={{ color: 'red' }}>
                Não há nenhuma mesa disponível no momento.
              </p>
            )}
          </ModalBody>
          <ModalFooter style={{ padding: '0 20px 20px' }}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                toggleTransfer();
              }}
              title="Cancelar"
            />
            {tables.length !== 0 && (
              <Button
                type="submit"
                title="Transferir"
                onClick={() => { }}
                buttonColor="#2EC9B7"
                disabled={isSubmiting}
              />
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
