import styled from 'styled-components';
import { Hyperlink } from 'ui-kit-takeat';

export const Header = styled.div`
  display: flex;
  background-color: #fe1933;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 50px;
  width: 100%;
  padding: 20px;
  text-align: center;
  border-radius: 6px 6px 0 0;

  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
`;

export const Bloco = styled.div`
  min-width: 285px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  border: solid 1px #fe1933;
  border-color: #fb4a20;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  flex-basis: 33%;
  max-width: 33%;
  flex-grow: 1;
`;


export const StyleKanbanImage = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyleKanbanTextTop = styled.div`
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #666666;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
`;

export const StyleKanbanTextMid = styled.div`
  align-items: center;
  margin-top: 10px;
  justify-content: center;
  height: 100%;
  width: 80%;
  color: #666666;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

export const HyperlinkStyle = styled(Hyperlink)`
  background-color: #EEFFFD;
  color: #3BD2C1;
  display: inline-block;
  width: 80%;
`;

export const IconTakeat = styled.span`
  float: left;
  margin-left: 15px;

  img {
    width: 25px;
    heigth: 50px;
  }
`;