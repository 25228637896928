import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { toast } from 'react-hot-toast';
import { Modal, ModalFooter, ModalBody } from 'reactstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import api from '../../services/api';

import { useNotification } from '../notificationContext';
import { useAuth } from '~/context/AuthContext';
import { usePos } from '~/context/PosContext';

import { useTables } from '../TablesContext';
import ButtonDefault from '~/components/Buttons/DefaultButton';
import Input from '~/components/Form/Input';
import getValidationErrors from '~/services/getValidationErrors';
import { useBills } from '../BillsContext';
import { useUzapi } from '../UzapiContext';
import { useWhatsgw } from '../WhatsgwContext';

const OrderContext = createContext();

const OrderProvider = ({ children }) => {
  const {
    websocketManager,
    user,
    token,
    generalNotifications,
    setGeneralNotifications,
    setNotificationsOpen,
    signOut,
  } = useAuth();
  const { setWppConnected, setWppQrcode, setLoadingQrCode } = useUzapi();

  const {
    setWppConnectedGw,
    setWppQrcodeGw,
    setLoadingQrCodeGw,
    setShowReconnectGw,
  } = useWhatsgw();

  const [ifoodStatus, setIfoodStatus] = useState(() => {
    const statusData = localStorage.getItem('@gddashboard:status_ifood');

    if (statusData) {
      return JSON.parse(statusData);
    }

    return [];
  });

  const { getStoneTransactions } = usePos();
  const { getTableBills } = useBills();
  const cancelForm = useRef(null);
  const { getTablesAvailable } = useTables();
  const [pendingBaskets, setPendingBaskets] = useState(() => {
    const pendingBasketsStorage = localStorage.getItem(
      '@gddashboard:pendingBaskets'
    );

    if (pendingBasketsStorage) {
      return JSON.parse(pendingBasketsStorage);
    }

    return [];
  });
  const [acceptedBaskets, setAcceptedBaskets] = useState(() => {
    const acceptedBasketsStorage = localStorage.getItem(
      '@gddashboard:acceptedBaskets'
    );

    if (acceptedBasketsStorage) {
      return JSON.parse(acceptedBasketsStorage);
    }

    return [];
  });
  const [finishedBaskets, setFinishedBaskets] = useState([]);
  const [canceledBaskets, setCanceledBaskets] = useState([]);
  const [readyBaskets, setReadyBaskets] = useState([]);
  const [orderBasketId, setOrderBasketId] = useState();
  const [orderId, setORderId] = useState();
  const [idState, setIdState] = useState();
  const [loading, setLoading] = useState(false);
  const [ongoingBaskets, setOngoingBaskets] = useState([]);
  const [deliveredBaskets, setDeliveredBaskets] = useState([]);

  const [loadingCancelItems, setLoadingCancelItems] = useState(false);

  const [pixToShow, setPixToShow] = useState([]);
  const [allPix, setAllPix] = useState([]);
  const [created, setCreated] = useState([]);

  const [sessionsInCache, setSessionsInCache] = useState(() => {
    const cacheStorage = localStorage.getItem('@gddashboard:sessionsInCache');

    if (cacheStorage) {
      return JSON.parse(cacheStorage);
    }

    return [];
  });

  const [updateTime, setUpdateTime] = useState(() => {
    const data = new Date();
    return `${data.getUTCHours() - 3}:${data.getUTCMinutes() < 10
      ? `0${data.getUTCMinutes()}`
      : data.getUTCMinutes()
      }`;
  });

  const hasKey = user ? user.has_order_cancel_password : false;

  const [modalCancelOrderIsOpened, setModalCancelOrderIsOpened] =
    useState(false);

  function handleOpenCancelOrderModal(order_basket_id, order_id, from, id) {
    setModalCancelOrderIsOpened(true);
    setOrderBasketId(order_basket_id);
    setORderId(order_id);
    setIdState(id);
  }

  const toggleModalCancelOrder = useCallback(() => {
    setModalCancelOrderIsOpened(!modalCancelOrderIsOpened);
  }, [modalCancelOrderIsOpened]);

  const { sendNotification } = useNotification();

  const getAllOrders = useCallback(async () => {
    setLoading(true);

    const response = await api.get('restaurants/baskets');
    const { orders } = response.data;
    const { created } = response.data;

    setPendingBaskets(
      orders.filter((a) => a.basket.order_status === 'pending')
    );
    setAcceptedBaskets(
      orders.filter((a) => a.basket.order_status === 'accepted')
    );
    setFinishedBaskets(
      orders.filter((a) => a.basket.order_status === 'finished')
    );
    setReadyBaskets(orders.filter((a) => a.basket.order_status === 'ready'));
    setOngoingBaskets(orders.filter((a) => a.basket.order_status === 'ongoing'));
    setDeliveredBaskets(orders.filter((a) => a.basket.order_status === 'delivered'));

    setCanceledBaskets(
      orders.filter(
        (a) =>
          a.basket.order_status === 'canceled' ||
          a.basket.order_status === 'chargeback'
      )
    );

    if (created.length > 0) {
      setCreated(created);
    }
    setLoading(false);
  }, []);

  const [pixLoading, setPixLoading] = useState(false);

  const getPixPayments = useCallback(async () => {
    setPixLoading(true);
    try {
      const response = await api.get('restaurants/pix');
      setPixToShow(response.data.toShow);
      setAllPix(response.data.all);
    } catch (err) {
      toast.error(err);
    }
    setPixLoading(false);
  }, []);

  const getAllOrdersAndNotificate = useCallback(async () => {
    // Pix
    try {
      const response = await api.get('restaurants/pix');
      setPixToShow(response.data.toShow);
      setAllPix(response.data.all);
    } catch (err) {
      toast.error(err);
    }

    // Pedidos
    const response = await api.get('restaurants/baskets');
    const { orders } = response.data;

    const pending = orders.filter((a) => a.basket.order_status === 'pending');

    const pendingOrders = orders.filter(
      (a) =>
        a.basket.order_status === 'pending' && a.table.table_type !== 'delivery'
    );

    const pendingDeliveryOrders = orders.filter(
      (a) =>
        a.basket.order_status === 'pending' && a.table.table_type === 'delivery'
    );

    if (pending.length > 0) {
      setPendingBaskets(pending);

      if (pendingOrders.length > 0) {
        sendNotification({
          title: 'Seu restaurante possui pedidos pendentes! ',
          body: 'Por favor, cheque os pedidos em seu restaurante',
        });
      }

      if (pendingDeliveryOrders.length > 0) {
        sendNotification({
          title: 'Seu restaurante possui pedidos de delivery pendentes! ',
          body: 'Por favor, cheque os pedidos de delivery em seu restaurante',
        });
      }
    }

    getTablesAvailable();

    setAcceptedBaskets(
      orders.filter((a) => a.basket.order_status === 'accepted')
    );
    setReadyBaskets(orders.filter((a) => a.basket.order_status === 'ready'));
    setOngoingBaskets(orders.filter((a) => a.basket.order_status === 'ongoing'));
    setDeliveredBaskets(orders.filter((a) => a.basket.order_status === 'delivered'));

    setFinishedBaskets(
      orders.filter((a) => a.basket.order_status === 'finished')
    );
    setCanceledBaskets(
      orders.filter(
        (a) =>
          a.basket.order_status === 'canceled' ||
          a.basket.order_status === 'chargeback'
      )
    );
  }, [sendNotification, getTablesAvailable]);

  const cancelOrderItem = useCallback(
    async ({ order_basket_id, order_id, from, id }) => {
      setLoadingCancelItems(true);
      try {
        if (hasKey) {
          handleOpenCancelOrderModal(order_basket_id, order_id, from, id);
        } else if (
          window.confirm('Tem certeza que deseja cancelar este item?')
        ) {
          await api.delete(
            `restaurants/orders/basket/${order_basket_id}/item/${order_id}`
          );

          toast.success('Item cancelado com sucesso!');
          getTableBills(id);
        }
      } catch (err) {
        toast.error('Erro ao cancelar item.');
        console.log(err);
      }
      setLoadingCancelItems(false);
    },
    [getAllOrders, hasKey, getTableBills, loadingCancelItems]
  );

  const cancelOrderItemWithCancelKey = useCallback(
    async (data) => {
      setLoadingCancelItems(true);
      try {
        if (cancelForm?.current) {
          cancelForm.current.setErrors({});
        }

        const schema = Yup.object().shape({
          order_cancel_password: Yup.string().required('senha obrigatória'),
          cancel_reason: Yup.string().required('justicativa obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.delete(
          `restaurants/orders/basket/${orderBasketId}/item/${orderId}`,
          {
            data: {
              order_cancel_password: data.order_cancel_password,
              cancel_reason: data.cancel_reason,
            },
          }
        );

        getAllOrders();
        toggleModalCancelOrder();
        toast.success('Item cancelado com sucesso!');
        getTableBills(idState);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (cancelForm?.current) {
            cancelForm.current.setErrors(errors);
          }
        } else if (
          error.response.data.errorType === 'incorrect_order_cancel_password'
        ) {
          toast.error('senha incorreta');
        } else {
          toast.error('Erro ao cancelar pedido');
        }
      }
      setLoadingCancelItems(false);
      console.log('loading false');
    },
    [
      getAllOrders,
      orderId,
      orderBasketId,
      toggleModalCancelOrder,
      getTableBills,
      idState,
      loadingCancelItems,
    ]
  );

  const moveOrderToAccepted = useCallback(
    ({ order_basket_id, accepted_at }) => {
      const orderBasket = pendingBaskets.find(
        (item) => item.basket.id === order_basket_id
      );

      orderBasket.accepted_at = accepted_at;
      orderBasket.basket.order_status = 'accepted';

      const newPending = pendingBaskets.filter(
        (item) => item.basket.id !== order_basket_id
      );

      const newAccepted = [...acceptedBaskets, orderBasket];

      setPendingBaskets(newPending);
      setAcceptedBaskets(newAccepted);
    },
    [pendingBaskets, acceptedBaskets]
  );

  const moveOrderToFinished = useCallback(({ order_basket_id }) => {
    // const orderBasket = acceptedBaskets.find(
    //   (item) => item.basket.id === order_basket_id
    // );
    // const newAccepted = acceptedBaskets.filter(
    //   (item) => item.basket.id !== order_basket_id
    // );
    // const newFinished = [...finishedBaskets, orderBasket];
    // setAcceptedBaskets(newAccepted);
    // setFinishedBaskets(newFinished);
  }, []);

  const moveOrderToFinishedDelivery = useCallback(
    ({ order_basket_id }) => {
      const orderBasket = readyBaskets.find(
        (item) => item.basket.id === order_basket_id
      );
      const newReady = readyBaskets.filter(
        (item) => item.basket.id !== order_basket_id
      );

      const newFinished = [...finishedBaskets, orderBasket];

      setReadyBaskets(newReady);
      setFinishedBaskets(newFinished);
    },
    [readyBaskets, finishedBaskets]
  );

  const moveOrderToReady = useCallback(
    ({ order_basket_id, ready_at, ifood_on_demand_id = null }) => {
      const orderBasket = acceptedBaskets.find(
        (item) => item.basket.id === order_basket_id
      );
      if (!orderBasket) {
        return;
      }

      orderBasket.ready_at = ready_at;
      orderBasket.basket.order_status = 'ready';
      orderBasket['ifood_on_demand_id'] = ifood_on_demand_id;

      const newAccepted = acceptedBaskets.filter(
        (item) => item.basket.id !== order_basket_id
      );

      const newReady = [...readyBaskets, orderBasket];

      setAcceptedBaskets(newAccepted);
      setReadyBaskets(newReady);
    },
    [acceptedBaskets, readyBaskets]
  );

  const moveOrderToCanceled = useCallback(
    ({ order_basket_id, from }) => {
      if (!from) {
        return;
      }
      let orderBasket = {};

      if (from === 'pending') {
        orderBasket = pendingBaskets.find(
          (item) => item.basket.id === order_basket_id
        );
        const newPending = pendingBaskets.filter(
          (item) => item.basket.id !== order_basket_id
        );
        setPendingBaskets(newPending);
      }
      if (from === 'accepted') {
        orderBasket = acceptedBaskets.find(
          (item) => item.basket.id === order_basket_id
        );
        const newAccepted = acceptedBaskets.filter(
          (item) => item.basket.id !== order_basket_id
        );
        setAcceptedBaskets(newAccepted);
      }

      if (from === 'finished') {
        orderBasket = finishedBaskets.find(
          (item) => item.basket.id === order_basket_id
        );
        const newFinished = finishedBaskets.filter(
          (item) => item.basket.id !== order_basket_id
        );
        setFinishedBaskets(newFinished);
      }

      const newCanceled = [...canceledBaskets, orderBasket];

      setCanceledBaskets(newCanceled);
    },
    [acceptedBaskets, pendingBaskets, canceledBaskets, finishedBaskets]
  );

  const addNewOrder = useCallback(
    ({ order_basket }) => {
      setPendingBaskets([order_basket, ...pendingBaskets]);
    },
    [pendingBaskets]
  );

  const updateIfoodOnDemandReady = useCallback(
    ({ order_basket_id, ifood_on_demand_id }) => {
      const orderBasket = readyBaskets.find(
        (item) => item.basket.id === order_basket_id
      );

      orderBasket['ifood_on_demand_id'] = ifood_on_demand_id;

      // const newAccepted = acceptedBaskets.filter(
      //   (item) => item.basket.id !== order_basket_id
      // );

      // console.log('aqqq>>>', orderBasket);

      // const newReady = [...readyBaskets, orderBasket];

      // setAcceptedBaskets(newAccepted);
      // setReadyBaskets(newReady);
    },
    [readyBaskets]
  );

  useEffect(() => {
    if (token) {
      getAllOrders();
    }
  }, [getAllOrders, token]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:pendingBaskets',
      JSON.stringify(pendingBaskets)
    );
  }, [pendingBaskets]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:acceptedBaskets',
      JSON.stringify(acceptedBaskets)
    );
  }, [acceptedBaskets]);

  /*
  useEffect(() => {
    let interval;
    if (user) {
      interval = setInterval(getAllOrdersAndNotificate, 45000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [getAllOrdersAndNotificate, user]);
  */

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(0, async (data) => {
        if (data.type === 'ifood-status') {
          setIfoodStatus(data.data);
          localStorage.setItem(
            '@gddashboard:status_ifood',
            JSON.stringify(data.data)
          );
        }

        if (false && data.type === 'new-connection') {
          console.log(
            'OrderContext: socket connected again and getAllOrdersAndNotificate'
          );
          getAllOrdersAndNotificate();
        } else if (data.type === 'table-finished') {
          const { table_session_id } = data.item;

          setAcceptedBaskets((state) =>
            state.filter((order) => order.session_id !== table_session_id)
          );

          setPendingBaskets((state) =>
            state.filter((order) => order.session_id !== table_session_id)
          );

          setCanceledBaskets((state) =>
            state.filter((order) => order.session_id !== table_session_id)
          );

          setFinishedBaskets((state) =>
            state.filter((order) => order.session_id !== table_session_id)
          );
        } else if (data.type === 'basket') {
          const order = data.item;

          const { created } = data;
          if (created) {
            setCreated(created);
          }

          getPixPayments();

          getTablesAvailable();

          const { basket } = order;

          switch (basket.order_status) {
            case 'pending':
              setPendingBaskets((state) => [order, ...state]);

              if (basket.table_type === 'delivery') {
                sendNotification({
                  title:
                    'Seu restaurante possui pedidos de delivery pendentes! ',
                  body: 'Por favor, cheque os pedidos de delivery em seu restaurante',
                });
              } else {
                sendNotification({
                  title: 'Seu restaurante possui pedidos pendentes! ',
                  body: 'Por favor, cheque os pedidos em seu restaurante',
                });
              }
              break;
            case 'accepted':
              setPendingBaskets((state) => {
                const ordersFIltered = state.filter(
                  (order) => order.basket.id !== basket.id
                );

                return ordersFIltered;
              });

              setAcceptedBaskets((state) => {
                const ordersAccepetedFIltered = state.filter(
                  (order) => order.basket.id !== basket.id
                );
                return [order, ...ordersAccepetedFIltered];
              });

              // await handleNotifyWhatsapp(order);

              break;
            case 'ready':
              setAcceptedBaskets((state) => {
                const ordersFIltered = state.filter(
                  (order) => order.basket.id !== basket.id
                );

                return ordersFIltered;
              });

              setReadyBaskets((state) => {
                const ordersReadyFiltered = state.filter(
                  (order) => order.basket.id !== basket.id
                );

                return [order, ...ordersReadyFiltered];
              });

              break;
            case 'finished':
              if (order.table.table_type === 'delivery') {
                setReadyBaskets((state) => {
                  const newReady = state.filter(
                    (item) => item.basket.id !== basket.id
                  );

                  return [...newReady];
                });
                setFinishedBaskets((state) => [order, ...state]);
                setDeliveredBaskets((state) => {
                  const newDelivered = state.filter(
                    (item) => item.basket.id !== basket.id
                  )

                  return [...newDelivered];
                })
              } else {
                setAcceptedBaskets((state) => {
                  const newAccepted = state.filter(
                    (item) => item.basket.id !== basket.id
                  );
                  return [...newAccepted];
                });

                setFinishedBaskets((state) => [order, ...state]);
              }

              break;
            case 'canceled':
            case 'chargeback':
              setCanceledBaskets((state) => [order, ...state]);
              break;
            // case 'ongoing':
            // break;
            // case 'delivered':
            // break;
            default:
          }
          // getAllOrders();
        } else if (
          data.type === 'cancel-order-item' ||
          data.type === 'update-order-item'
        ) {
          getAllOrders();
        } else if (data.type === 'delivery-order-update') {
          getAllOrders();
        } else if (data.type === 'cancel-order') {
          getTableBills();
          getAllOrders();
        } else if (data.type === 'new-transfer') {
          getAllOrders();
          getTableBills();
        } else if (data.type === 'reopen-session') {
          getAllOrders();
          getTableBills();
        } else if (data.type === 'new-session-payment') {
          getPixPayments();
        } else if (data.type === 'new-general-notification') {
          let notifs = generalNotifications;
          notifs.fixed.forEach((notif) => {
            if (notif.type === 'success-sessions') {
              notifs.all.unshift(notif);
            }
          });

          notifs.fixed = notifs.fixed.filter(
            (notif) => !(notif.type === 'success-sessions')
          );

          notifs.fixed.unshift(data.data);
          setGeneralNotifications(notifs);
          setNotificationsOpen(true);
        } else if (data.type === 'stone-pos-payment') {
          getStoneTransactions();
        } else if (data.type === 'uzapi_status') {
          //redeploy
          const connected = data.item.status === 'inChat';

          setWppConnected(connected);
          localStorage.setItem(
            '@gddashboard:wppConnected',
            JSON.stringify(connected)
          );
        } else if (data.type === 'uzapi_qrcode') {
          const base64 = await fetch(data.item.qrcode);
          const blob = await base64.blob();
          const url = URL.createObjectURL(blob);

          setWppQrcode(url);
          setLoadingQrCode(false);
        } else if (data.type === 'whatsgw_status') {
          //redeploy
          const connected = data.item.state === 'connected';

          setWppConnectedGw(connected);
          localStorage.setItem(
            '@gddashboard:wppConnected',
            JSON.stringify(connected)
          );

          if (connected === true) {
            setShowReconnectGw(false);
            toast.success('WhatsApp conectado com sucesso!');
          }
        } else if (data.type === 'whatsgw_qrcode') {
          const base64 = await fetch(data.item.qrcode);
          const blob = await base64.blob();
          const url = URL.createObjectURL(blob);

          setWppQrcodeGw(url);
          setLoadingQrCodeGw(false);
        } else if (data.type === 'user-logout') {
          const thisUser = data.item.user;
          if (thisUser.id === user.user_id) {
            signOut();
          }
        }
      });
    }
  }, [
    websocketManager,
    sendNotification,
    getTablesAvailable,
    pendingBaskets,
    acceptedBaskets,
    readyBaskets,
    finishedBaskets,
    canceledBaskets,
    getAllOrders,
    getTableBills,
    getPixPayments,
    getAllOrdersAndNotificate,
    generalNotifications,
    getStoneTransactions,

  ]);

  const value = useMemo(
    () => ({
      pendingBaskets,
      setPendingBaskets,
      acceptedBaskets,
      finishedBaskets,
      canceledBaskets,
      readyBaskets,
      addNewOrder,
      getAllOrders,
      loading,
      setLoading,
      setUpdateTime,
      updateTime,
      moveOrderToAccepted,
      moveOrderToFinished,
      moveOrderToFinishedDelivery,
      moveOrderToCanceled,
      moveOrderToReady,
      cancelOrderItem,
      pixToShow,
      allPix,
      getPixPayments,
      created,
      setCreated,
      ifoodStatus,
      sessionsInCache,
      setSessionsInCache,
      updateIfoodOnDemandReady,
      setReadyBaskets,
      ongoingBaskets,
      deliveredBaskets,
    }),
    [
      pendingBaskets,
      setPendingBaskets,
      acceptedBaskets,
      readyBaskets,
      finishedBaskets,
      canceledBaskets,
      addNewOrder,
      getAllOrders,
      setLoading,
      loading,
      setUpdateTime,
      updateTime,
      moveOrderToAccepted,
      moveOrderToFinished,
      moveOrderToFinishedDelivery,
      moveOrderToCanceled,
      moveOrderToReady,
      cancelOrderItem,
      pixToShow,
      allPix,
      getPixPayments,
      pixLoading,
      created,
      setCreated,
      ifoodStatus,
      sessionsInCache,
      setSessionsInCache,
      updateIfoodOnDemandReady,
      setReadyBaskets,
      ongoingBaskets,
      deliveredBaskets,
    ]
  );
  return (
    <OrderContext.Provider value={value}>
      {children}
      <Modal isOpen={modalCancelOrderIsOpened} toggle={toggleModalCancelOrder}>
        <Form onSubmit={cancelOrderItemWithCancelKey} ref={cancelForm}>
          <ModalBody>
            <Input
              name="order_cancel_password"
              label="Senha de cancelamento"
              type="password"
            />
            <Input name="cancel_reason" label="Justificativa" />
          </ModalBody>
          <ModalFooter>
            <ButtonDefault
              message="Confirmar"
              type="submit"
              disabled={loadingCancelItems}
            />
          </ModalFooter>
        </Form>
      </Modal>
    </OrderContext.Provider>
  );
};

function useOrder() {
  const context = useContext(OrderContext);

  if (!context) {
    throw new Error('useOrder must be used within an OrderProvider');
  }

  return context;
}

export { OrderProvider, useOrder };