import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import toast from 'react-hot-toast';

import { v4 } from 'uuid';

import { useOrder } from '~/context/OrderingSystem/Order';

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const { addToOrder } = useOrder();
  const [cart, setCart] = useState(() => {
    const cartStorage = localStorage.getItem('@garcomdigital:cart');

    if (cartStorage) {
      return JSON.parse(cartStorage);
    }

    return [];
  });

  useEffect(() => {
    localStorage.setItem('@garcomdigital:cart', JSON.stringify(cart));
  }, [cart]);

  const [productsHasCounted, setProductsHasCounted] = useState([]);

  const closeCart = useCallback(() => {
    localStorage.removeItem('@garcomdigital:cart');
    setProductsHasCounted([]);

    setCart([]);
  }, []);

  // TODO: Modificar essa função para receber um objeto no lugar dessa
  // lista gigante de parâmetros
  const addCartToOrder = useCallback(
    async (
      tableId,
      clientId,
      orderType,
      hasUsedDelivery,
      data,
      toggleModalOperation,
      paymentsSession,
      isTablePrintable,
      commandTableNumber,
      getMenu,
      discount,
      discountObs,
      user_id,
      phone,
      scheduled_time,
      adminPassword,
      details,
      registerPayment,
      rescue,
      channel
    ) => {
      const newCart = cart.filter(basket => basket.amount > 0)

      const res = await addToOrder(
        newCart,
        tableId,
        clientId,
        orderType,
        hasUsedDelivery,
        data,
        closeCart,
        toggleModalOperation,
        paymentsSession,
        isTablePrintable,
        commandTableNumber,
        getMenu,
        discount,
        discountObs,
        user_id,
        phone,
        scheduled_time,
        adminPassword,
        details,
        registerPayment,
        rescue,
        channel
      );
      return res;
    },
    [cart, addToOrder, closeCart]
  );

  const countProducts = useCallback(
    (product) => {
      const productFind = productsHasCounted.findIndex(
        (item) => item.id === product.id
      );

      if (productFind !== -1) {
        productsHasCounted[productFind].amount += product.amount;
      } else {
        setProductsHasCounted((state) => {
          return [...state, product];
        });
      }
    },
    [productsHasCounted]
  );

  const increment = useCallback(
    (id, orderingId) => {
      const newCart = [...cart];

      if (orderingId) {
        const findCart = newCart.findIndex(
          (item) => item.ordering_system_id === orderingId
        );

        newCart[findCart].amount += 1;

        setCart(newCart);

        const productFind = productsHasCounted.findIndex(
          (item) => item.id === id
        );

        if (productFind !== -1) {
          productsHasCounted[productFind].amount += 1;
        }
      } else {
        const findCart = newCart.findIndex((item) => item.intern_id === id);

        newCart[findCart].amount += 1;

        setCart(newCart);
      }
    },
    [cart, productsHasCounted]
  );

  const decrement = useCallback(
    (id, orderingId) => {
      const newCart = [...cart];

      if (orderingId) {
        const findCart = newCart.findIndex(
          (item) => item.ordering_system_id === orderingId
        );

        if (newCart[findCart].amount > 1) {
          newCart[findCart].amount -= 1;
        }

        setCart(newCart);

        const productFind = productsHasCounted.findIndex(
          (item) => item.id === id
        );

        if (productFind !== -1) {
          if (productsHasCounted[productFind].amount > 1) {
            productsHasCounted[productFind].amount -= 1;
          }
        }
      } else {
        const findCart = newCart.findIndex((item) => item.id === id);

        if (newCart[findCart].amount > 1) {
          newCart[findCart].amount -= 1;
        }

        setCart(newCart);
      }
    },
    [cart, productsHasCounted]
  );

  const changeProductAmount = useCallback(
    (id, orderingId, newAmount) => {
      const newCart = [...cart];

      if (orderingId) {
        const findCart = newCart.findIndex(
          (item) => item.ordering_system_id === orderingId
        );

        newCart[findCart].amount = newAmount;

        setCart(newCart);

        const productFind = productsHasCounted.findIndex(
          (item) => item.id === id
        );

        if (productFind !== -1) {
          productsHasCounted[productFind].amount = newAmount;
        }
      } else {
        const findCart = newCart.findIndex((item) => item.intern_id === id);

        newCart[findCart].amount = newAmount;

        setCart(newCart);
      }
    },
    [cart, productsHasCounted]
  );

  const addToCart = useCallback(
    (basket) => {
      if (
        cart.length > 0 &&
        basket.complementsCategory.length < 1 &&
        !basket.isProductWeight &&
        !basket.observation
      ) {
        const productExist = cart.findIndex(
          (product) =>
            product.id === basket.product.id &&
            product.complement_categories < 1 &&
            !product.observation
        );

        if (productExist !== -1) {
          const newCart = [...cart];

          newCart[productExist].amount += basket.amount;

          setCart(newCart);

          // increment(productExist[0].intern_id, null);
        } else {
          setCart([
            ...cart,
            {
              basket_id: v4(),
              id: basket.product.id,
              ordering_system_id: v4(),
              name: basket.product.name,
              price: basket.product.price,
              image: basket.product.image,
              amount: basket.amount,
              observation: basket.observation,
              complement_categories: basket.complementsCategory,
              use_weight: basket.isProductWeight || false,
              weight: basket.inputProductWeight || null,
              weight_price: basket.inputProductPrice || null,
              intern_id: basket.product.id + 1,
              is_unitary: basket.product.is_unitary,
              combo_price: basket.combo_price,
            },
          ]);
        }
      } else {
        setCart([
          ...cart,
          {
            basket_id: v4(),
            id: basket.product.id,
            ordering_system_id: v4(),
            name: basket.product.name,
            price: basket.product.price,
            image: basket.product.image,
            amount: basket.amount,
            observation: basket.observation,
            complement_categories: basket.complementsCategory,
            use_weight: basket.isProductWeight || false,
            weight: basket.inputProductWeight || null,
            weight_price: basket.inputProductPrice || null,
            intern_id: basket.product.id + 1,
            is_unitary: basket.product.is_unitary,
            combo_price: basket.combo_price,
          },
        ]);
      }
    },
    [cart, increment]
  );

  const removeFromCart = useCallback(
    (id, itemId) => {
      const newCart = [...cart];
      const productId = newCart.findIndex((item) => item.basket_id === id);

      if (productId >= 0) {
        const cartFiltered = newCart.filter(
          (product) => product.basket_id !== id
        );

        setCart(cartFiltered);

        const productFind = productsHasCounted.findIndex(
          (item) => item.id === itemId
        );

        if (productFind !== -1) {
          productsHasCounted[productFind].amount = 0;
        }
      } else {
        console.log('Produto nao existe');
      }
    },
    [cart, productsHasCounted]
  );

  const addObservation = useCallback(
    (id, ordering_system_id, message) => {
      const newCart = [...cart];

      if (ordering_system_id) {
        const findCart = newCart.findIndex(
          (item) => item.ordering_system_id === ordering_system_id
        );

        newCart[findCart].observation = message;

        setCart(newCart);
      } else {
        const findCart = newCart.findIndex((item) => item.id === id);

        newCart[findCart].observation = message;

        setCart(newCart);
      }
    },
    [cart]
  );

  const value = useMemo(
    () => ({
      addToCart,
      decrement,
      increment,
      cart,
      closeCart,
      addCartToOrder,
      removeFromCart,
      addObservation,
      countProducts,
      productsHasCounted,
      setProductsHasCounted,
      changeProductAmount
    }),
    [
      addToCart,
      closeCart,
      decrement,
      increment,
      cart,
      addCartToOrder,
      removeFromCart,
      addObservation,
      countProducts,
      productsHasCounted,
      setProductsHasCounted,
      changeProductAmount
    ]
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

const useCart = () => {
  const context = useContext(CartContext);

  if (!context) {
    console.log('useCart must be within a provider');
  }

  return context;
};

export { CartProvider, useCart };
