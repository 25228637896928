import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';

import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { MdUpdate } from 'react-icons/md';

import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';

import formatCompleteDate from '~/services/formatCompleteDate';
import getValidationErrors from '~/services/getValidationErrors';

import {
  Container,
  Card,
  ButtonArea,
  Button,
  Deposits,
  ErrorContainer,
  TotalArea,
  StyledTable,
  DisplayNoneImprimir,
  LinkStyled,
} from './styles';

import api from '~/services/api';
import ReportOpeningBox from '~/pages/Reports/ReportOpeningBox';
import { useAuth } from '~/context/AuthContext';
import PrintOpeningBoxAudit from '~/pages/Reports/PrintOpeningBoxAudit';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import CheckboxInput from '~/components/Form/Checkbox';
import PersonalButton from '~/components/Buttons/PersonalButton';
import PersonalOutlineButton from '~/components/Buttons/PersonalOutlineButton';
import { FaExclamationTriangle, FaEye, FaPrint } from 'react-icons/fa';
import { FiTrendingDown, FiTrendingUp, FiX } from 'react-icons/fi';
import ReactToPrint from 'react-to-print';
import PrintManualCashierWithdrawal from '~/components/Prints/PrintManualCashierWithdrawal';
import PaymentFormStillOpenSessions from '~/components/PaymentFormStillOpenSessions';
import { ToReceiveModal } from '~/components/ToReceiveModal';
import formatValue from '~/utils/formatValue';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { InformationDiv } from 'ui-kit-takeat';

function OpeningBox({ }, ref) {
  const openingBoxRef = useRef(null);
  const closingBoxRef = useRef(null);
  const depositMoneyRef = useRef(null);
  const withdrawalMoneyRef = useRef(null);
  const { user, paymentMethodsState } = useAuth();
  const [btnDisable, setBtnDisable] = useState();

  const [modalDeposit, setModalDeposit] = useState(false);
  const [modalWithdrawal, setModalWithdrawal] = useState(false);
  const [modalOpeningBox, setModalOpeningBox] = useState(false);
  const [modalClosingBox, setModalClosingBox] = useState(false);
  const [modalRecentBox, setModalRecentBox] = useState(false);
  const [modalPrint, setModalPrint] = useState(false);
  const [modalPrintAudit, setModalPrintAudit] = useState(false);
  const [modalAutomaticDeposits, setModalAutomaticDeposits] = useState(false);

  const [, setOpeningBoxId] = useState();
  const [openedBox, setOpenedBox] = useState(false);
  const [boxInitialValue, setBoxInitialValue] = useState();
  const [boxFinalValue, setBoxFinalValue] = useState();
  const [boxTotalValue, setBoxTotalValue] = useState();
  const [automaticsDeposits, setAutomaticsDeposits] = useState();
  const [automaticsDepositsDetails, setAutomaticsDepositsDetails] = useState();
  const [manualDeposits, setManualDeposits] = useState();
  const [manualWithdrawals, setManualWithdrawals] = useState({});
  const [manualWithdrawal, setManualWithdrawal] = useState({
    description: 'Transf. de fundos',
  });

  const [totalManualDeposits, setTotalManualDeposits] = useState();
  const [totalAutomaticsDeposit, setTotalAutomaticDeposits] = useState();
  const [totalWithdrawalDeposits, setTotalWithdrawalDeposits] = useState();
  const [paymentsAutomatics, setPaymentsAutomatics] = useState();
  const [paymentsManual, setPaymentsManuals] = useState();
  const [boxClosingTime, setBoxClosingTime] = useState();
  const [boxOpeningTime, setBoxOpeningTime] = useState();
  const [userClose, setUserClose] = useState('');
  const [userOpen, setUserOpen] = useState('');
  const [cashFlowCategories, setCashFlowCategories] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [toReceive, setToReceive] = useState(null);
  const [toReceiveModalOpen, setToReceiveModalOpen] = useState(false);

  const [allPaymentMethods, setAllPaymentMethods] = useState([]);
  const [auditItems, setAuditItems] = useState();
  const page = window.location.pathname;

  const [transferCheckbox, setTransferCheckbox] = useState(false);
  const [despesasCheckbox, setDespesasCheckbox] = useState(false);
  const [bankAccountId, setBankAccountId] = useState(null);
  const [disableConfirmBtn, setDisableConfirmBtn] = useState(false);
  const componentRef = useRef(null);
  const componentWithdrawalRef = useRef(null);
  const printRef = useRef(null);

  const [userSelected, setUserSelected] = useState(null);

  const filtered = paymentsAutomatics?.filter(
    (item) => item.description === 'Dinheiro'
  );

  const filteredValue =
    filtered && filtered.length > 0 ? Number(filtered[0].value) : 0;

  const [finalValueTheory, setFinalValueTheory] = useState(
    Number(boxInitialValue || 0) +
    filteredValue +
    Number(totalManualDeposits || 0) -
    Number(totalWithdrawalDeposits || 0))

  useEffect(() => {
    setFinalValueTheory(
      Number(boxInitialValue || 0) +
      filteredValue +
      Number(totalManualDeposits || 0) -
      Number(totalWithdrawalDeposits || 0)
    )
  }, [boxInitialValue, filteredValue, totalManualDeposits, totalWithdrawalDeposits])

  const toggleModalDeposit = useCallback(() => {
    setModalDeposit(!modalDeposit);
  }, [modalDeposit]);

  function toggleModalOpenedBoxMessage() {
    setOpenedBox(!openedBox);
  }

  function toggleModalAutomaticDeposits() {
    setModalAutomaticDeposits(!modalAutomaticDeposits);
  }

  const getPaymentMethods = useCallback(async () => {
    const methods = paymentMethodsState.filter(
      (method) => method.available || method.keyword === 'online_ifood'
    );

    methods.sort((a, b) => Number(b.is_favorite) - Number(a.is_favorite));

    const parsedCompleteData = methods.map((data) => ({
      value: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
    }));

    setAllPaymentMethods(parsedCompleteData);
  }, [setAllPaymentMethods, paymentMethodsState]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  const toggleModalWithdrawal = useCallback(async () => {
    try {
      if (cashFlowCategories.length === 0) {
        const response = await api.get(
          '/restaurants/cash-flows/categories-detail'
        );

        const categoriesSelect = response?.data?.map((category) => {
          return {
            value: category.id,
            label: category.text,
          };
        });

        setCashFlowCategories(categoriesSelect);
      }

      if (bankAccounts.length === 0) {
        const response = await api.get(
          '/restaurants/cash-flows/bank-accounts',
          {
            params: {
              isMinimal: true,
            },
          }
        );

        const bankSelect = response?.data?.map((bank) => {
          return {
            value: bank.id,
            label: bank.name,
          };
        });

        bankSelect.push({
          value: -1,
          label: 'Outros',
        });

        setBankAccounts(bankSelect);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setModalWithdrawal(!modalWithdrawal);
      setTransferCheckbox(false);
      setDespesasCheckbox(false);
      setBankAccountId(null);
    }
  }, [cashFlowCategories, modalWithdrawal]);

  function toggleModalRecentBox() {
    setModalRecentBox(!modalRecentBox);
  }

  const toggleModalOpeningBox = useCallback(() => {
    setModalOpeningBox(!modalOpeningBox);
  }, [modalOpeningBox]);

  const toggleModalClosingBox = useCallback(() => {
    setModalClosingBox(!modalClosingBox);
  }, [modalClosingBox]);

  const toggleModalPrint = useCallback(() => {
    setModalPrint(!modalPrint);
  }, [modalPrint]);

  const toggleModalPrintAudit = useCallback(() => {
    if (
      modalPrintAudit &&
      page === '/operation' &&
      stillExistOpenSessionsData.length > 0
    ) {
      window.location.reload();
    }
    setModalPrintAudit(!modalPrintAudit);
  }, [modalPrintAudit]);

  const [userCashier, setUserCashier] = useState(null);
  const [userButtonsPermission, setUserButtonsPermission] = useState(false);

  const getIndividualsBoxValues = useCallback(
    async (id) => {
      try {
        const response = await api.get(
          `/restaurants/cashier-opening-event/summary/${id}`
        );

        const summary = response.data;

        setManualDeposits(summary.manual_deposits);
        setManualWithdrawals(summary.manual_withdrawals);
        setTotalAutomaticDeposits(summary.totals?.automatic_deposit);
        setTotalManualDeposits(summary.totals?.manual_deposit);
        setTotalWithdrawalDeposits(summary.totals?.manual_withdrawal);
        setPaymentsAutomatics(summary.automatic_deposits);
        setPaymentsManuals(summary.manual_deposits);
        setBoxInitialValue(Number(summary.opening?.initial_value).toFixed(2));
        setToReceive(summary.totals?.to_receive);

        setUserSelected(summary.opening?.owner_user_id || null);
        setUserButtonsPermission(
          summary.opening?.owner_user_id === user.user_id
        );
      } catch (err) {
        if (err?.response?.data?.errorType === 'cashier_opening_not_found') {
          if (page !== '/operation') {
            setOpenedBox(true);
          }
        } else {
          toast.error('Erro ao carregar valores');
        }
      }
    },
    [page]
  );

  useEffect(() => {
    if (userCashier) {
      // Value é o id do caixa
      getIndividualsBoxValues(userCashier?.value);
    }
  }, [userCashier]);

  const getBoxValuesDetails = useCallback(async () => {
    const user_id = userCashier?.value ? userCashier?.value : null;

    try {
      const response = await api.get(
        `/restaurants/cashier-opening-event/summary/${user_id}?group=${false}`
      );

      const summary = response.data;

      setAutomaticsDepositsDetails(summary.automatic_deposits);

      toggleModalAutomaticDeposits();
    } catch (error) {
      toast.error('Erro ao baixar detalhes de caixa de vendas');
      console.log(error.message);
    }
  }, [userCashier]);

  const getBoxValues = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/cashier-opening-event/summary/null'
      );

      const summary = response.data;

      setAutomaticsDeposits(summary.automatic_deposits);
      setManualDeposits(summary.manual_deposits);
      setManualWithdrawals(summary.manual_withdrawals);
      setTotalAutomaticDeposits(summary.totals?.automatic_deposit);
      setTotalManualDeposits(summary.totals?.manual_deposit);
      setTotalWithdrawalDeposits(summary.totals?.manual_withdrawal);
      setPaymentsAutomatics(summary.automatic_deposits);
      setPaymentsManuals(summary.manual_deposits);
      setBoxInitialValue(summary.opening?.initial_value);
      setToReceive(summary.totals?.to_receive);

      if (summary.opening) {
        setUserOpen(summary.opening.user_open?.name);
        setUserClose(summary.opening.user_close?.name);
      }

      setUserButtonsPermission(summary.opening?.owner_user_id === user.user_id);

      // Nenhum caixa aberto para o usuário atual (quando não é user_protected)
      if (summary.opening === null) {
        if (page !== '/operation') {
          setOpenedBox(true);
        }
      }
    } catch (err) {
      if (err?.response?.data?.errorType === 'cashier_opening_not_found') {
        if (page !== '/operation') {
          setOpenedBox(true);
        }
      } else {
        toast.error('Erro ao carregar valores');
      }
    }
  }, [page]);

  const [isModalOnlineDepositsOpened, setIsModalOnlineDepositsOpened] =
    useState(false);

  const [onlineDeposits, setOnlineDeposits] = useState([]);

  const toggleOnlineDepositisModal = useCallback(() => {
    setIsModalOnlineDepositsOpened(!isModalOnlineDepositsOpened);
  }, [isModalOnlineDepositsOpened]);

  const openingBox = useCallback(
    async (data) => {
      try {
        if (openingBoxRef?.current) {
          openingBoxRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          opening_value: Yup.string().required('Valor obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post('/restaurants/cashier-opening', {
          initial_value: data.opening_value,
        });

        const { online_deposits } = response.data;
        setOnlineDeposits(online_deposits);

        if (online_deposits.length > 0) {
          toggleOnlineDepositisModal();
        }

        toggleModalOpeningBox();
        getBoxValues();
        toast.success('Caixa aberto com sucesso.');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (openingBoxRef?.current) {
            openingBoxRef.current.setErrors(errors);
          }
        } else if (error.response.data.errorType === 'cashier_already_open') {
          toast.error('Caixa já aberto');
        } else {
          toast.error('Erro ao abrir o caixa');
        }
      }
    },
    [toggleModalOpeningBox, getBoxValues, toggleOnlineDepositisModal]
  );

  const [stillExistOpenSessionsData, setStillExistOpenSessionsData] = useState(
    []
  );

  const [stillExistOpenSessionsModal, setStillExistOpenSessionsModal] =
    useState(false);

  function toggleStillExistOpenSessionsModal() {
    setStillExistOpenSessionsModal(!stillExistOpenSessionsModal);
  }

  const closingBox = useCallback(
    async (data) => {
      setDisableConfirmBtn(true);

      try {
        if (closingBoxRef?.current) {
          closingBoxRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          final_value: Yup.string().required('Valor obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // Se tiver usuario selecionado enviará o id do caixa do mesmo para fechamento
        if (userCashier) {
          const resp = await api.put(`/restaurants/cashier-opening/close`, {
            owner_user_id: userCashier.user_id,
            final_value: data.final_value,
          });

          setBoxFinalValue(resp.data.final_value);
          setBoxInitialValue(resp.data.initial_value);
          setUserOpen(resp.data.user_open?.name);
          setUserClose(resp.data.user_close?.name);

          setBoxClosingTime(resp.data.closed_at);
          setBoxOpeningTime(resp.data.opened_at);

          toggleModalClosingBox();
          toggleModalPrintAudit();
          toast.success('Caixa fechado com sucesso.');
        } else {
          const resp = await api.put(`/restaurants/cashier-opening/close`, {
            final_value: data.final_value,
          });

          setBoxFinalValue(resp.data.final_value);
          setBoxInitialValue(resp.data.initial_value);
          setUserOpen(resp.data.user_open?.name);
          setUserClose(resp.data.user_close?.name);

          setBoxClosingTime(resp.data.closed_at);
          setBoxOpeningTime(resp.data.opened_at);

          toggleModalClosingBox();
          toggleModalPrintAudit();
          toast.success('Caixa fechado com sucesso.');
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (closingBoxRef?.current) {
            closingBoxRef.current.setErrors(errors);
          }
        } else if (
          error.response.data.errorType === 'cashier_opening_not_found'
        ) {
          toast.error('Não encontramos caixa aberta');
        } else if (
          error.response.data.errorType === 'still_exist_open_sessions'
        ) {
          toggleStillExistOpenSessionsModal();
          setStillExistOpenSessionsData(error.response.data.payload);
        } else {
          toast.error('Erro ao fechar o caixa');
        }
      } finally {
        setDisableConfirmBtn(false);
      }
    },
    [toggleModalClosingBox, toggleModalPrintAudit]
  );

  const depositMoney = useCallback(
    async (data) => {
      try {
        setBtnDisable(true);

        if (depositMoneyRef?.current) {
          depositMoneyRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          deposit_description: Yup.string().required('Descrição obrigatória'),
          deposit_value: Yup.string().required('Preço obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // await api.get('/restaurants/cashier-opening-event');

        await api.post('/restaurants/cashier-opening-event', {
          value: data.deposit_value,
          description: data.deposit_description,
        });

        toast.success('Valor adicionado com sucesso');

        getBoxValues();
        toggleModalDeposit();

        setBtnDisable(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (depositMoneyRef?.current) {
            depositMoneyRef.current.setErrors(errors);
          }
        } else if (
          error.response.data.errorType === 'cashier_opening_not_found'
        ) {
          toast.error('Não encontramos caixa aberta');
        } else {
          toast.error('Erro ao adicionar no caixa');
        }

        setBtnDisable(false);
      }
    },
    [getBoxValues, toggleModalDeposit]
  );

  const [printAvailable, setPrintAvailable] = useState(false);

  const withdrawalMoney = useCallback(
    async (data) => {
      setBtnDisable(true);
      try {
        if (withdrawalMoneyRef?.current) {
          withdrawalMoneyRef.current.setErrors({});
        }

        let schema;

        if (transferCheckbox) {
          if (bankAccountId === -1) {
            schema = Yup.object().shape({
              withdrawal_value: Yup.number()
                .typeError('Somente números')
                .min(0.1, 'Preço precisa ser maior que 0')
                .required('Preço obrigatório'),
              bank_account_id: Yup.string().required(
                'Conta bancária obrigatória'
              ),
              new_bank_account: Yup.string().required(
                'Nome da nova conta bancária obrigatório'
              ),
            });
          } else {
            schema = Yup.object().shape({
              withdrawal_value: Yup.number()
                .typeError('Somente números')
                .min(0.1, 'Preço precisa ser maior que 0')
                .required('Preço obrigatório'),
              bank_account_id: Yup.string().required(
                'Conta bancária obrigatória'
              ),
            });
          }
        } else {
          schema = Yup.object().shape({
            withdrawal_description: Yup.string().required(
              'Descrição obrigatória'
            ),
            cash_flow_category_subcategory_id: Yup.string().required(
              'Centro de custo obrigatório'
            ),
            withdrawal_value: Yup.number()
              .typeError('Somente números')
              .min(0.1, 'Preço precisa ser maior que 0')
              .required('Preço obrigatório'),
          });
        }

        console.log('schema', schema);
        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cashier-opening-event', {
          value: -Math.abs(data.withdrawal_value),
          description: data.withdrawal_description,
          cash_flow_category_subcategory_id:
            data.cash_flow_category_subcategory_id || null,
          is_transfer: transferCheckbox,
          bank_account_id: bankAccountId,
          new_bank_account: data.new_bank_account || null,
        });

        toast.success('Retirada feita com sucesso');

        if (printAvailable) {
          printRef.current.handlePrint();
        }

        getBoxValues();
        toggleModalWithdrawal();
        setTransferCheckbox(false);
        setDespesasCheckbox(false);
        setBankAccountId(null);
        setManualWithdrawal({});
        setBtnDisable(false);
        setPrintAvailable(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (withdrawalMoneyRef?.current) {
            withdrawalMoneyRef.current.setErrors(errors);
          }
        } else if (
          error.response.data.errorType === 'cashier_opening_not_found'
        ) {
          toast.error('Não encontramos caixa aberta');
        } else if (
          error.response.data.errorType ===
          'cashier_opening_insufficient_withdrawal'
        ) {
          toast.error(
            'Valor em caixa é insuficiente para este valor de sangria.'
          );
        } else {
          toast.error('Erro ao retirar do caixa');
        }
        setBtnDisable(false);
      }
    },
    [
      getBoxValues,
      toggleModalWithdrawal,
      transferCheckbox,
      bankAccountId,
      printAvailable,
    ]
  );

  const handleDeleteBoxStates = useCallback(() => {
    updateBox()
    // setAutomaticsDeposits('');
    // setPaymentsAutomatics([])
    // setTotalAutomaticDeposits('')
    // setAutomaticsDepositsDetails('');
    // setBoxFinalValue('');
    // setBoxInitialValue(null);
    // setBoxTotalValue('');
    // setManualDeposits('');
    // setManualWithdrawals('');
    // setFinalValueTheory(null)
    if (modalPrintAudit) {
      toggleModalPrintAudit();
    }

  }, [toggleModalPrintAudit, modalPrintAudit]);

  const updateBox = useCallback(() => {
    getBoxValues();
  }, [getBoxValues]);

  useImperativeHandle(ref, () => {
    return {
      updateBox,
    };
  });

  useEffect(() => {
    getBoxValues();
  }, [getBoxValues]);

  const userIsProtected = user?.user_protected || false;
  const canAccessAllCashiers =
    (user?.user_protected && user?.use_cashier_by_user) || false;

  const [permission, setPermission] = useState();
  const [permissionToRecentlyBtnBox, setPermissionToRecentlyBtnBox] =
    useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/users/role-permission/FinanceCashierOpen,FinanceRecentCashiers'
      );

      const { can_read } = response.data.FinanceCashierOpen;

      const recently_box_button = response.data.FinanceRecentCashiers.can_read;

      setPermission(can_read);
      setPermissionToRecentlyBtnBox(recently_box_button);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const [cashiers, setCashiers] = useState([]);

  // Busca os caixas abertos, e verifica se o caixa pode ser aberto
  const getCashiers = useCallback(async () => {
    const response = await api.get('/restaurants/current-cashier-openings');

    const filtered = response.data.map((cashier) => {
      return {
        label:
          cashier.owner_user.name === 'Total'
            ? 'Total dos caixas'
            : cashier.owner_user.name,
        value: cashier.id,
        user_id: cashier.owner_user.id,
      };
    });

    const adminUserCashier = filtered.find((item) => {
      return Number(item.user_id) === Number(user.user_id);
    });

    // Se o admin raiz (user_protected) não possui caixa aberto ainda,
    // perguntar se deseja abrir caixa ou não
    if (!adminUserCashier) {
      if (page !== '/operation') {
        setOpenedBox(true);
      }
    }

    setCashiers(filtered);
  }, [page, user.user_id]);

  useEffect(() => {
    // Busca os caixas atuais se o usuário tiver acesso
    if (canAccessAllCashiers && permission) {
      try {
        getCashiers();
      } catch (err) {
        console.log(err);
      }
    }
  }, [permission, canAccessAllCashiers, getCashiers]);

  const [isAuditClosingBoxOpened, setIsAuditClosingboxOpened] = useState(false);

  function toggleAuditClosingBox() {
    setIsAuditClosingboxOpened(!isAuditClosingBoxOpened);
  }

  const [arrayMethods, setArrayMethods] = useState([]);

  const [isModalClosingErrorOpened, setIsModalClosingErrorOpened] =
    useState(false);

  function toggleCashierClosingError() {
    setIsModalClosingErrorOpened(!isModalClosingErrorOpened);
  }

  async function handleSubmitAuditClosingBox(data) {
    setDisableConfirmBtn(true);

    try {
      const items = [];

      Object.keys(data).forEach((key, index) => {
        return (
          key !== 'final_value' &&
          key !== 'dinheiro' &&
          items.push({
            payment_method_id: Number(key),
            value: data[key] || 0,
          })
        );
      });

      if (userCashier) {
        const resp = await api.put(`/restaurants/cashier-opening/close`, {
          owner_user_id: userCashier.user_id,
          final_value: data.final_value,
          items,
        });

        toggleModalPrintAudit();
        setBoxFinalValue(resp.data.final_value);
        setBoxInitialValue(resp.data.initial_value);
        setUserClose(resp.data.user_close?.name);
        setUserOpen(resp.data.user_open?.name);
        setAuditItems(resp.data.audit);
        setBoxClosingTime(resp.data.closed_at);
        setBoxOpeningTime(resp.data.opened_at);
        toggleAuditClosingBox();
      } else {
        const resp = await api.put('/restaurants/cashier-opening/close', {
          final_value: data.final_value,
          items,
        });
        toggleModalPrintAudit();
        setBoxFinalValue(resp.data.final_value);
        setBoxInitialValue(resp.data.initial_value);
        setUserClose(resp.data.user_close?.name);
        setUserOpen(resp.data.user_open?.name);
        setAuditItems(resp.data.audit);
        setBoxClosingTime(resp.data.closed_at);
        setBoxOpeningTime(resp.data.opened_at);
        toggleAuditClosingBox();
      }
    } catch (error) {
      if (error.response.data.errorType === 'cashier_opening_not_found') {
        toast.error('Não encontramos caixa aberto.');
      } else if (
        error.response.data.errorType === 'all_sessions_must_be_completed'
      ) {
        toggleCashierClosingError();
      } else if (
        error.response.data.errorType === 'still_exist_open_sessions'
      ) {
        toggleStillExistOpenSessionsModal();
        setStillExistOpenSessionsData(error.response.data.payload);
      } else {
        toast.error('Erro ao fechar caixa');
      }
      console.log(error);
    } finally {
      setDisableConfirmBtn(false);
    }
  }

  function handleCloseBox() {
    toggleAuditClosingBox();
  }

  function changeTransferCheck() {
    setTransferCheckbox(!transferCheckbox);
    if (!transferCheckbox === false) {
      setBankAccountId(null);
    }
  }

  function changeDespesasCheck() {
    setDespesasCheckbox(!despesasCheckbox);
  }

  const [sessionState, setSessionState] = useState({});
  const [modalPaymentForm, setModalPaymentForm] = useState(false);

  function toggleModalPaymentForm() {
    setModalPaymentForm(!modalPaymentForm);
  }

  function openPaymentForm(session) {
    setSessionState(session);
    toggleModalPaymentForm();
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const setButtonSize =
    windowWidth < 1290 && window.location.pathname === '/operation';

  useEffect(() => {
    // Função para atualizar windowWidth quando a janela é redimensionada
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Adicionar um ouvinte de redimensionamento quando o componente é montado
    window.addEventListener('resize', handleResize);

    // Remover o ouvinte de redimensionamento quando o componente é desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [onlinePaymentsMethods, setOnlinePaymentsMethods] = useState(false);

  const getOnlinePaymentMethodsValues = useCallback(async () => {
    const url = userCashier
      ? `/restaurants/cashier-opening/methods?owner_id=${userCashier.user_id}`
      : `/restaurants/cashier-opening/methods`;
    const response = await api.get(url);

    const methods = response.data.filter(
      (item) =>
        (item.is_online && (item.available || item.delivery_accepts)) ||
        (item.is_online && item.keyword === 'cupom_ifood')
    );

    setOnlinePaymentsMethods(methods);
  }, [userCashier]);

  useEffect(() => {
    if (isAuditClosingBoxOpened) {
      getOnlinePaymentMethodsValues();
    }
  }, [getOnlinePaymentMethodsValues, isAuditClosingBoxOpened]);

  function changePrint() {
    setPrintAvailable(!printAvailable);
  }

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container page={page}>
      <Modal
        isOpen={isModalClosingErrorOpened}
        toggle={toggleCashierClosingError}
      >
        <ModalBody>
          <p style={{ textAlign: 'center' }}>
            <FaExclamationTriangle size={31} color="#ff3a2c" />
          </p>
          <p>
            Você ainda possui comandas sem emissão fiscal. Emita as notas ou
            solicite a gestão para que desative essa condição. Você também pode
            finalizar as comandas em massa no menu de comandas, clicando{' '}
            <LinkStyled to="/operation/bills">Aqui</LinkStyled>
          </p>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isModalOnlineDepositsOpened}
        toggle={toggleOnlineDepositisModal}
      >
        <ModalBody>
          <strong>
            Os valores a seguir, que foram pagamentos online feitos enquanto seu
            caixa estava fechado, entrarão no caixa que você está abrindo:
          </strong>
          {onlineDeposits &&
            onlineDeposits.length > 0 &&
            onlineDeposits
              .filter((item) => item.total > 0)
              .map((deposit) => (
                <p>
                  {deposit.description} - R$ {deposit.total.toFixed(2)}
                </p>
              ))}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              toggleOnlineDepositisModal();
              setOnlineDeposits([]);
            }}
          >
            Ok, entendi
          </button>
        </ModalFooter>
      </Modal>

      <Modal toggle={toggleModalPaymentForm} isOpen={modalPaymentForm}>
        <PaymentFormStillOpenSessions
          session={sessionState}
          sessions={stillExistOpenSessionsData}
        />
      </Modal>

      <Modal
        isOpen={stillExistOpenSessionsModal}
        toggle={toggleStillExistOpenSessionsModal}
      >
        <ModalHeader>
          <div style={{ display: 'flex', width: '98%' }}>
            <span>
              Não foi possível fechar o caixa, pois existem comandas em aberto
              ou sem informação de pagamento. Segue abaixo:
            </span>
            <FiX
              color="#FF2C3A"
              size={17}
              onClick={toggleStillExistOpenSessionsModal}
              cursor="pointer"
              style={{ position: 'absolute', right: 10, top: 10 }}
            />
          </div>
        </ModalHeader>
        <ModalBody>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontWeight: 'bold', width: '25%' }}>Comanda</span>
            <span style={{ fontWeight: 'bold', width: '27%' }}>
              Data / Hora
            </span>
            <span style={{ fontWeight: 'bold' }}>Ação</span>
          </div>
          {stillExistOpenSessionsData &&
            stillExistOpenSessionsData.length > 0 &&
            stillExistOpenSessionsData.map((session) => (
              <div
                key={session.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 10,
                }}
              >
                <span style={{ width: '25%' }}>
                  #
                  {session.is_delivery
                    ? session.attendance_password
                    : session.id}
                </span>
                <span style={{ width: '27%' }}>
                  {formatCompleteDate(session?.createdAt)}
                </span>
                <span>
                  <PersonalButton
                    message="Caixa"
                    color="#2ec0b7"
                    onClick={() => {
                      openPaymentForm(session);
                    }}
                  />
                </span>
              </div>
            ))}
        </ModalBody>
      </Modal>

      <ButtonArea canAccessAllCashiers={canAccessAllCashiers}>
        {canAccessAllCashiers ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form>
              <Select
                placeholder="Total dos caixas"
                name="select_cashier"
                size={page !== '/operation' ? 250 : 168}
                options={cashiers}
                onChange={(e) => setUserCashier(e)}
                label="Selecione um caixa"
              />
            </Form>

            {canAccessAllCashiers && (
              <>
                {!boxInitialValue ? (
                  <PersonalButton
                    onClick={toggleModalOpeningBox}
                    message={setButtonSize ? '$ Abrir' : '$ Abrir caixa'}
                    color="#2EC9B7"
                    style={{ margin: '28px 0 0 5px' }}
                  />
                ) : (
                  <PersonalButton
                    style={{ margin: '28px 0 0 5px' }}
                    type="button"
                    onClick={handleCloseBox}
                    message={setButtonSize ? '$ Fechar' : '$ Fechar caixa'}
                    color="#FF2C3A"
                  />
                )}
              </>
            )}
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!boxInitialValue ? (
              <PersonalButton
                onClick={toggleModalOpeningBox}
                message={setButtonSize ? '$ Abrir' : '$ Abrir caixa'}
                color="#2EC9B7"
                style={{ margin: '5px 0 0 5px' }}
              />
            ) : (
              <PersonalButton
                style={{ margin: '5px 0 0 5px' }}
                type="button"
                onClick={handleCloseBox}
                message={setButtonSize ? '$ Fechar' : '$ Fechar caixa'}
                color="#FF2C3A"
              />
            )}
          </div>
        )}

        <div style={{ display: 'flex' }}>
          {!user.audit_cashier ? (
            <PersonalOutlineButton
              type="button"
              onClick={toggleModalPrint}
              color="transparent"
              message="Relatório"
              borderColor="transparent"
              fontColor="#2EC9B7"
              style={{ textDecoration: 'underline' }}
            />
          ) : (
            ''
          )}
          {(user.audit_cashier && permission && permissionToRecentlyBtnBox) ||
            (!user.audit_cashier && permissionToRecentlyBtnBox) ? (
            <PersonalOutlineButton
              type="button"
              onClick={toggleModalRecentBox}
              color="transparent"
              message={setButtonSize ? 'Recentes' : 'Caixas recentes'}
              borderColor="#FF2C3A"
              fontColor="#FF2C3A"
            />
          ) : (
            ''
          )}
        </div>
      </ButtonArea>

      <Card page={page}>
        {user.audit_cashier ? (
          <>
            <div style={{ marginTop: 15 }}>
              {(userButtonsPermission || !userSelected) && (
                <div style={{ display: 'flex' }}>
                  <PersonalOutlineButton
                    type="button"
                    onClick={toggleModalDeposit}
                    message="+ Adicionar"
                    color="transparent"
                    borderColor="#2EC9B7"
                    fontColor="#2EC9B7"
                  />
                  <PersonalOutlineButton
                    type="button"
                    onClick={toggleModalWithdrawal}
                    message="- Retirar"
                    color="transparent"
                    borderColor="#FF2C3A"
                    fontColor="#FF2C3A"
                  />
                </div>
              )}
              {/* <PersonalButton style={{ marginRight: 90 }} type="button" onClick={handleCloseBox} message="Fechar caixa" color="#FF2C3A" /> */}
            </div>
            <div
              style={{
                height: 450,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ErrorContainer>
                <p>
                  Estas informações estão ocultas pois o restaurante está
                  configurado no modo “Caixa às cegas”. Para mais informações,
                  contate o proprietário do estabelecimento.
                </p>
              </ErrorContainer>
            </div>
          </>
        ) : (
          <>
            <MdUpdate
              color="#2EC9B7"
              size={21}
              onClick={updateBox}
              cursor="pointer"
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 8,
              }}
            >
              <TotalArea page={page}>
                <strong>Abertura</strong>
                <span>{boxInitialValue ? formatValue(boxInitialValue) : "R$ 0,00"}</span>
              </TotalArea>
              <TotalArea style={{ position: 'relative' }} page={page}>
                <strong>
                  <strong>Vendas</strong>
                  <FaEye
                    cursor="pointer"
                    title="Ver detalhes"
                    onClick={getBoxValuesDetails}
                    color="#2EC9B7"
                    size={16}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: 10,
                      transform: 'translateY(-50%)',
                    }}
                  />
                </strong>
                <span>{formatValue(totalAutomaticsDeposit)}</span>
              </TotalArea>
              <TotalArea page={page}>
                <strong>Total parcial</strong>
                <span>{finalValueTheory ? formatValue(finalValueTheory) : "R$ 0,00"} </span>
              </TotalArea>
              {(!user.use_cashier_by_user || user.user_protected) && (
                <TotalArea page={page}>
                  <strong>A receber</strong>
                  {toReceive ? (
                    <FaEye
                      cursor="pointer"
                      title="Ver detalhes"
                      onClick={() => setToReceiveModalOpen(true)}
                      color="#2EC9B7"
                      size={16}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        right: 10,
                        transform: 'translateY(-50%)',
                      }}
                    />
                  ) : ""}
                  <span>{toReceive ? formatValue(toReceive) : '-'} </span>
                </TotalArea>
              )}
            </div>

            <Row style={{ marginTop: 15 }}>
              {(userButtonsPermission || !userSelected) && (
                <div style={{ display: 'flex' }}>
                  <PersonalOutlineButton
                    type="button"
                    onClick={toggleModalDeposit}
                    message="+ Adicionar"
                    color="transparent"
                    borderColor="#2EC9B7"
                    fontColor="#2EC9B7"
                  />
                  <PersonalOutlineButton
                    type="button"
                    onClick={toggleModalWithdrawal}
                    message="- Retirar"
                    color="transparent"
                    borderColor="#FF2C3A"
                    fontColor="#FF2C3A"
                  />
                </div>
              )}
              {/* <PersonalButton style={{ marginRight: 90 }} type="button" onClick={handleCloseBox} message="Fechar caixa" color="#FF2C3A" /> */}
            </Row>

            {manualDeposits && manualDeposits.length > 0 && (
              <StyledTable operation={page === '/operation'}>
                <thead>
                  <tr>
                    <th>
                      {' '}
                      <FiTrendingUp
                        color="#2EC9B7"
                        style={{ marginRight: 4 }}
                      />
                      Entradas Manuais
                    </th>
                    <th>Valor do depósito</th>
                    <th>Descrição do depósito</th>
                  </tr>
                </thead>
                <tbody>
                  {manualDeposits && manualDeposits.length > 0 ? (
                    manualDeposits.map((item) => (
                      <tr key={item.id}>
                        <td>{formatCompleteDate(item.createdAt)}</td>
                        <td>R$ {item.value}</td>
                        <td>{item.description}</td>
                        <td></td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan="4" style={{ fontSize: 14, color: '#979797' }}>
                      Sem entradas manuais no momento
                    </td>
                  )}
                </tbody>
              </StyledTable>
            )}

            {manualWithdrawals && manualWithdrawals.length > 0 && (
              <StyledTable operation={page === '/operation'}>
                <thead>
                  <tr>
                    <th>
                      <FiTrendingDown
                        color="#FF2C3A"
                        style={{ marginRight: 4 }}
                      />{' '}
                      Saídas Manuais
                    </th>
                    <th>Valor da retirada</th>
                    <th>Descrição da retirada</th>
                  </tr>
                </thead>
                <tbody>
                  {manualWithdrawals && manualWithdrawals.length > 0 ? (
                    manualWithdrawals.map((item) => (
                      <tr key={item.id}>
                        <td>{formatCompleteDate(item.createdAt)}</td>
                        <td>R$ {item.value}</td>
                        <td>{item.description}</td>
                        <td>
                          <ReactToPrint
                            onBeforeGetContent={async () => {
                              await setManualWithdrawal(item);
                            }}
                            trigger={() => (
                              <FaPrint color="#FF2C3A" cursor="pointer" />
                            )}
                            content={() => componentRef.current}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="4"
                        style={{ fontSize: 14, color: '#979797' }}
                      >
                        Sem saídas manuais no momento
                      </td>
                    </tr>
                  )}
                </tbody>
              </StyledTable>
            )}

            {/* 
            <TotalValueArea style={{ borderBottom: "1px solid #33333320" }}>
              <strong>Total parcial</strong>

              <Deposits>
                <p>R$ {finalValueTheory.toFixed(2)}</p>
              </Deposits>
            </TotalValueArea> */}

            <StyledTable operation={page === '/operation'}>
              <thead>
                <tr>
                  <th>Caixa de vendas</th>
                  <th style={{ textAlign: 'right' }}>Valor</th>
                </tr>
              </thead>
              <tbody>
                {paymentsAutomatics &&
                  paymentsAutomatics.length > 0 &&
                  paymentsAutomatics.map((item) => (
                    <tr key={item.id}>
                      <td>{item.description}</td>

                      <td
                        style={{ textAlign: 'right' }}
                      >{`R$ ${item?.value?.toFixed(2)}`}</td>
                    </tr>
                  ))}
              </tbody>
            </StyledTable>
          </>
        )}
      </Card>

      <ToReceiveModal
        isOpen={toReceiveModalOpen}
        toggle={() => setToReceiveModalOpen(false)}
      />

      <Modal isOpen={modalOpeningBox} toggle={toggleModalOpeningBox}>
        <Form onSubmit={openingBox} ref={openingBoxRef}>
          <ModalBody>
            <Input
              name="opening_value"
              label="Fundo de caixa (valor total em espécie no caixa)"
              type="number"
              step="0.01"
              min="0"
            />
          </ModalBody>
          <ModalFooter>
            <Button operationType="deposit" type="submit">
              Abrir caixa
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={isAuditClosingBoxOpened} toggle={toggleAuditClosingBox}>
        <Form
          onSubmit={handleSubmitAuditClosingBox}
          ref={openingBoxRef}
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        >
          <ModalHeader>
            <h3>Fechamento de caixa</h3>
            {!user.audit_cashier && (
              <h5>
                Informe cada método de pagamento. Apenas o "Fundo de caixa
                final(Dinheiro)" é obrigatório.
              </h5>
            )}
          </ModalHeader>
          <ModalBody
            style={{ maxHeight: 'calc(100vh - 250px)', overflowY: 'auto' }}
          >
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Col lg="7">
                {' '}
                <span style={{ marginRight: 10 }}>
                  Fundo de caixa final (Dinheiro) *
                </span>{' '}
                <p style={{ color: 'red', fontWeight: 'bold' }}>
                  OBRIGATÓRIO *
                </p>
              </Col>

              <Col lg="5">
                {' '}
                <Input
                  name="final_value"
                  placeholder="R$"
                  type="number"
                  step="0.01"
                  min="0"
                />
              </Col>
            </Row>
            {allPaymentMethods &&
              allPaymentMethods.length > 0 &&
              allPaymentMethods
                .filter(
                  (method) =>
                    method.keyword !== 'dinheiro' &&
                    method.keyword !== 'prazo' &&
                    method.keyword !== 'credit_card_auto' &&
                    method.keyword !== 'pix_auto' &&
                    method.keyword !== 'online_ifood' &&
                    method.keyword !== 'cupom_ifood'
                )
                .map((method, index) => (
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    key={method.id}
                  >
                    <Col lg="7">
                      <span>{method.label}</span>
                    </Col>

                    <Col lg="5">
                      <Input
                        name={method.value?.toString()}
                        placeholder={
                          user.audit_cashier ? 'R$' : 'R$ (opcional)'
                        }
                        type="number"
                        step="0.01"
                        min="0"
                      />
                    </Col>

                    {/*                
                    {automaticsDepositsDetails &&
                      automaticsDepositsDetails.length > 0 &&
                      automaticsDepositsDetails.filter(item => item).map((item) => (
                        <React.Fragment key={item.id}>

                          <Col lg="7">R$ {item.value}</Col>
                          <Col lg="5">{item.description}</Col>
                        </React.Fragment >
                      ))} */}
                  </Row>
                ))}

            {onlinePaymentsMethods &&
              onlinePaymentsMethods.length > 0 &&
              onlinePaymentsMethods.map((item) => (
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  key={item.id}
                >
                  <Col lg="7">
                    <span>{item.name}</span>
                  </Col>

                  <Col lg="5">
                    <Input
                      name={item.id?.toString()}
                      defaultValue={item.value}
                      type="number"
                      step="0.01"
                      min="0"
                      disabled
                      key={item.value}
                    />
                  </Col>
                </Row>
              ))}
          </ModalBody>
          <ModalFooter>
            <PersonalButton
              type="button"
              onClick={toggleAuditClosingBox}
              disabled={disableConfirmBtn}
              message="Cancelar"
              color="#FF2C3A"
            />

            <PersonalButton
              type="submit"
              disabled={disableConfirmBtn}
              message="Confirmar e Fechar"
              color="#2EC9B7"
            />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={openedBox} toggle={toggleModalOpenedBoxMessage}>
        <ModalBody>
          <h3>Oops, não encontramos caixa aberto no momento.</h3>
        </ModalBody>
        <ModalFooter>
          <Button
            operationType="deposit"
            type="button"
            onClick={() => {
              toggleModalOpeningBox();
              toggleModalOpenedBoxMessage();
            }}
          >
            Desejo abrir caixa agora
          </Button>

          <Button
            operationType="withdrawal"
            type="button"
            onClick={toggleModalOpenedBoxMessage}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalClosingBox} toggle={toggleModalClosingBox}>
        <Form onSubmit={closingBox} ref={closingBoxRef}>
          <ModalBody>
            <Input
              name="final_value"
              label="Fundo de caixa final"
              type="number"
              step="0.01"
              min="0"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              operationType="withdrawal"
              type="submit"
              disabled={disableConfirmBtn}
            >
              Fechar caixa
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalDeposit} toggle={toggleModalDeposit}>
        <Form onSubmit={depositMoney} ref={depositMoneyRef}>
          <ModalBody>
            <Input
              name="deposit_value"
              label="Valor do depósito"
              type="number"
              step="0.01"
              min="0"
            />

            <Input name="deposit_description" label="Descrição do depósito" />
          </ModalBody>
          <ModalFooter>
            <Button operationType="deposit" type="submit" disabled={btnDisable}>
              {btnDisable ? <Spinner /> : '+ Adicionar'}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalWithdrawal} toggle={toggleModalWithdrawal}>
        <Form onSubmit={withdrawalMoney} ref={withdrawalMoneyRef}>
          <ModalBody>
            <Input
              name="withdrawal_value"
              label="Valor da retirada"
              type="number"
              step="0.01"
              min="0"
              onChange={(e) => {
                setManualWithdrawal({
                  ...manualWithdrawal,
                  value: e.target.value,
                });
              }}
            />

            <CheckboxInput
              label="Transferência de fundos"
              name="transferencia_fundos"
              onClick={() => changeTransferCheck()}
              disabled={despesasCheckbox}
            />
            <p style={{ fontSize: 13 }}>
              Permite que você transfira seu dinheiro para outra conta bancária
              sem gerar despesas
            </p>

            {transferCheckbox && (
              <>
                <Select
                  label="Conta bancária"
                  name="bank_account_id"
                  placeholder="Selecione"
                  options={bankAccounts}
                  onChange={(e) => {
                    setBankAccountId(e.value);
                    setManualWithdrawal({
                      ...manualWithdrawal,
                      bank_account: { id: e.value, name: e.label },
                    });
                  }}
                />

                {bankAccountId === -1 && (
                  <Input
                    name="new_bank_account"
                    placeholder="Digite o nome do banco a ser cadastrado"
                  />
                )}
              </>
            )}

            <CheckboxInput
              label="Despesas"
              name="despesas"
              onClick={() => changeDespesasCheck()}
              disabled={transferCheckbox}
            />
            <p style={{ fontSize: 13 }}>
              Gera um lançamento no "Contas a Pagar"
            </p>

            {despesasCheckbox && (
              <>
                <Input
                  name="withdrawal_description"
                  label="Descrição da retirada"
                  onChange={(e) => {
                    setManualWithdrawal({
                      ...manualWithdrawal,
                      description: e.target.value,
                    });
                  }}
                />

                <Select
                  label="Centro de Custo"
                  name="cash_flow_category_subcategory_id"
                  placeholder="Categoria do lançamento"
                  options={cashFlowCategories}
                  onChange={(e) => {
                    setManualWithdrawal({
                      ...manualWithdrawal,
                      cash_flow_category: e.label,
                    });
                  }}
                />
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div>
              <CheckboxInput
                label="Imprimir comprovante"
                name="despesas"
                onClick={() => changePrint()}
                disabled={!transferCheckbox && !despesasCheckbox}
              />
            </div>

            <ReactToPrint
              ref={printRef}
              trigger={() => (
                <Button
                  operationType="withdrawal"
                  type="button"
                  disabled={btnDisable}
                  style={{ marginLeft: 'auto', display: 'none' }}
                >
                  {btnDisable ? <Spinner /> : '- Retirar'}
                </Button>
              )}
              content={() => componentWithdrawalRef.current}
            />

            <Button
              operationType="withdrawal"
              type="submit"
              disabled={btnDisable}
              style={{ marginLeft: 'auto' }}
            >
              {btnDisable ? <Spinner /> : '- Retirar'}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={modalAutomaticDeposits}
        toggle={toggleModalAutomaticDeposits}
      >
        <ModalBody>
          <strong>Vendas</strong>
          <InformationDiv
            containerStyles={{
              marginTop: 8,
              padding: '8px 12px',
              gap: 8,
            }}
            title=""
            type="info"
            text="São listados pagamentos apenas no período de abertura do caixa atual."
          />
          {automaticsDepositsDetails &&
            automaticsDepositsDetails.length > 0 &&
            automaticsDepositsDetails.map((item) => (
              <Deposits key={item.id}>
                <p>{formatCompleteDate(item.createdAt)}</p>
                <p>R$ {item.value}</p>
                <p>{item.description}</p>
              </Deposits>
            ))}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={toggleModalAutomaticDeposits}
            operationType="report"
          >
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalPrint} toggle={toggleModalPrint}>
        <ModalBody>
          <PrintOpeningBoxAudit
            paymentsAutomatics={paymentsAutomatics}
            paymentsManual={paymentsManual}
            boxInitialValue={boxInitialValue}
            boxTotalValue={boxTotalValue}
            boxFinalValue={boxFinalValue}
            totalAutomaticsDeposit={totalAutomaticsDeposit}
            totalManualDeposits={totalManualDeposits}
            totalWithdrawalDeposits={totalWithdrawalDeposits}
            handleDeleteBoxStates={handleDeleteBoxStates}
            manualDeposits={manualDeposits}
            manualWithdrawals={manualWithdrawals}
            userClose={userClose}
            userOpen={userOpen}
            boxClosingTime={boxClosingTime}
            boxOpeningTime={boxOpeningTime}
            boxId={null}
            modalPrintAudit={modalPrint}
            arrayMethods={arrayMethods}
            auditItems={auditItems}
            isClosing={false}
          />
        </ModalBody>
      </Modal>

      <Modal isOpen={modalPrintAudit} toggle={toggleModalPrintAudit} onClosed={handleDeleteBoxStates}>
        <ModalBody>
          <PrintOpeningBoxAudit
            paymentsAutomatics={paymentsAutomatics}
            paymentsManual={paymentsManual}
            boxInitialValue={boxInitialValue}
            boxTotalValue={boxTotalValue}
            boxFinalValue={boxFinalValue}
            totalAutomaticsDeposit={totalAutomaticsDeposit}
            totalManualDeposits={totalManualDeposits}
            totalWithdrawalDeposits={totalWithdrawalDeposits}
            handleDeleteBoxStates={handleDeleteBoxStates}
            manualDeposits={manualDeposits}
            manualWithdrawals={manualWithdrawals}
            userClose={userClose}
            userOpen={userOpen}
            arrayMethods={arrayMethods}
            auditItems={auditItems}
            modalPrintAudit={modalPrintAudit}
            boxClosingTime={boxClosingTime}
            boxOpeningTime={boxOpeningTime}
            boxId={null}
            isClosing={true}
          />
        </ModalBody>
      </Modal>

      <Modal isOpen={modalRecentBox} toggle={toggleModalRecentBox} size="lg">
        <ReportOpeningBox />
      </Modal>

      <DisplayNoneImprimir>
        <PrintManualCashierWithdrawal
          item={manualWithdrawal}
          restaurant={user}
          ref={componentRef}
        />
      </DisplayNoneImprimir>

      <DisplayNoneImprimir>
        <PrintManualCashierWithdrawal
          item={manualWithdrawal}
          restaurant={user}
          ref={componentWithdrawalRef}
        />
      </DisplayNoneImprimir>
    </Container>
  );
}

export default forwardRef(OpeningBox);
