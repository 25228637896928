import styled from 'styled-components';

import DatePicker from 'react-datepicker';

export const Wrapper = styled.div`
  padding: 20px;
  height: calc(100vh - 48px);
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

export const Card = styled.div`
  width: 100%;
  padding: 20px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const Content = styled.div`
  width: 100%;
  heigth: auto;
`;
export const TablesPlace = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  width: 100%;
  min-height: 300px;
  background-color: white;
  border-radius: 8px;
  overflow-x: auto;
`;

export const BillExplanation = styled.div`
  width: 50%;
  margin-left: 60px;
  margin-top: 20px;
`;

export const Badge = styled.div`
  width: 16px;
  height: 16px;
  font-size: 13px;
  text-align: center;
  border-radius: 50%;
  background-color: red;
  color: white;
  margin-right: 5px;
`;

export const BadgeDiv = styled.div`
  display: flex;
`;

export const DateSelectorDiv = styled.div`
  width: 90%;
  height: 60px;
  display: flex;
  clear: both;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 20px;
  flex-direction: row;
`;

export const DateDiv = styled.div`
  float: left;
  left: 0;
  margin-left: 15px;
`;

export const SubmitDate = styled.button`
  height: 30px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }
`;

export const SelectDateButton = styled.button`
  height: 40px;
  border-radius: 20px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }

  :selected {
    border: none;
  }
`;

export const ButtonChangeOrdenation = styled.button`
  margin-top: 16px;
  border-radius: 7px;
  border: none;
  background-color: #ff403d;
  color: #fff;
  font-size: 10px;
  width: 110px;
  height: 30px;

  & + button {
    margin-left: 8px;
  }
`;

export const DataPickerArea = styled.div`
  display: inline-block;
`;

export const DatePickerStyled = styled(DatePicker)`
  height: 40px;
  padding: 10px;

  margin-right: 15px;
  margin-left: 10px;

  border-radius: 20px;
  align-self: center;
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 20px;
  }
`;

export const FilterArea = styled.div`
  width: 250px;
  padding: 20px;
  background-color: #fff;
  position: absolute;
  font-size: 16px;
  top: 43px;
  right: 192px;
  border-radius: 8px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
`
