import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 10px;
`;

export const Content = styled.div`
  display: flex;

  justify-content: flex-start;

  font-size: 18px;
  color: #3d3d3d;

  p {
    text-align: center;
  }
`;

export const CheckboxInputStyled = styled.div`
  input[type='checkbox'] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: #2ec9b7;
    width: 17px;
    height: 17px;
    border: 0.15em solid #2ec9b7;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    margin-right: 8px;
  }

  input[type='checkbox']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #2ec9b7;
    transform-origin: bottom left;

    clip-path: ${(props) =>
    props.allChecksChecked
      ? 'inset(50% 20% 44% 10%);'
      : 'polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%)'};
  }

  input[type='checkbox']:checked::before {
    transform: scale(1);
    background-color: #2ec9b7;
  }

  .form-control + .form-control {
    margin-top: 1em;
  }

  input[type='checkbox']:disabled {
    color: #3c3c3c;
    cursor: not-allowed;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  min-width: 400px;
  align-items: center;
  margin-top: 10px;
  border-spacing: 0 20px;

  th,
  td {
    padding: 10px;
  }

  thead {
    border-bottom: none;
    text-align: left;
    background: #efefef;
    color: #6c6c6c;

    tr,
    th {
      border-color: #fff;
      border-style: solid;
      border-width: 4px;
      border-left: none;
    }

    /* tr {
      th:last-child {
        text-align: right;
      }
    } */
  }
  tbody {
    td {
      border-bottom: 1px solid #dee2e6;
    }
    /* td:last-child {
      text-align: right;
    } */

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const Header = styled.div`
  margin: 10px 30px 10px 30px;
  display: flex;
  justify-content: space-between;

  h2 {
    font-size: 30px;
    font-weight: bold;
  }
`;

export const Card = styled.div`
  width: 95%;
  min-height: 730px;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.white};
  /* filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1)); */
  border-radius: 5px;
  padding: 20px;
  display: flex;
`;

export const AddKitchenCard = styled.button`
  width: 200px;
  height: 200px;
  color: #2ec9b7;
  border: 2px solid #2ec9b7;
  border-radius: 5px;
  background: #fff;

  p {
    color: #2ec9b7;
    font-weight: bold;
    margin-top: 10px;
    font-size: 18px;
  }
`;

export const ChooseKitchenCard = styled(Link)`
  width: 200px;
  height: 200px;
  border: none;
  border-radius: 5px;
  background: #2ec9b7;
  margin-left: 10px;
  text-decoration: none;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    color: #fff;
  }

  p {
    color: #fff;
    font-weight: bold;
    margin-top: 10px;
    font-size: 18px;
  }
`;

export const ScreenOne = styled.div`
  min-height: 600px;
  max-height: 900px;
  overflow: auto;
  padding: 20px;
  transform: ${(props) =>
    props.active ? 'translateX(0%)' : 'translateX(-90%)'};

  transition: all 800ms ease-in-out;

  opacity: ${(props) => (props.active ? '1' : '0')};
  padding-bottom: 720px;
  position: absolute;
  width: 97%;

  /* @media (max-width: 1300px) {
    padding-bottom: 720px;
  }

  ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
      padding-bottom: 720px;
    `} */
`;

export const InputStyled = styled.input`
  width: 65px;
  height: 38px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  text-align: center;
  margin-right: 8px;
`;

export const ScreenTwo = styled.div`
  min-height: 600px;
  max-height: 900px;
  overflow: auto;
  padding: 20px;
  transform: ${(props) =>
    props.active ? 'translateX(0%)' : 'translateX(100%)'};
  transition: all 800ms ease-in-out;

  opacity: ${(props) => (props.active ? '1' : '0')};
  padding-bottom: 320px;
  position: absolute;
  width: 97%;

  @media (max-width: 1300px) {
    padding-bottom: 720px;
  }

  ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
      padding-bottom: 720px;
    `}
`;

export const ButtonsArea = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 97%;
  bottom: 15px;
  padding-right: 25px;
  padding-left: 20px;
  padding-top: 20px;

  border-top: 1px solid #c4c4c4;
  background: #fff;

  bottom: 0px;
`;