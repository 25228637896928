import styled, { css } from 'styled-components';

export const Pedido = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: solid #dee2e6 1px;
  border-radius: 5px;

  &:not(:last-child) {
    border-bottom: solid #dee2e6 1px;
  }
`;

export const Header = styled.div`
  height: 0px;
  width: 100%;
  display: flex;
  position: absolute;
`;

export const OrderNumber = styled.span`
  font-weight: bold;
  font-size: 16px;
  margin-left: 8px;
`;

export const Time = styled.span`
  color: #33333390;
  text-align: left;
`;

export const OrdersInfo = styled.div`
  display: flex;
  align-items: center;

  div {
    & + div {
      margin-left: 16px;
    }

    p {
      margin-top: 8px;
    }
  }
`;

export const InfoDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  width: 120px;
  ${(props) => props.grow && 'flex-grow: 1;'}
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  gap: 5px;
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
  align-self: stretch;
  align-items: ${({ onEnd }) => (onEnd ? 'flex-end' : 'flex-start')};

  ${(props) => props.grow && 'flex-grow: 1;'}
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const CardColumnItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PedidoItems = styled.div`
  height: 30px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  left: 0;
  font-size: 1.3em;
  margin-top: 4px;
  margin-left: 10px;
  border: none;
  background: white;
`;

export const Eye = styled.button`
  height: 30px;
  background: white;
`;

export const Pencil = styled.button`
  height: 30px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 50px;
  border: none;
  background: white;
`;

export const TableNumber = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;

  @media (max-width: 410px) {
    margin-left: 130px;
    font-size: 12px;
  }
`;

export const PhoneNumber = styled.span`
  color: #333333;
  text-align: left;
  font-size: 16px;

  @media (max-width: 410px) {
    margin-left: 130px;
    font-size: 12px;
  }
`;

export const DeleteAndEditActions = styled.div`
  height: 30px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

export const AcceptButton = styled.button`
  border-radius: 8px;
  padding: 5px;
  height: 30px;
  width: 100px;

  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #01afff;
  font-size: 14px;
  display: flex;

  text-align: center;

  svg {
    margin-right: 3px;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const SeeButton = styled.button`
  width: 100px;
  height: 30px;
  border-radius: 8px;
  padding: 5px;

  color: white;
  align-self: center;

  border: none;
  background: #ffa814;
  font-size: 14px;
  display: flex;

  text-align: center;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }

  svg {
    margin-right: 3px;
  }
`;

export const CancelButton = styled.button`
  width: 100px;
  height: 30px;
  border-radius: 8px;
  padding: 5px;
  font-size: 14px;
  color: white;
  align-self: center;
  border: none;
  background: #ff2c3a;

  display: flex;
  text-align: center;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }

  :disabled {
    filter: opacity(50%);
  }

  div {
    margin: auto;
    text-align: center;
  }

  svg {
    margin-right: 3px;
  }
`;

export const Badge = styled.div`
  color: #fff;
  padding: 5px;
  border-radius: 7px;
  font-size: 12px;

  ${(props) =>
    props.type === 'newOrder' &&
    css`
      background-color: #ff403d;
    `};

  ${(props) =>
    props.type === 'orderDelivered' &&
    css`
      background-color: purple;
    `};

  ${(props) =>
    props.type === 'orderFinished' &&
    css`
      background-color: #7fd1b9;
    `};

  ${(props) =>
    props.type === 'orderCanceled' &&
    css`
      background-color: grey;
    `};

  ${(props) =>
    props.type === 'orderAccepted' &&
    css`
      background-color: #90c2e7;
    `};
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #F6F6F6;
  padding: 5px;
  border-radius: 5px;
`

export const CardBody = styled.div`
 display: flex;
  justify-content: space-between;
`
export const DateArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: end;

  strong{
    font-weight: 600;
  }
`
export const BillInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export const UserDeliveryInfo = styled.span`
  color: #333333;
  font-size: 12px;
`

export const UserDeliveryInfoArea = styled.div`
margin-top: 8px;
display: flex;
flex-direction: column;
align-items: flex-start;
`