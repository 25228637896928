import React, { createContext, useState, useEffect, useContext } from 'react';
import Notification from 'react-web-notification';
import { Howl } from 'howler';

import Sound from '~/assets/sounds/phoneNotification.mp3';
// import SoundOgg from '~/assets/sounds/notification.ogg';

import icon from '~/assets/img/garfinho_red.png';

import history from '../../services/history';

import { useAuth } from '../AuthContext';

import isMobile from '~/utils/isMobile';

const NotificationContext = createContext();

const is_mobile = isMobile();

const NotificationProvider = ({ children }) => {
  const [notificationTitle, setNotificationTitle] = useState('');
  const [options, setOptions] = useState({ icon });
  const [ignore, setIgnore] = useState(true);

  const { user, fcmManager } = useAuth();

  function handlePermissionDenied() {
    // alert('Você não conseguirá ver notificações dos seus novos pedidos');
    console.log('Notification Permission Denied');
    setIgnore(true);
  }

  function handleNotSupported() {
    alert('Seu navegador não é compatível com o sistema de notificações');
    console.log('Web Notification not Supported');
    setIgnore(true);
  }

  function sendNotification({ title, body, tag }) {
    if (is_mobile) {
      if ('serviceWorker' in navigator && navigator.serviceWorker) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification(title, {
            body,
            icon: './favicon.png',
            tag,
          });
        });
      }
      return;
    }

    setOptions({ icon, body, tag });
    setNotificationTitle(title);
  }

  useEffect(() => {
    /* Habilitando firebase quando o provider for contruído */
    if (fcmManager) {
      console.log('Permission Granted');
      fcmManager.enableFirebase(user?.id);
      fcmManager.setOnMessageCallback((data) => {
        sendNotification(data);
      });
    }
  }, [user, fcmManager]);

  function handlePermissionGranted() {
    if (fcmManager) {
      console.log('Permission Granted');
      fcmManager.enableFirebase(user.id);
      fcmManager.setOnMessageCallback((data) => {
        sendNotification(data);
      });

      setIgnore(is_mobile);
    }
  }

  // function playSound(filename) {
  //   document.getElementById('sound').play();
  // }

  function playSoundHowler() {
    const sound = new Howl({
      src: Sound,
      volume: 1,
      preload: true,

    });
    sound.play();
  }

  const page = window.location.pathname;

  function handleNotificationOnShow(e, tag) {
    if (page !== "/kitchen") { playSoundHowler() }
    // console.log(e, `Notification shown tag:${tag}`);
  }

  function handleOnClick(e, tag) {
    console.log(tag);
    if (tag === 'new-order') {
      window.focus();
      history.push('/orders');
    } else if (tag === 'closing-solicitation') {
      window.focus();
      history.push('/bills/close-solicitation');
    } else {
      window.focus();
    }
  }

  return (
    <NotificationContext.Provider value={{ sendNotification }}>
      {notificationTitle ? (
        <>
          <Notification
            ignore={ignore && notificationTitle !== ''}
            title={notificationTitle}
            askAgain
            requireInteraction
            timeout={-1}
            onClose={() => setNotificationTitle(undefined)}
            onShow={handleNotificationOnShow}
            onClick={handleOnClick}
            options={options}
            notSupported={handleNotSupported}
            onPermissionDenied={handlePermissionDenied}
            onPermissionGranted={handlePermissionGranted}
          />
          {/* <audio id="sound" autoPlay preload="auto">
            <source src={Sound} type="audio/mpeg" />
            <source src={SoundOgg} type="audio/ogg" />
          </audio> */}
        </>
      ) : null}
      {children}
    </NotificationContext.Provider>
  );
};

function useNotification() {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      'useNotification must be used within an NotificationProvider'
    );
  }

  return context;
}

export { NotificationProvider, useNotification };
