import React, { useCallback, useEffect, useState } from 'react';
import { FaClock, FaUserCircle } from 'react-icons/fa';
import { Badge } from 'reactstrap';
import { compareAsc, differenceInMinutes } from 'date-fns';
import api from '~/services/api';

import formatTime from '~/services/formatTime';
import OrderItem from './OrderItem';

import {
  BuyerInfo,
  ConfirmButton,
  Container,
  Footer,
  Header,
  Main,
  Orders,
} from './styles';
import PhoneString from '~/services/formatPhoneString';
import formatCompleteDate from '~/services/formatCompleteDate';

function OrderCard({ item, baskets, setBaskets }) {
  const [cardExpanded, setCardExpanded] = useState(false);

  function toggleCardExpand() {
    setCardExpanded(!cardExpanded);
  }

  const buttonStatus = item?.status === 'pending' ? 'Iniciar Preparo' : 'Finalizar';
  function getBadgeStatus(status) {
    if (status === 'pending') {
      return 'bg-info';
    }

    if (status === 'doing') {
      return 'bg-success';
    }

    if (status === 'finished') {
      return 'bg-secondary';
    }

    if (status === 'canceled') {
      return 'bg-danger';
    }

    return false;
  }

  function getBadgeText(status) {
    if (status === 'pending') {
      return 'NOVO';
    }

    if (status === 'doing') {
      return 'EM PREPARO';
    }

    if (status === 'finished') {
      return 'FINALIZADO';
    }

    if (status === 'canceled') {
      return 'CANCELADO';
    }

    return false;
  }

  const handleChangeStatus = useCallback(
    async (order_status, id) => {
      await api.put(`/restaurants/kds/basket/status/${item?.id}`, {
        status: order_status === 'pending' ? 'doing' : 'finished',
      });

      const newBaskets = [...baskets];

      const basketFind = newBaskets?.findIndex((item) => item?.id === id);

      if (basketFind !== -1) {
        newBaskets[basketFind].status =
          order_status === 'pending' ? 'doing' : 'finished';
      }

      setBaskets(newBaskets);
    },
    [item, baskets, setBaskets]
  );

  const [isLate, setIsLate] = useState([]);
  const [isVeryLate, setIsVeryLate] = useState([]);

  const verifyIfIsLate = useCallback(() => {
    const currentTime = new Date();

    if (item?.scheduled_to) {
      const scheduledTime = new Date(item.scheduled_to);

      if (compareAsc(currentTime, scheduledTime) === 1) {
        setIsLate([item.id]);
      }
    } else if (item?.kds_orders) {
      const arrivedAtTime = new Date(item.arrived_at);

      item.kds_orders.forEach(kds => {
        const timeDifference = differenceInMinutes(currentTime, arrivedAtTime);

        if (timeDifference > kds?.average_time) {
          setIsVeryLate(old => [...old, kds.id]);
        } else if (timeDifference > kds?.average_time / 2) {
          setIsLate(old => [...old, kds.id]);
        }
      });
    }
  }, [item]);


  useEffect(() => {
    verifyIfIsLate();
  }, [verifyIfIsLate]);

  useEffect(() => {
    const verifyIfProductIsLate = setInterval(verifyIfIsLate, 60000);
    return () => {
      clearInterval(verifyIfProductIsLate);
    };
  }, [isLate, isVeryLate, item, verifyIfIsLate]);

  function getTableData(table) {
    switch (table.table_type) {
      case 'table':
        return `Mesa ${table.table_number}`;
      case 'delivery':
        return `Delivery`;
      case 'balcony':
        return `Balcão`;
      default:
        return '';
    }
  }

  return (
    <Container>
      <Main>
        <Header>
          {item?.table.table_type === 'pos' && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontWeight: 'bold' }}>
                Totem
              </span>
              {item?.with_withdrawal ? (
                <span style={{ fontWeight: 500 }}>
                  Para viagem
                </span>
              ) : (
                <span style={{ fontWeight: 500 }}>
                  Comer no local
                </span>
              )}
            </div>
          )}

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontWeight: 'bold', fontSize: 18 }}>
              #
              {item?.table.table_type === 'pos' ? (
                <>
                  {item?.attendance_password ? item.attendance_password : item.basket_key}
                </>
              ) : (
                <>
                  {item?.table.table_type === 'delivery'
                    ? item?.attendance_password
                    : item?.basket_key}
                </>
              )}
            </span>

            <Badge pill className={getBadgeStatus(item?.status)} style={{ lineHeight: "16px" }}>
              {getBadgeText(item?.status)}
            </Badge>
          </div>

          {item?.table.table_type === 'command' ?
            (
              <p style={{ fontWeight: 'bold' }}> Comanda {item.table.table_number} /  Mesa {item.command_table_number || 'n/a'}</p>
            ) : (
              <p style={{ fontWeight: 'bold' }}>
                {getTableData(item?.table)}
              </p>
            )
          }

        </Header>
        <BuyerInfo
        // isProductLate={
        //   isLate.includes(item?.id) &&
        //   (item?.status === 'doing' || item?.status === 'pending')
        // }
        >
          <span>
            <FaUserCircle />{' '}
            <span>
              {item?.buyer
                ? item?.buyer?.name
                  ? item?.buyer?.name
                  : PhoneString(item?.buyer?.phone)
                : item?.waiter?.name}
            </span>
          </span>
          {item?.scheduled_to && <span>Agendado para: {formatCompleteDate(item?.scheduled_to)}</span>}

          {!item?.scheduled_to && (
            <span>
              <FaClock /> <span>{formatCompleteDate(item?.arrived_at)}</span>
            </span>
          )}


        </BuyerInfo>
        <Orders>
          {item &&
            item?.kds_orders &&
            item?.kds_orders.length > 0 &&
            item?.kds_orders.sort((a, b) => {
              if (a.canceled_at) {
                return 1;
              } else if (b.canceled_at) {
                return -1;
              } else {
                return a.id - b.id;
              }
            })
              .map((product, index) => (
                <OrderItem
                  product={product}
                  basket={item}
                  isLate={
                    isLate.includes(product?.id) &&
                    (item?.status === 'doing' || item?.status === 'pending')
                  }
                  isVeryLate={
                    isVeryLate.includes(product?.id) &&
                    (item?.status === 'doing' || item?.status === 'pending')
                  }
                  index={index}
                  key={product.id}

                />
              ))}
        </Orders>
      </Main>

      {(item?.status === 'pending' || item?.status === 'doing') && (
        <Footer>
          <ConfirmButton
            type="button"
            status={item?.status}
            onClick={() => handleChangeStatus(item?.status, item?.id)}
          >
            {buttonStatus}{' '}
          </ConfirmButton>
        </Footer>
      )}

    </Container>
  );
}

export default OrderCard;