import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  background-color: ${(props) => props.color};
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 50px;
  width: 100%;
  padding: 12px 8px;
  text-align: center;
  border-radius: 6px 6px 0 0;

  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
`;

export const FilterArea = styled.div`
  width: 250px;
  padding: 20px;
  background-color: #fff;
  position: absolute;
  font-size: 16px;
  top: 43px;
  right: 34px;
  border-radius: 8px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
`

export const OrderArea = styled.div`
  width: 176px;
  padding: 8px;
  background-color: #fff;
  position: absolute;
  font-size: 16px;
  top: 35px;
  right: 34px;
  border-radius: 8px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  display: flex;
  flex-direction: column;

  button {
    height: 32px;
    display: flex;
    gap: 8px;
    padding: 8px;
    justify-content: flex-start;
    align-items: center;
    border: none;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.014px;
    color: #222;
    background-color: #fff;
    transition: ease-in 0.1s;
    
    &:hover {
      background-color: #f5f5f5;
    }
  }
`

export const OrderButton = styled.button`
  height: 32px;
  display: flex;
  gap: 4px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  border: none;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #fff;
  background-color: rgba(204, 95, 20, 0.50);
  border-radius: 4px;
  transition: ease-in 0.1s;
  
  &:hover {
    background-color: rgba(204, 95, 20, 0.75);
  }
`

export const Bloco = styled.div`
  min-width: 285px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  margin-left: 20px auto;
  background: white;
  border-radius: 8px;
  border: solid 1px ${(props) => props.color};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  flex-basis: 33%;
  max-width: 33%;
  flex-grow: 1;
`;

export const ItemsCount = styled.span`
  display: flex;
  background: white;
  justify-content: center;
  align-items: center;
  height: 25px;
  min-width: 25px;
  border-radius: 50%;

  color: ${(props) => props.color};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;



export const StyleKanbanWarning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
`;